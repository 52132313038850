import React, { useState } from 'react';
import '../css/toggle-switch.css';

const ToggleSwitch = ({ onChange, checked, value, disabled, label }) => {
  const [isToggled, setIsToggled] = useState(checked);
  const onToggle = (event) => {
    onChange(event);
    setIsToggled(!isToggled);
  };

  return (
    <div className="toggle-switch-container">
      <label className="toggle-switch">
        <input
          className={disabled ? 'disabled' : ''}
          type="checkbox"
          checked={checked}
          onChange={onToggle}
          value={value}
          disabled={disabled}
        />
        <span className="switch" />
      </label>
      {label && <span className="toggle-label">{label}</span>}
    </div>
  );
};

export default ToggleSwitch;
