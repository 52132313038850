import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { createTtsStrings } from '../../util/tts-util';
import Sockette from 'sockette';
import YouTube from 'react-youtube';
import '../css/tts.css';
import '../css/fonts.css';
import 'animate.css/animate.min.css';
import useInteraction from '../hooks/useInteraction';
const getYoutubeID = require('get-youtube-id');
import { coinNames } from '../static/coin-names';

const Tts = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const { username } = useParams();
  const interacted = useInteraction();
  const pingTime = 5 * 60 * 1000; //5 minutes

  const [msgVisible, setMsgVisible] = useState(false);
  const [ttsImage, setTtsImage] = useState(null);
  const [streak, setStreak] = useState(null);
  const [action, setAction] = useState(null);
  const [amount, setAmount] = useState(null);
  const [isPlainMessage, setIsPlainMessage] = useState(false);
  const [blinkerVisible, setBlinkerVisible] = useState('');
  const [msgKey, setMsgKey] = useState(0);
  const ampedTTS = useRef(null);
  const preload = useRef(true);
  const [showLoading, setShowLoading] = useState(false);
  const socket = useRef(null);
  const buffering = useRef(false);
  const ttsTimeout = useRef(null);
  const maxTtsSeconds = useRef(40);
  const mediaStartTime = useRef(0);
  const mediaTimeout = useRef(null);
  const allMessages = useRef([]);
  const soundQueue = useRef([]);
  const currentSound = useRef(null);
  const [message, setMessage] = useState('');
  const [subMessage, setSubMessage] = useState(null);
  const [donator, setDonator] = useState('');
  const audioText = useRef('');
  const mediaDuration = useRef(null);
  const defaultTextTime = 10;
  const [ttsFont, setTtsFont] = useState('default');
  const [donatorColor, setDonatorColor] = useState();
  const [messageColor, setMessageColor] = useState();
  const [customMessageFont, setCustomMessageFont] = useState('');
  const ttsVoice = useRef('Matthew');
  const voiceTypeId = useRef(1);
  const [ttsAnimation, setTtsAnimation] = useState('bounce');
  const ttsVolume = useRef(null);
  const externalTtsUrl = useRef(null);
  const ttsVoiceDefault = 'Matthew';
  const ttsAnimationDefault = 'bounce';
  const ttsSoundDefault = '/static/audio/coin.mp3';
  const ttsFontDefault = 'default';
  const shouldShowVideo = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const willPlayMedia = useRef(null);
  const willPlayTTS = useRef(null);
  const chimeReady = useRef(false);
  const ttsReady = useRef(false);
  const youtubeReady = useRef(false);
  const currentChat = useRef(null);
  const globalTimeout = useRef(null);
  const playbackEnabled = useRef(true);
  const shouldVideoStopInterval = useRef(null);
  const playbackDelay = useRef(0);
  const [cryptoShort, setCryptoShort] = useState(null);
  const [ttsSubscribed, setTtsSubscribed] = useState(null);
  const [platformTitleFont, setPlatformTitleFont] = useState(null);
  const [useHeartbeat, setUseHeartbeat] = useState(false);

  const ttsState = useRef({ state: '' });

  const ttsAudioRef = useRef();
  const chimeRef = useRef();
  const chimeAudioSourceRef = useRef();
  const youtubeRef = useRef();
  const heartbeatRef = useRef();

  const [isViewerPro, setIsViewerPro] = useState(false);
  const [isStreamerPro, setIsStreamerPro] = useState(false);

  useEffect(() => {
    const loadSettings = async () => {
      const settings = await axios.get('/pubapi/chat-settings', { params: { username } });
      let { cozyUser, youtubeUser, rumbleUser } = settings.data;
      if (cozyUser === true || youtubeUser === true || rumbleUser === true) {
        chatPing({ cozyUser, youtubeUser, rumbleUser });
        setInterval(() => chatPing({ cozyUser, youtubeUser, rumbleUser }), pingTime);
      }
    };
    loadSettings();
  }, []);

  useEffect(() => {
    if (useHeartbeat) {
      //Play an inaudible note every 5 minutes to prevent bluetooth speaker disconnect
      setInterval(() => heartbeatRef.current.play(), 5*60*1000);
    }
  }, [useHeartbeat]);

  const chatPing = async ({ cozyUser, youtubeUser, rumbleUser }) => {
    await axios.post('/pubapi/chat-ping', { username, cozyUser, youtubeUser, rumbleUser });
  };

  const neural = new Set([
    'Justin',
    'Matthew',
    'Ivy',
    'Amy',
    'Brian',
    'Olivia',
    'Lucia',
    'Elin',
    'Seoyeon',
    'Ayanda',
    'Hiujin',
  ]);

  const shouldVideoStop = async () => {
    const currentTime =
      await youtubeRef.current.internalPlayer.getCurrentTime();
    if (currentTime - mediaStartTime.current >= currentChat.current.mediaDuration) {
      clearInterval(shouldVideoStopInterval.current);
      setShowVideo(false);
      playNextMessage();
    }
  };

  const playTTS = () => {
    if (!willPlayTTS.current) {
      setTimeout(playMedia, 5000);
      return;
    }
    ttsState.current['chimePlayed'] = true;
    remoteLog('playTTS');
    if (!preload.current) {
      loadTTS();
    }
    if (willPlayTTS.current) {
      ttsAudioRef.current.play();
    } else {
      playMedia();
    }
    ttsTimeout.current = setTimeout(() => {
      remoteLog('tts timeout');
      ttsAudioRef.current.src = '';
      playMedia();
    }, maxTtsSeconds.current * 1000);
  };

  const amplifyMedia = (mediaElem, multiplier) => {
    const context = new (window.AudioContext || window.webkitAudioContext)(),
      result = {
        context: context,
        source: context.createMediaElementSource(mediaElem),
        gain: context.createGain(),
        media: mediaElem,
        amplify: function (multiplier) {
          result.gain.gain.value = multiplier;
        },
        getAmpLevel: function () {
          return result.gain.gain.value;
        },
      };
    result.source.connect(result.gain);
    result.gain.connect(context.destination);
    result.amplify(multiplier);
    return result;
  };

  const playMedia = async () => {
    ttsState.current['ttsPlayed'] = true;
    remoteLog('playMedia');
    clearTimeout(ttsTimeout.current);
    if (!preload.current) {
      loadMedia();
    }
    if (currentChat.current.media && willPlayMedia.current) {
      remoteLog('play media');
      setMsgVisible(false);
      setMsgKey(msgKey + 1);
      setTimeout(() => setMsgVisible(true), 0);
      setTimeout(() => setMsgVisible(false), 0);
      youtubeRef.current.internalPlayer.setVolume(ttsVolume.current * 100);
      if (preload.current) {
        try {
          mediaStartTime.current =
            await youtubeRef.current.internalPlayer.getCurrentTime();
        } catch (err) {
          console.error(err);
        }
      }
      ttsState.current.state = 'playingMedia';
      setShowVideo(shouldShowVideo.current);
      remoteLog('set ttsState to playingMedia');
      youtubeRef.current.internalPlayer.playVideo();
      mediaTimeout.current = setTimeout(() => {
        remoteLog('Media timeout hit');
        playNextMessage();
      }, (currentChat.current.mediaDuration + 60) * 1000); // Give it 1.5 times to load
      shouldVideoStopInterval.current = setInterval(() => {
        shouldVideoStop();
      }, 1000);
    } else {
      playNextMessage();
    }
  };

  const loadMedia = async () => {
    remoteLog('loading media');
    if (!currentChat.current) {
      return;
    }
    if (!willPlayMedia.current) {
      return;
    }
    const regexTimestamp = /[?&]t=([0-9]+)/;
    const match = allMessages.current[0].media.match(regexTimestamp);
    mediaStartTime.current = match ? match[1] : 0;
    let newVideoId = getIdFromUrl(allMessages.current[0].media);
    youtubeRef.current.internalPlayer.loadVideoById({
      videoId: newVideoId,
      startSeconds: mediaStartTime.current,
    });
    try {
      await youtubeRef.current.internalPlayer.setVolume(0);
      await youtubeRef.current.internalPlayer.playVideo();
    } catch (err) {
      console.error(err);
    }
  };

  const youtubeStates = {
    UNSTARTED: -1,
    ENDED: 0,
    PLAYING: 1,
    PAUSED: 2,
    BUFFERING: 3,
    CUED: 5,
  };

  const youtubeStateChange = (e) => {
    remoteLog('Youtube state change ' + e.data);
    switch (e.data) {
      case youtubeStates.ENDED:
        youtubeEnded();
        break;
      case youtubeStates.PLAYING:
        youtubePlaying();
        break;
      case youtubeStates.PAUSED:
        youtubePaused();
        break;
      case youtubeStates.BUFFERING:
        youtubeBuffering();
        break;
    }
  };

  const youtubeBuffering = () => {
    remoteLog('VIDEO BUFFERING');
    buffering.current = true;
    if (ttsState.current.state == 'mediaPlaying' || !preload.current) {
      setShowLoading(true);
    }
  };

  const youtubePlaying = async () => {
    remoteLog('youtubePlaying');
    if (!youtubeReady.current && buffering.current && preload.current) {
      try {
        await youtubeRef.current.internalPlayer.pauseVideo();
        buffering.current = false;
        youtubeReady.current = true;
        remoteLog('youtube ready');
        if (preload.current) {
          mediaStartTime.current =
            await youtubeRef.current.internalPlayer.getCurrentTime();
        }
        if (preload.current) {
          playIfAllReady();
        }
      } catch (err) {
        console.error(err);
      }
    } else if (
      ttsState.current.state == 'mediaPlaying' ||
      ttsState.current.state == 'playingMedia'
    ) {
      ttsState.current.state = 'mediaPlaying';
      setShowLoading(false);
    } else {
      remoteLog('youtube playing in unexpected ttsState');
      return await youtubeRef.current.internalPlayer.pauseVideo();
    }
  };

  const youtubePaused = async () => {
    remoteLog('youtubePaused');
    if (ttsState.current.state == 'mediaPlaying' && buffering.current) {
      setShowLoading(false);
      try {
        await youtubeRef.current.internalPlayer.playVideo();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const youtubeError = (err) => {
    remoteLog('youtubeError');
    remoteLog(err.data);
    playNextMessage();
  };

  const youtubeEnded = () => {
    //TODO: should we do playnextmessage here? If the video ends before the timeout?
    remoteLog('youtubeEnded');
    playNextMessage();
  };

  const stopVideo = async () => {
    remoteLog('stopVideo');
    try {
      if (youtubeRef.current && youtubeRef.current.internalPlayer.pauseVideo) {
        await youtubeRef.current.internalPlayer.pauseVideo();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const chimeFinished = () => {
    remoteLog('chime finished');
    // TODO: Don't load TTS audio if it doesn't meet requirements
    if (willPlayTTS.current) {
      playTTS();
    } else {
      setTimeout(() => {
        playNextMessage();
      }, defaultTextTime * 1000);
    }
  };

  const receiveMessage = (data) => {
    remoteLog(`receiveMessage: ${JSON.stringify(data)}`);
    if (data.skip) {
      return skipMessage();
    }
    if (data.type === 'sound') {
      if (data.queueEnabled) {
        soundQueue.current.push(data);
        playSoundQueue();
      } else {
        playSoundImmediately(data);
      }
      return;
    }
    allMessages.current.push(data);
    if (!playbackEnabled.current) {
      remoteLog('received message while playback is paused');
      remoteLog(JSON.stringify(allMessages.current));
      return;
    }
    if (allMessages.current.length == 1) {
      showMessage(allMessages.current[0]);
    }
  };

  const playSoundQueue = () => {
    if (soundQueue.current.length > 0) {
      const soundData = soundQueue.current[0];
      if (!currentSound.current) {
        currentSound.current = soundData;
        // Check if video is involved
        if (soundData.video) {
          if (soundData.videoEnabled) {
            console.log(`Video enabled, playing with ${soundData.volume === 0 ? 'no sound' : 'sound'}.`);
            playSoundVideo(soundData.video, soundData.volume);
          } else {
            handleSoundOnlyPlayback(soundData);
          }
        } else {
          handleSoundOnlyPlayback(soundData);
        }
      }
    }
  };

  const playSoundImmediately = (soundData) => {
    if (currentSound.current) {
      stopCurrentSound();
    }
    currentSound.current = soundData;
    if (soundData.video) {
      if (soundData.videoEnabled) {
        console.log(`Video enabled, playing with ${soundData.volume === 0 ? 'no sound' : 'sound'}.`);
        playSoundVideo(soundData.video, soundData.volume);
      } else {
        handleSoundOnlyPlayback(soundData);
      }
    } else {
      handleSoundOnlyPlayback(soundData);
    }
  };

  const stopCurrentSound = () => {
    if (currentSound.current) {
      currentSound.current.pause();
      if (currentSound.current.tagName === 'VIDEO') {
        document.body.removeChild(currentSound.current.parentNode);
      }
      currentSound.current = null;
    }
  };

  const handleSoundOnlyPlayback = (soundData) => {
    if (soundData.volume > 0) {
      console.log("Playing sound only.");
      playSound(soundData.soundUrl || soundData.video, soundData.volume);
    } else {
      console.log("Volume is 0, skipping playback.");
      proceedToNextSound();
    }
  };

  const proceedToNextSound = () => {
    currentSound.current = null;
    soundQueue.current.shift();
    prepareNextSound();
  };

  const prepareNextSound = () => {
    if (soundQueue.current.length > 0) {
      playSoundQueue();
    } else {
      console.log("Sound queue is empty, ready for new sounds.");
    }
  };

  const playSound = async (soundUrl, volume) => {
    console.log(`Attempting to play sound at volume: ${volume}`);
    const soundAudio = new Audio(soundUrl);
    soundAudio.volume = volume / 100;
    soundAudio.onended = soundAudio.onerror = () => {
      soundAudio.remove();
      proceedToNextSound();
    };
    soundAudio.play().catch((error) => {
      console.error("Error playing sound:", error);
      proceedToNextSound();
    });
    currentSound.current = soundAudio;
  };
  

  const playSoundVideo = async (videoUrl, volume) => {
    console.log(`Initializing video playback from URL: ${videoUrl} with adjusted volume: ${volume}`);
    const videoContainer = document.createElement('div');
    videoContainer.className = 'flex-center';
    const videoElement = document.createElement('video');
    videoElement.src = videoUrl;
    videoElement.width = 640;
    videoElement.height = 360;
    videoElement.volume = volume / 100;
    videoContainer.appendChild(videoElement);
    document.body.appendChild(videoContainer);
  
    videoElement.onloadeddata = () => {
      videoElement.play().then(() => {
        console.log("Video playback started successfully.");
      }).catch((error) => {
        console.error("Error during video playback:", error);
        cleanupVideo(videoContainer);
      });
    };
  
    videoElement.onended = videoElement.onerror = () => {
      console.log("Video playback ended or encountered an error.");
      cleanupVideo(videoContainer);
    };
  
    currentSound.current = videoElement; // Store the video element in the currentSound ref
  };

  const cleanupVideo = (videoContainer) => {
    document.body.removeChild(videoContainer);
    proceedToNextSound();
  };

  useEffect(() => {
    if (chimeReady.current && currentChat.current) {
      playIfAllReady();
    }
  }, [interacted]);

  const showMessage = (data) => {
    remoteLog('showMessage');
    console.info(`[tts] showMessage`, data);
    resetState();
    currentChat.current = data;
    willPlayTTS.current = currentChat.current.shouldPlayTTS;
    preload.current = data.preload;
    mediaDuration.current = data.mediaDuration;
    setAmount(data.amount);
    setCryptoShort(coinNames[data.paymentPlatform] && data.paymentPlatform.toUpperCase());
    setDonator(data.donator);
    setIsPlainMessage(data.isPlainMessage);
    setTtsSubscribed(
      data.isSubscriber || data.isSubscription ? true : false
    );
    setPlatformTitleFont(
      data.paymentPlatform == 'youtube' ? 'tts-message-youtube' : ''
    );
    setIsViewerPro(data.isViewerPro);
    setIsStreamerPro(data.isStreamerPro);

    let strings = createTtsStrings(data);
    audioText.current = strings.audioText;
    setAction(strings.action);
    setStreak(data.streak);

    setMessage(data.message);
    setSubMessage(data.subMessage);
    shouldShowVideo.current = data.showVideo && !!data.media;

    if (!ampedTTS.current) {
      ampedTTS.current = amplifyMedia(ttsAudioRef.current, 1);
    }
    externalTtsUrl.current = data.externalTtsUrl;
    ttsVoice.current = data.ttsVoice || ttsVoiceDefault;
    voiceTypeId.current = data.voiceTypeId || 9;
    ttsVolume.current = data.ttsVolume / 100;
    ampedTTS.current.amplify(data.ttsVolumeBoost || 1);
    if (data.imageUrl) {
      setTtsImage(data.imageUrl);
    } else {
      setTtsImage(data.ttsImage);
    }
    setCustomMessageFont(data.customMessageFont);
    setTtsAnimation(data.ttsAnimation || ttsAnimationDefault);

    let ttsSound = data.ttsSound || ttsSoundDefault;
    if (ttsSound != chimeRef.current.src) {
      chimeRef.current.src = ttsSound;
    }
    setTtsFont(data.ttsFont || ttsFontDefault);
    setDonatorColor(data.donatorColor);
    setMessageColor(data.messageColor);
    chimeRef.current.volume = data.ttsVolume / 100;
    ttsState.current.state = 'initialLoad';

    chimeRef.current.load();
    willPlayMedia.current = currentChat.current.shouldPlayMedia;
    if (willPlayMedia.current && preload.current) {
      loadMedia();
    }
    willPlayTTS.current = currentChat.current.shouldPlayTTS;
    let globalTimeoutDuration = 15;
    if (willPlayTTS.current) {
      globalTimeoutDuration = 40;
    }
    if (willPlayTTS.current && preload.current) {
      loadTTS();
    }
    if (willPlayMedia.current) {
      globalTimeoutDuration += mediaDuration.current * 2 + 30;
    }
    globalTimeout.current = setTimeout(() => {
      remoteLog('global timeout');
      ttsAudioRef.current.src = '';
      playNextMessage();
    }, globalTimeoutDuration * 1000);
  };

  const skipMessage = () => {
    remoteLog('playing next message');
    setShowVideo(false);
    ttsAudioRef.current.pause();
    playNextMessage();
  };

  const resetState = () => {
    ttsState.current = {
      state: '',
      chimePlayed: false,
      ttsPlayed: false,
      mediaPlayed: false,
    };
  };

  const playNextMessage = async () => {
    ttsState.current['mediaPlayed'] = true;
    remoteLog('playing next message');
    clearTimeout(globalTimeout.current);
    clearInterval(shouldVideoStopInterval.current);
    clearTimeout(ttsTimeout.current);
    resetState();
    chimeReady.current = false;
    youtubeReady.current = false;
    setShowLoading(false);
    ttsReady.current = false;
    buffering.current = false;
    setMsgVisible(false);
    setShowVideo(false);
    setMsgKey(msgKey + 1);
    setTimeout(() => setMsgVisible(true), 0);
    setTimeout(() => setMsgVisible(false), 0);
    stopVideo();
    clearTimeout(mediaTimeout.current);
    remoteLog('reset ttsState, moving to next message');
    setTimeout(() => {
      allMessages.current.shift();
      if (!playbackEnabled.current) {
        remoteLog('playback is paused');
        return;
      }
      if (allMessages.current.length) {
        showMessage(allMessages.current[0]);
      }
    }, playbackDelay.current * 1000);
  };

  const loadPolly = () => {
    window.AWS.config.region = process.env.AWS_REGION;
    window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.AWS_KEY,
    });
  };

  const abortTtsPlayback = () => { };

  const abortChimePlayback = () => {
    if (ttsState.current.state == 'chimeLoading') {
      clearTimeout(null);
      chimeRef.current.load();
      chimeFinished();
    }
  };

  const loadTTS = () => {
    remoteLog('loadTTS');
    // External TTS (non-Polly, just FakeYou for now)
    if (externalTtsUrl.current != null) {
      ttsAudioRef.current.src = '/audioproxy?url=' + externalTtsUrl.current;
      ttsAudioRef.current.load();
      if (!preload.current) {
        ttsAudioRef.current.play();
      }
      return;
    }

    // Create the JSON parameters for getSynthesizeSpeechUrl
    const isNeural = neural.has(ttsVoice.current) ? 'neural' : 'standard';
    var speechParams = {
      OutputFormat: 'mp3',
      SampleRate: '16000',
      Text: '',
      TextType: 'text',
      VoiceId: ttsVoice.current,
      Engine: isNeural,
    };

    // newscaster voice
    if (voiceTypeId.current == 2) {
      speechParams.TextType = 'ssml';
      speechParams.Text = `<speak><amazon:domain name="news"><![CDATA[${audioText.current}]]></amazon:domain></speak>`;
    }
    // whisper voice
    else if (voiceTypeId.current == 3) {
      speechParams.TextType = 'ssml';
      speechParams.Text = `<speak><amazon:effect name="whispered"><prosody rate="-10%" volume="loud"><![CDATA[${audioText.current}]]></prosody></amazon:effect></speak>`;
    }
    // pitch altered
    else if (voiceTypeId.current == 4) {
      speechParams.TextType = 'ssml';
      speechParams.Text = `<speak><amazon:effect vocal-tract-length="-15%"><![CDATA[${audioText.current}]]></amazon:effect></speak>`;
    }
    else {
      speechParams.Text = audioText.current;
    }
    // Create the Polly service object and presigner object
    var polly = new window.AWS.Polly({ apiVersion: '2016-06-10' });
    var signer = new window.AWS.Polly.Presigner(speechParams, polly);
    // Create presigned URL of synthesized speech file
    ttsState.current.state = 'ttsLoading';
    signer.getSynthesizeSpeechUrl(speechParams, function (error, url) {
      if (error) {
        remoteLog(error);
        abortTtsPlayback();
      } else {
        ttsAudioRef.current.src = url;
        ttsAudioRef.current.load();
        if (!preload.current) {
          ttsAudioRef.current.play();
        }
      }
    });
  };

  const startWebsockets = () => {
    // we always use a secure connection to test.
    const protocol = 'wss';
    socket.current = new Sockette(
      `${protocol}://${baseUrl}/${username.toLowerCase()}`,
      {
        onopen: function (event) {
          event.target.send(
            `Remote client log - ${username} - Open TTS - ${username}`
          );
        },
        onmessage: function (event) {
          event.target.send(
            `Remote client log - ${username} - [message] Data received from server: ${event.data}`
          );
          if (event.data == 'pong') {
            return;
          }
          const data = JSON.parse(event.data);
          if (data.id) {
            event.target.send(`rec:${data.id}`);
          }
          receiveMessage(data);
        },
        onclose: function (event) {
          if (event.wasClean) {
            console.log(
              `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
            );
          } else {
            console.log('[close] Connection died');
          }
        },
        onerror: function (error) {
          console.log(`[error] ${error.message}`);
        },
      }
    );
    setInterval(() => {
      socket.current && socket.current.send(`ping from: ${username} - TTS Overlay`);
    }, 30 * 1000);
  };

  const playIfAllReady = () => {
    remoteLog('playIfAllReady');
    if (
      !preload.current ||
      (preload.current &&
        chimeReady.current &&
        (!willPlayTTS.current || (ttsReady.current && willPlayTTS.current)) &&
        (!willPlayMedia.current || (youtubeReady.current && willPlayMedia.current)))
    ) {
      remoteLog('chime play');
      if (ttsState.current.state == 'chimeError') {
        setMsgVisible(true);
        playTTS();
      } else if (!ttsState.current.chimePlayed) {
        setMsgVisible(true);
        chimeRef.current.play();
      }
    }
  };

  const remoteLog = (msg) => {
    console.log('Remote log -', msg);
    if (socket.current) {
      socket.current.send(
        `Remote client log - ${username} - ${msg} - ttsState: ${JSON.stringify(
          ttsState.current
        )} - msgVisible: ${msgVisible}`
      );
    }
  };

  useEffect(() => {
    const getPlaybackSettings = async () => {
      try {
        const { data } = await axios.get('/pubapi/playbackSettings', {
          params: { username },
        });
        let playbackCurrentlyEnabled = true;
        if (data.playbackEnabled !== undefined) {
          playbackCurrentlyEnabled = data.playbackEnabled;
        }
        playbackEnabled.current = playbackCurrentlyEnabled;
        if (data.maxTtsSeconds > 0) {
          maxTtsSeconds.current = data.maxTtsSeconds;
        }
        playbackDelay.current = data.playbackDelay;
        setUseHeartbeat(data.ttsHeartbeat);
      } catch (err) {
        console.error(err);
      }
    };
    getPlaybackSettings();
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById('aws');
    if (!existingScript) {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://sdk.amazonaws.com/js/aws-sdk-2.864.0.min.js'
      );
      script.id = 'aws';
      script.onload = loadPolly;
      document.head.appendChild(script);
    } else {
      loadPolly();
    }
  }, []);


  useEffect(() => {
    resetState();
    setInterval(async () => {
      const { data } = await axios.get('/pubapi/playbackSettings', {
        params: { username },
      });
      if (data.playbackEnabled != undefined) {
        if (!playbackEnabled.current && data.playbackEnabled && ttsState.current.state == '') {
          remoteLog('unpaused - playing next message');
          remoteLog(JSON.stringify(allMessages.current));
          playbackEnabled.current = true;
          if (allMessages.current.length) {
            showMessage(allMessages.current[0]);
          }
        } else {
          playbackEnabled.current = data.playbackEnabled;
        }
      }
      if (data.maxTtsSeconds > 0) {
        maxTtsSeconds.current = data.maxTtsSeconds
      }
    }, 30 * 1000);

    setInterval(async () => {
      setBlinkerVisible(!blinkerVisible);
    }, 1000);

    startWebsockets();

    chimeRef.current.oncanplaythrough = () => {
      remoteLog('can play chime');
      chimeReady.current = true;
      if (currentChat.current) {
        playIfAllReady();
      }
    };

    ttsAudioRef.current.addEventListener('canplaythrough', () => {
      remoteLog('can play tts');
      ttsReady.current = true;
      if (preload.current) {
        playIfAllReady();
      }
    });

    ttsAudioRef.current.onplaying = function () {
      ttsState.current.state = 'ttsPlaying';
      remoteLog('ttsPlaying');
    };
    ttsAudioRef.current.onended = () => {
      playMedia();
    };
    ttsAudioRef.current.onstalled = function () {
      remoteLog('tts playback stalled');
    };
    ttsAudioRef.current.onerror = () => {
      remoteLog('tts source error!');
      abortTtsPlayback();
    };

    chimeRef.current.onplaying = function () {
      remoteLog('CHIME PLAYING');
      clearTimeout(null);
    };
    chimeRef.current.onended = () => {
      playTTS();
    };
    chimeRef.current.onerror = function () {
      remoteLog('chime error');
      ttsState.current.state = 'chimeError';
      chimeReady.current = true;
      if (!preload.current) {
        setMsgVisible(true);
        playTTS();
      }
    };
    chimeRef.current.onstalled = function () {
      remoteLog('chime stalled');
    };
    document.getElementById('chimeAudioSource').onerror = () => {
      remoteLog('chime source error!');
      abortChimePlayback();
    };

    youtubeRef.current.internalPlayer.addEventListener(
      'onStateChange',
      youtubeStateChange
    );
    youtubeRef.current.internalPlayer.addEventListener('onError', youtubeError);
  }, []);

  const getIdFromUrl = (uri) => getYoutubeID(uri);

  const opts = {
    height: '390',
    width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <div className="tts-container">
      {showLoading && (
        <div className={'text-center tts-title ' + ttsFont}>
          Loading Media...
        </div>
      )}
      {msgVisible && (
        <div className={'animate__animated animate__' + ttsAnimation}>
          <img className="tts-image w-50" src={ttsImage} />
          {!isPlainMessage && (!streak || streak <= 1) && (
            <div
              className={
                'text-center tts-title ' + ttsFont + ' ' + platformTitleFont
              }
              style={messageColor !== 'inherit' ? { color: messageColor } : {}}
            >
              <span 
                className={(ttsSubscribed ? 'tts-title-subscriber' : 'tts-title')} 
                style={donatorColor !== 'inherit' ? { color: donatorColor } : {}}
              >
                {(isStreamerPro || isViewerPro) && currentChat.current && currentChat.current.ttsBadgeType && (
                  <img
                    src={(!isStreamerPro && currentChat.current.ttsBadgeType.includes('yellow')) ?
                      '/static/img/pc-pro-badge-check-blue.webp' : `/static/img/pc-pro-badge-${currentChat.current.ttsBadgeType}.webp`}
                    className="pro-badge" 
                    alt="PRO" 
                  />
                )}
                {donator}
              </span>
              <span 
                className="smaller-title" 
                style={messageColor !== 'inherit' ? { color: messageColor } : {}}
              >
                &nbsp;{action}&nbsp;
              </span>
              <span>${amount}</span>
              {cryptoShort && <span>&nbsp;in&nbsp;{cryptoShort}</span>}
            </div>
          )}
          {!!streak && streak >= 1 && (
            <div
              className={'text-center tts-title ' + ttsFont}
              style={messageColor !== 'inherit' ? { color: messageColor } : {}}
            >
              <span 
                className={(ttsSubscribed ? 'tts-title-subscriber' : 'tts-title')} 
                style={donatorColor !== 'inherit' ? { color: donatorColor } : {}}
              >
                {isStreamerPro && (
                  <img 
                    src={`/static/img/pc-pro-badge${currentChat.current && currentChat.current.ttsBadgeType && currentChat.current.ttsBadgeType.includes('lightning') ? '-yellow-l' : '-yellow'}.webp`} 
                    className="pro-badge" 
                    alt="PRO" 
                  />
                )}
                {isViewerPro && !isStreamerPro && (
                  <img 
                    src={`/static/img/pc-pro-badge${currentChat.current && currentChat.current.ttsBadgeType && currentChat.current.ttsBadgeType.includes('lightning') ? '-l' : ''}.webp`} 
                    className="pro-badge" 
                    alt="PRO" 
                  />
                )}
                {donator}
              </span>
              <span>
                &nbsp;is&nbsp;on&nbsp;a&nbsp;{streak}-month&nbsp;streak
              </span>
            </div>
          )}
          <div className="tts-message-container">
            <span
              className={
                'text-center tts-message ' + ttsFont + ' ' + customMessageFont
              }
              style={messageColor !== 'inherit' ? { color: messageColor } : {}}
            >
              &nbsp;{message}&nbsp;
            </span>
            {!!subMessage && (
              <div 
                className={'text-center tts-message ' + ttsFont} 
                style={messageColor !== 'inherit' ? { color: messageColor } : {}}
              >
                &nbsp;{subMessage}&nbsp;
              </div>
            )}
          </div>
        </div>
      )}
      <audio crossOrigin="anonymous" hidden ref={ttsAudioRef} controls>
        <source id="ttsAudioSource" type="audio/mp3" src="" />
      </audio>
      <audio
        id="chime"
        preload="auto"
        hidden
        ref={chimeRef}
        controls
        src="/static/audio/coin.mp3"
      >
        <source
          ref={chimeAudioSourceRef}
          id="chimeAudioSource"
          type="audio/mp3"
          src="/static/audio/coin.mp3"
        />
      </audio>
      <audio
        id="heartbeat"
        preload="auto"
        hidden
        ref={heartbeatRef}
        controls
        src="/static/audio/heartbeat.wav"
      />
      <div
        className="flex-center"
        style={{ display: showVideo ? 'flex' : 'none' }}
      >
        <YouTube ref={youtubeRef} opts={opts} />
      </div>
      {blinkerVisible && <div className="blinker"></div>}
    </div>
  );
};

export default Tts;

