import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/overlay-settings.css';
import Select from 'react-select';
import ToggleSwitch from './toggle-switch';
import FormAlert from './form-alert';
import NumberInput from './number-input';
import ColorInput from './color-input';
import FontSelector from './font-selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const OverlaySettings = ({ updateData, isMod = false, username, ...props }) => {

  // Sanitize props to prevent null values warning.
  const sanitizedProps = { ...props };
  Object.entries(sanitizedProps).forEach(([key, value]) => {
    if (value === null) {
      sanitizedProps[key] = '';
    }
  });

  const initialFormState = { ...sanitizedProps };

  const [formData, setFormData] = useState(initialFormState);
  const [customLinkData, setCustomLinkData] = useState({
    maxChats: '50',
    autoScrollDisabled: false,
  });

  const [tooltip, setTooltip] = useState('');
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const [spoiler, setSpoiler] = useState(null);
  const toggleSpoiler = (name) => {
    setSpoiler(spoiler === name ? null : name);
  };

  const fontOptions = [
    { value: 'default', label: 'Default' },
    { value: 'bold', label: 'Bold' },
    { value: 'comicbook', label: 'Comic Book' },
    { value: 'consolas', label: 'Consolas' },
    { value: 'cursive', label: 'Cursive' },
    { value: 'futuristic', label: 'Futuristic' },
    { value: 'groovy', label: 'Groovy' },
    { value: 'jaldi', label: 'Jaldi' },
    { value: 'professional', label: 'Professional' },
    { value: 'spooky', label: 'Spooky' },
    { value: 'three-d', label: '3D' },
  ];

  const overlayOverallOrientationOptions = [
    { value: 'topLeft', label: 'Top Left' },
    { value: 'topCenter', label: 'Top Center' },
    { value: 'topRight', label: 'Top Right' },
    { value: 'centerLeft', label: 'Center Left' },
    { value: 'center', label: 'Center' },
    { value: 'centerRight', label: 'Center Right' },
    { value: 'bottomLeft', label: 'Bottom Left' },
    { value: 'bottomCenter', label: 'Bottom Center' },
    { value: 'bottomRight', label: 'Bottom Right' },
  ];

  const overlayOrientationOptions = [
    { value: 'horizontal', label: 'Horizontal' },
    { value: 'vertical', label: 'Vertical' },
  ];

  const emoteWallAnimationOptions = [
    { value: 'rainfall', label: 'Rainfall' },
    { value: 'screensaver', label: 'Screensaver' },
    { value: 'fireworks', label: 'Fireworks' },
    { value: 'explosion', label: 'Explosion' },
    { value: 'decompression', label: 'Decompression' },
  ];

  const expirationAnimationOptions = [
    { value: '', label: 'None' },
    { value: 'fadeOut', label: 'Fade Out' },
    { value: 'slideLeft', label: 'Slide Left' },
    { value: 'slideUp', label: 'Slide Up' },
    { value: 'slideRight', label: 'Slide Right' },
    { value: 'slideDown', label: 'Slide Down' },
    { value: 'zoomOut', label: 'Zoom Out' },
    { value: 'zoomIn', label: 'Zoom In' },
    { value: 'flipHorizontal', label: 'Flip Horizontal' },
    { value: 'flipVertical', label: 'Flip Vertical' },
  ];

  const themeOptions = [
    { value: 'classic', label: `Classic - Good ol' Powerchat` },
    { value: 'bubble', label: 'Bubble - Simple chat conversation' },
    { value: 'console', label: 'Console - Inspired by a Linux terminal' },
  ];

  const chatOrientationOptions = [
    { value: 'topLeft', label: 'Top Left' },
    { value: 'topRight', label: 'Top Right' },
    { value: 'bottomLeft', label: 'Bottom Left' },
    { value: 'bottomRight', label: 'Bottom Right' },
  ]

  const incomingAnimationOptions = [
    { value: '', label: 'None' },
    { value: 'fadeIn', label: 'Fade In' },
    { value: 'slideFromLeft', label: 'Slide from Left' },
    { value: 'slideDownFromTop', label: 'Slide down from Top' },
    { value: 'slideFromRight', label: 'Slide from Right' },
    { value: 'slideUpFromBottom', label: 'Slide up from Bottom' },
    { value: 'flipHorizontal', label: 'Flip Horizontal' },
    { value: 'flipVertical', label: 'Flip Vertical' },
  ];

  const voiceStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '40px',
      margin: '10px 20px 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({ ...styles, color: 'black', width: '100%' }),
    input: (styles) => ({
      ...styles,
      cursor: 'text',
    }),
  };

  const fontStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '40px',
      margin: '10px 0 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({ ...styles, color: 'black' }),
    input: (styles) => ({
      ...styles,
    }),
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '40px',
      margin: '10px 0 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({
      ...styles,
      color: 'black',
      ':active': {
        ...styles[':active'],
      },
    }),
    input: (styles) => ({
      ...styles,
      cursor: 'text',
    }),
  };

  const [url, setUrl] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (option, action) => {
    setFormData({
      ...formData,
      [action.name]: option.value,
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCustomLinkChange = (event) => {
    const { name, value } = event.target;
    setCustomLinkData({
      ...customLinkData,
      [name]: value,
    });
  };

  const handleCustomLinkToggle = (name) => {
    setCustomLinkData({
      ...customLinkData,
      [name]: !customLinkData[name],
    });
  };

  useEffect(() => {
    const fieldMap = {
      chatOverlayTtl: 'ttl',
      chatOverlayEmoteSize: 'emoteSize',
      chatOverlayMessageColor: 'messageColor',
      chatOverlayMessageColorRandom: 'messageColorRandom',
      chatOverlayEmotesOnly: 'emotesOnly',
      chatOverlayNoEmotes: 'noEmotes',
      chatOverlayChatterColor: 'chatterColor',
      chatOverlayChatterColorRandom: 'chatterColorRandom',
      chatOverlayChatterColorDefault: 'chatterColorDefault',
      chatOverlayExpirationAnimation: 'expirationAnimation',
      chatOverlayIncomingAnimation: 'incomingAnimation',
      chatOverlayNoBadges: 'noBadges',
      chatOverlayNoAvatars: 'noAvatars',
      chatOverlayNoUsernames: 'noUsernames',
      chatOverlayTheme: 'theme',
      chatOverlayOrientation: 'orientation',
      chatOverlayFont: 'font',
      chatOverlayFontSize: 'fontSize',
      chatOverlayBackgroundColor: 'backgroundColor',
      chatOverlayBackgroundTitleColor: 'backgroundTitleColor',
      chatOverlayBackgroundMessageColor: 'backgroundMessageColor',
      ttsChatsVolume: 'ttsVolume',
      ttsChatsMode: 'ttsChatsMode',
      useDefaultChatFilter: 'useDefaultChatFilter',
      hideChats: 'hideChats',
    };

    const urlParams = new URLSearchParams();

    Object.keys(fieldMap).forEach(field => {
      if (formData[field]) {
        const urlField = fieldMap[field];
        urlParams.set(urlField, formData[field]);
      }
    });

    // Add custom link data to URL
    if (customLinkData.maxChats) {
      urlParams.set('maxChats', customLinkData.maxChats);
    }
    if (customLinkData.autoScrollDisabled) {
      urlParams.set('autoScrollDisabled', customLinkData.autoScrollDisabled);
    }
    if (customLinkData.chatOverlayHideChats) {
      urlParams.set('hideChats', customLinkData.chatOverlayHideChats);
    }

    setUrl(`${window.location.origin}/${username}/chat?${urlParams.toString()}`);
  }, [formData, customLinkData, username]);

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedSettings = Object.fromEntries(
        Object.entries(formData).filter(([, v]) => v != null)
      );

      //default view count settings
      if (!formData.viewCountOverlayOrientation) {
        formData.viewCountOverlayOrientation = 'center';
        updatedSettings.viewCountOverlayOrientation = 'center';
      }

      if (!formData.viewCountOrientation) {
        formData.viewCountOrientation = 'horizontal';
        updatedSettings.viewCountOrientation = 'horizontal';
      }

      if (!formData.viewCountPowerchatViewers) {
        formData.viewCountPowerchatViewers = false;
        updatedSettings.viewCountPowerchatViewers = false;
      }


      //default chat overlay settings
      if (!formData.chatOverlayTtl) {
        formData.chatOverlayTtl = 0;
        updatedSettings.chatOverlayTtl = 0;
      }

      if (!formData.chatOverlayExpirationAnimation) {
        formData.chatOverlayExpirationAnimation = '';
        updatedSettings.chatOverlayExpirationAnimation = '';
      }

      if (!formData.chatOverlayIncomingAnimation) {
        formData.chatOverlayIncomingAnimation = '';
        updatedSettings.chatOverlayIncomingAnimation = '';
      }

      if (!formData.chatOverlayTheme) {
        formData.chatOverlayTheme = 'classic';
        updatedSettings.chatOverlayTheme = 'classic';
      }

      if (!formData.chatOverlayOrientation) {
        formData.chatOverlayOrientation = 'topLeft';
        updatedSettings.chatOverlayOrientation = 'topLeft';
      }

      if (!formData.chatOverlayFont) {
        formData.chatOverlayFont = 'default';
        updatedSettings.chatOverlayFont = 'default';
      }

      if (!formData.chatOverlayFontSize) {
        formData.chatOverlayFontSize = 20;
        updatedSettings.chatOverlayFontSize = 20;
      }

      if (!formData.chatOverlayEmoteSize) {
        formData.chatOverlayEmoteSize = 28;
        updatedSettings.chatOverlayEmoteSize = 28;
      }

      if (!formData.chatOverlayMessageColor) {
        formData.chatOverlayMessageColor = "#ffffff";
        updatedSettings.chatOverlayMessageColor = "#ffffff";
      }

      if (!formData.chatOverlayMessageColorRandom) {
        formData.chatOverlayMessageColorRandom = false;
        updatedSettings.chatOverlayMessageColorRandom = false;
      }

      if (!formData.chatOverlayChatterColor) {
        formData.chatOverlayChatterColor = "#5bc7ec";
        updatedSettings.chatOverlayChatterColor = "#5bc7ec";
      }

      if (!formData.chatOverlayBackgroundColor) {
        formData.chatOverlayBackgroundColor = "transparent";
        updatedSettings.chatOverlayBackgroundColor = "transparent";
      }

      if (!formData.chatOverlayBackgroundTitleColor) {
        formData.chatOverlayBackgroundTitleColor = "transparent";
        updatedSettings.chatOverlayBackgroundTitleColor = "transparent";
      }

      if (!formData.chatOverlayBackgroundMessageColor) {
        formData.chatOverlayBackgroundMessageColor = "transparent";
        updatedSettings.chatOverlayBackgroundMessageColor = "transparent";
      }

      if (!formData.chatOverlayEmotesOnly) {
        formData.chatOverlayEmotesOnly = false;
        updatedSettings.chatOverlayEmotesOnly = false;
      }

      if (!formData.chatOverlayNoEmotes) {
        formData.chatOverlayNoEmotes = false;
        updatedSettings.chatOverlayNoEmotes = false;
      }

      if (!formData.chatOverlayNoBadges) {
        formData.chatOverlayNoBadges = false;
        updatedSettings.chatOverlayNoBadges = false;
      }

      if (!formData.chatOverlayNoAvatars) {
        formData.chatOverlayNoAvatars = false;
        updatedSettings.chatOverlayNoAvatars = false;
      }

      if (!formData.chatOverlayNoUsernames) {
        formData.chatOverlayNoUsernames = false;
        updatedSettings.chatOverlayNoUsernames = false;
      }

      if (!formData.hideChats) {
        formData.hideChats = false;
        updatedSettings.hideChats = false;
      }

      if (!formData.ttsChatsMode) {
        formData.ttsChatsMode = 0;
        updatedSettings.ttsChatsMode = 0;
      }

      if (!formData.ttsChatsPerms) {
        formData.ttsChatsPerms = 0;
        updatedSettings.ttsChatsPerms = 0;
      }

      if (formData.chatReadSender === undefined || formData.chatReadSender === null) {
        formData.chatReadSender = false;
        updatedSettings.chatReadSender = false;
      }


      //default emote wall settings
      if (!formData.emoteWallAnimation) {
        formData.emoteWallAnimation = 'rainfall';
        updatedSettings.emoteWallAnimation = 'rainfall';
      }

      if (!formData.emoteWallMaxEmoteSize) {
        formData.emoteWallMaxEmoteSize = 150;
        updatedSettings.emoteWallMaxEmoteSize = 150;
      }

      if (!formData.emoteWallSpeed) {
        formData.emoteWallSpeed = 5;
        updatedSettings.emoteWallSpeed = 5;
      }

      await axios.put('/api/overlay-settings', updatedSettings);
      updateData(updatedSettings);
      triggerAlert('Overlay settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save overlay settings', false);
    }
  };

  const handleEmoteToggle = (name) => {
    const value = formData[name] ? false : true;

    switch (name) {
      case 'chatOverlayEmotesOnly':
        setFormData({
          ...formData,
          [name]: value,
          chatOverlayNoEmotes: false,
        });
        break;

      case 'chatOverlayNoEmotes':
        setFormData({
          ...formData,
          [name]: value,
          chatOverlayEmotesOnly: false,
        });
        break;

      default:
        break;
    }
  };

  const handleThemeSelectChange = (option, action) => {
    switch (option.value) {
      case 'console':
        setFormData({
          ...formData,
          [action.name]: option.value,
          chatOverlayFont: 'consolas',
          chatOverlayNoAvatars: true,
          chatOverlayNoBadges: true,
          chatOverlayBackgroundTitleColor: 'transparent',
          chatOverlayBackgroundMessageColor: 'rgba(0, 0, 0, 0.50)',
        });
        break;

      case 'bubble':
        setFormData({
          ...formData,
          [action.name]: option.value,
          chatOverlayBackgroundTitleColor: '#000000',
          chatOverlayBackgroundMessageColor: 'rgba(0, 0, 0, 0.50)',
        });
        break;

      case 'classic':
      default:
        setFormData({
          ...formData,
          [action.name]: option.value,
          chatOverlayChatterColorDefault: true,
          chatOverlayChatterColorRandom: false,
          chatOverlayMessageColorRandom: false,
          chatOverlayChatterColor: '#5bc7ec',
          chatOverlayMessageColor: '#ffffff',
          chatOverlayIncomingAnimation: '',
          chatOverlayExpirationAnimation: '',
          chatOverlayFont: 'jaldi',
          chatOverlayEmoteSize: 28,
          chatOverlayOrientation: 'topLeft',
          chatOverlayFontSize: 20,
          chatOverlayTtl: 0,
          chatOverlayNoAvatars: false,
          chatOverlayNoBadges: false,
          chatOverlayNoUsernames: false,
          chatOverlayBackgroundTitleColor: 'transparent',
          chatOverlayBackgroundMessageColor: 'transparent',
          chatOverlayBackgroundColor: 'transparent',
        });
    }
  };

  const handleChatterToggle = (name) => {
    const value = formData[name] ? false : true;

    switch (name) {
      case 'chatOverlayChatterColorDefault':
        setFormData({
          ...formData,
          [name]: value,
          chatOverlayChatterColorRandom: false,
        });
        break;

      case 'chatOverlayChatterColorRandom':
        setFormData({
          ...formData,
          [name]: value,
          chatOverlayChatterColorDefault: false,
        });
        break;

      default:
      // Do nothing
    }
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const cancelSettingsUpdate = () => {
    setFormData({ ...initialFormState });
    triggerAlert('Changes discarded');
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  return (
    <div className="settings-container form-container">
      <form onSubmit={handleSubmit}>
        <div className="settings-sections-wrapper" style={{ marginBottom: '-3rem' }}>
          <div className="settings-section">
            <h4 className="text-light-blue">Live Chat Overlay Settings:</h4>
          </div>
        </div>
        <div className="settings-sections-wrapper" style={{ marginBottom: '-1rem' }}>
          <div className="settings-section">
            <h4 className="text-light-blue">&nbsp;</h4>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="chatOverlayTtl">Chat Remove Time</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('chatOverlayTtl')}
                  />
                  {tooltip === 'chatOverlayTtl' && (
                    <p className="tooltip shadow-lg">
                      Time in seconds that a chat message will be displayed. Set to 0 to never expire.<br /><br />Default: 0
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <NumberInput
                  name="chatOverlayTtl"
                  placeholder="Chat Message TTL in Seconds"
                  value={formData.chatOverlayTtl || '0'}
                  minValue={0}
                  maxValue={86400}
                  step={1}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </div>
          </div>
          <div className="settings-section" style={{ paddingRight: '2em' }}>
            <h4 className="text-light-blue">&nbsp;</h4>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="chatOverlayEmoteSize">Max Emote Size</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('chatOverlayEmoteSize')}
                  />
                  {tooltip === 'chatOverlayEmoteSize' && (
                    <p className="tooltip shadow-lg">
                      Maximum size an emote will be in pixels. Emotes will not go larger than the actual image size.<br /><br />Default: 28
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <NumberInput
                  name="chatOverlayEmoteSize"
                  placeholder="Maximum Emote/Sticker Size in pixels"
                  value={formData.chatOverlayEmoteSize || '28'}
                  minValue={10}
                  maxValue={4000}
                  step={1}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </div>
          </div>
          <div className="settings-section radio-inputs">
            <div className="input-row radio-row">
              <div className="flex-row">
                <ToggleSwitch
                  name="chatOverlayEmotesOnly"
                  onChange={() => handleEmoteToggle('chatOverlayEmotesOnly')}
                  checked={formData.chatOverlayEmotesOnly}
                  disabled={isMod}
                />
                <label htmlFor="chatOverlayEmotesOnly">
                  Emotes Only
                </label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('chatOverlayEmotesOnly')}
                  />
                  {tooltip === 'chatOverlayEmotesOnly' && (
                    <p className="tooltip shadow-lg">
                      Only shows emotes and no regular text.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="settings-section radio-inputs">
            <div className="input-row radio-row">
              <div className="flex-row">
                <ToggleSwitch
                  name="chatOverlayNoEmotes"
                  onChange={() => handleEmoteToggle('chatOverlayNoEmotes')}
                  checked={formData.chatOverlayNoEmotes}
                  disabled={isMod}
                />
                <label htmlFor="chatOverlayNoEmotes">
                  No Emotes
                </label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('chatOverlayNoEmotes')}
                  />
                  {tooltip === 'chatOverlayNoEmotes' && (
                    <p className="tooltip shadow-lg">
                      Remove emotes from messages.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="settings-section radio-inputs">
            <div className="input-row radio-row">
              <div className="flex-row">
                <ToggleSwitch
                  name="hideChats"
                  onChange={() => handleToggle('hideChats')}
                  checked={formData.hideChats}
                  disabled={isMod}
                />
                <label htmlFor="hideChats">Hide Chats</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('hideChats')}
                  />
                {tooltip === 'hideChats' && (
                  <p className="tooltip shadow-lg">
                    Chats will no longer display on overlay. TTS may still play.
                  </p>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-sections-wrapper">
          <div className="settings-section">
            <div className="input-row" style={{ width: '100%' }}>
              <div className="flex-row">
                <label htmlFor="chatOverlayTheme">Chat Theme</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('chatOverlayTheme')}
                  />
                  {tooltip === 'chatOverlayTheme' && (
                    <p className="tooltip shadow-lg">
                      <strong>Set the theme of your chat.</strong><br />
                      We offer a variety of themes to choose from.<br />
                      <br />
                      Selecting a theme will adjust some of the customization settings so the theme works as intended. You can adjust it to your liking after selecting a theme.
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <Select
                  name="chatOverlayTheme"
                  options={themeOptions}
                  styles={voiceStyles}
                  placeholder="Theme"
                  onChange={handleThemeSelectChange}
                  value={themeOptions.find(
                    (x) => x.value == formData.chatOverlayTheme
                  )}
                  isDisabled={isMod}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-spoiler-notes">
          <div className="overlay-spoiler">
            <div className="overlay-spoiler-header">
              <h4 className="text-light-blue" onClick={() => toggleSpoiler('chatOverlayCustomization')}>
                {spoiler !== 'chatOverlayCustomization' ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color="#57bad8"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronUp}
                    color="#57bad8"
                  />
                )}
                &nbsp;&nbsp;&nbsp;
                Customize Theme
              </h4>
            </div>
            <div className={`overlay-spoiler-body ${spoiler === 'chatOverlayCustomization' ? 'open' : 'closed'}`}>
              <div className="url-preview">
                <h5>Chat Overlay URL Preview:</h5>
                <div className="flex-row">
                  <Tooltip title={copySuccess ? "Copied!" : "Copy URL"} placement="top">
                    <IconButton onClick={handleCopyUrl} size="small">
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <div className="url-container">
                    <Tooltip title={url} placement="top">
                      <span className="url-text">{url}</span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="settings-sections-wrapper" style={{ marginTop: '-2em' }}>
                <div className="settings-section" style={{ paddingRight: '2em' }}>
                  <h4 className="text-light-blue">&nbsp;</h4>
                  <div className="input-row">
                    <div className="flex-row">
                      <label htmlFor="chatOverlayChatterColor">Name Color</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayChatterColor')}
                        />
                        {tooltip === 'chatOverlayChatterColor' && (
                          <p className="tooltip shadow-lg">
                            <strong>Color of chatter. Not message.</strong><br />You can type in a hexadecimal color code or click on the color box to the left to select a color via the picker.<br />Default: #5bc7ec (Powerchat Blue)
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="tooltip-input">
                      <ColorInput
                        name="chatOverlayChatterColor"
                        value={formData.chatOverlayChatterColor || '#5bc7ec'}
                        onValueChange={(value, name) => handleChange({ target: { value, name } })}
                        disabled={isMod}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="flex-row">
                      <label htmlFor="chatOverlayMessageColor">Message Color</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayMessageColor')}
                        />
                        {tooltip === 'chatOverlayMessageColor' && (
                          <p className="tooltip shadow-lg">
                            <strong>Color of message text. Not username.</strong><br />You can type in a hexadecimal color code or click on the color box to the left to select a color via the picker.<br />Default: #ffffff (white)
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="tooltip-input">
                      <ColorInput
                        name="chatOverlayMessageColor"
                        value={formData.chatOverlayMessageColor || '#ffffff'}
                        onValueChange={(value, name) => handleChange({ target: { value, name } })}
                        disabled={isMod}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="flex-row">
                      <label htmlFor="chatOverlayBackgroundTitleColor">Name Background Color</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayBackgroundTitleColor')}
                        />
                        {tooltip === 'chatOverlayBackgroundTitleColor' && (
                          <p className="tooltip shadow-lg">
                            <strong>Background color of message title. Where avatar, badges, and chatter name is.</strong><br />You can type in a hexadecimal color code or click on the color box to the left to select a color via the picker.<br /><br />Default: transparent
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="tooltip-input">
                      <ColorInput
                        opacity={true}
                        name="chatOverlayBackgroundTitleColor"
                        value={formData.chatOverlayBackgroundTitleColor || 'transparent'}
                        onValueChange={(value, name) => handleChange({ target: { value, name } })}
                        disabled={isMod}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="flex-row">
                      <label htmlFor="chatOverlayBackgroundMessageColor">Message Background Color</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayBackgroundMessageColor')}
                        />
                        {tooltip === 'chatOverlayBackgroundMessageColor' && (
                          <p className="tooltip shadow-lg">
                            <strong>Background color of message.</strong><br />You can type in a hexadecimal color code or click on the color box to the left to select a color via the picker.<br /><br />Default: transparent
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="tooltip-input">
                      <ColorInput
                        opacity={true}
                        name="chatOverlayBackgroundMessageColor"
                        value={formData.chatOverlayBackgroundMessageColor || 'transparent'}
                        onValueChange={(value, name) => handleChange({ target: { value, name } })}
                        disabled={isMod}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="flex-row">
                      <label htmlFor="chatOverlayBackgroundColor">Overlay Background Color</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayBackgroundColor')}
                        />
                        {tooltip === 'chatOverlayBackgroundColor' && (
                          <p className="tooltip shadow-lg">
                            <strong>Background color of chat overlay.</strong><br />You can type in a hexadecimal color code or click on the color box to the left to select a color via the picker.<br /><br />Default: transparent
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="tooltip-input">
                      <ColorInput
                        opacity={true}
                        name="chatOverlayBackgroundColor"
                        value={formData.chatOverlayBackgroundColor || 'transparent'}
                        onValueChange={(value, name) => handleChange({ target: { value, name } })}
                        disabled={isMod}
                      />
                    </div>
                  </div>
                </div>
                <div className="settings-section" style={{ paddingRight: '2em' }}>
                  <h4 className="text-light-blue">&nbsp;</h4>
                  <div className="input-row">
                    <div className="flex-row">
                      <label htmlFor="chatOverlayFont">Chat Font Style</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayFont')}
                        />
                        {tooltip === 'chatOverlayFont' && (
                          <p className="tooltip shadow-lg">
                            <strong>Set the font of your chat.</strong><br />
                            We offer a variety of fonts to choose from.<br />
                            <br />
                            Default: Jaldi
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="tooltip-input">
                      <FontSelector
                        name="chatOverlayFont"
                        fonts={fontOptions}
                        styles={fontStyles}
                        placeholder="Font"
                        onFontChange={handleSelectChange}
                        value={fontOptions.find((x) => x.value == formData.chatOverlayFont)}
                        disabled={isMod}
                      />
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="flex-row">
                      <label htmlFor="chatOverlayFontSize">Chat Font Size (pixels)</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayFontSize')}
                        />
                        {tooltip === 'chatOverlayFontSize' && (
                          <p className="tooltip shadow-lg">
                            Font size in pixels.<br />Default: 20.00
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="tooltip-input">
                      <NumberInput
                        name="chatOverlayFontSize"
                        placeholder="Font size"
                        value={formData.chatOverlayFontSize || '20'}
                        minValue={8}
                        maxValue={256}
                        step={1}
                        onValueChange={(value, name) =>
                          handleChange({ target: { value, name } })
                        }
                        disabled={isMod}
                      />
                    </div>
                  </div>
                  <div className="select-row tooltip-wrapper">
                    <div className="input-row" style={{ width: '100%' }}>
                      <label htmlFor="chatOverlayIncomingAnimation">Chat Add Animation</label>
                      <Select
                        name="chatOverlayIncomingAnimation"
                        options={incomingAnimationOptions}
                        styles={voiceStyles}
                        placeholder="Animation"
                        onChange={handleSelectChange}
                        value={incomingAnimationOptions.find(
                          (x) => x.value == formData.chatOverlayIncomingAnimation
                        )}
                        isDisabled={isMod}
                      />
                    </div>
                  </div>
                  <div className="select-row tooltip-wrapper">
                    <div className="input-row" style={{ width: '100%' }}>
                      <label htmlFor="chatOverlayExpirationAnimation">Chat Remove Animation</label>
                      <Select
                        name="chatOverlayExpirationAnimation"
                        options={expirationAnimationOptions}
                        styles={voiceStyles}
                        placeholder="Animation"
                        onChange={handleSelectChange}
                        value={expirationAnimationOptions.find(
                          (x) => x.value == formData.chatOverlayExpirationAnimation
                        )}
                        isDisabled={isMod}
                      />
                    </div>
                  </div>

                  <div className="select-row tooltip-wrapper">
                    <div className="input-row" style={{ width: '100%' }}>
                      <label htmlFor="chatOverlayOrientation">Chat Orientation</label>
                      <Select
                        name="chatOverlayOrientation"
                        options={chatOrientationOptions}
                        styles={voiceStyles}
                        placeholder="Orientation"
                        onChange={handleSelectChange}
                        value={chatOrientationOptions.find(
                          (x) => x.value == formData.chatOverlayOrientation        
                        )}
                        isDisabled={isMod}
                      />
                    </div>
                  </div>
                </div>
                <div className="settings-section radio-inputs">
                  <div className="input-row radio-row">
                    <div className="flex-row">
                      <ToggleSwitch
                        name="chatOverlayChatterColorDefault"
                        onChange={() => handleChatterToggle('chatOverlayChatterColorDefault')}
                        checked={formData.chatOverlayChatterColorDefault}
                        disabled={isMod}
                      />
                      <label htmlFor="chatOverlayChatterColorDefault">
                        Default Chatter Colors
                      </label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayChatterColorDefault')}
                        />
                        {tooltip === 'chatOverlayChatterColorDefault' && (
                          <p className="tooltip shadow-lg">
                            Ignores the chatter color and instead uses the default platform specific colors.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-row radio-row">
                    <div className="flex-row">
                      <ToggleSwitch
                        name="chatOverlayChatterColorRandom"
                        onChange={() => handleChatterToggle('chatOverlayChatterColorRandom')}
                        checked={formData.chatOverlayChatterColorRandom}
                        disabled={isMod}
                      />
                      <label htmlFor="chatOverlayChatterColorRandom">
                        Random Chatter Colors
                      </label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayChatterColorRandom')}
                        />
                        {tooltip === 'chatOverlayChatterColorRandom' && (
                          <p className="tooltip shadow-lg">
                            Ignores the chatter color and instead picks a random color for each message.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-row radio-row">
                    <div className="flex-row">
                      <ToggleSwitch
                        name="chatOverlayMessageColorRandom"
                        onChange={() => handleToggle('chatOverlayMessageColorRandom')}
                        checked={formData.chatOverlayMessageColorRandom}
                        disabled={isMod}
                      />
                      <label htmlFor="chatOverlayMessageColorRandom">
                        Random Message Colors
                      </label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('chatOverlayMessageColorRandom')}
                        />
                        {tooltip === 'chatOverlayMessageColorRandom' && (
                          <p className="tooltip shadow-lg">
                            Ignores the chat message color and instead picks a random color for each message.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="input-row radio-row">
                    <div className="flex-row">
                      <ToggleSwitch
                        name="chatOverlayNoBadges"
                        onChange={() => handleToggle('chatOverlayNoBadges')}
                        checked={formData.chatOverlayNoBadges}
                        disabled={isMod}
                      />
                      <label htmlFor="chatOverlayNoBadges">
                        No Badges
                      </label>
                    </div>
                  </div>
                  <div className="input-row radio-row">
                    <div className="flex-row">
                      <ToggleSwitch
                        name="chatOverlayNoAvatars"
                        onChange={() => handleToggle('chatOverlayNoAvatars')}
                        checked={formData.chatOverlayNoAvatars}
                        disabled={isMod}
                      />
                      <label htmlFor="chatOverlayNoAvatars">
                        No Avatars
                      </label>
                    </div>
                  </div>
                  <div className="input-row radio-row">
                    <div className="flex-row">
                      <ToggleSwitch
                        name="chatOverlayNoUsernames"
                        onChange={() => handleToggle('chatOverlayNoUsernames')}
                        checked={formData.chatOverlayNoUsernames}
                        disabled={isMod}
                      />
                      <label htmlFor="chatOverlayNoUsernames">
                        No Usernames
                      </label>
                    </div>
                  </div>
                  <div className="input-row radio-row">
                    <div className="flex-row">
                      <ToggleSwitch
                        name="chatOverlayHideChats"
                        onChange={() => handleCustomLinkToggle('chatOverlayHideChats')}
                        checked={formData.chatOverlayHideChats}
                        disabled={isMod}
                      />
                      <label htmlFor="chatOverlayHideChats">
                        Hide Chats
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="settings-sections-wrapper">
                <div className="settings-section">
                  <div className="custom-chat-options">
                    <div className="options-content">
                      <div className="input-row">
                        <div className="flex-row">
                          <label htmlFor="maxChats">Max chats on screen</label>
                          <div className="icon-btn tooltip-wrapper">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              color="#57bad8"
                              onClick={() => toggleTooltip('maxChats')}
                            />
                            {tooltip === 'maxChats' && (
                              <p className="tooltip shadow-lg">
                                Set the maximum number of chat messages to display on screen at once. (1-300)
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="tooltip-input">
                          <NumberInput
                            name="maxChats"
                            placeholder="Max chats"
                            value={customLinkData.maxChats}
                            minValue={1}
                            maxValue={300}
                            step={1}
                            onValueChange={(value, name) =>
                              handleCustomLinkChange({ target: { value, name } })
                            }
                            disabled={isMod}
                          />
                        </div>
                      </div>
                      <div className="input-row radio-row">
                        <div className="flex-row">
                          <ToggleSwitch
                            name="autoScrollDisabled"
                            onChange={() => handleCustomLinkToggle('autoScrollDisabled')}
                            checked={customLinkData.autoScrollDisabled}
                            disabled={isMod}
                          />
                          <label htmlFor="autoScrollDisabled">
                            Auto-Scroll Disabled
                          </label>
                          <div className="icon-btn tooltip-wrapper">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              color="#57bad8"
                              onClick={() => toggleTooltip('autoScrollDisabled')}
                            />
                            {tooltip === 'autoScrollDisabled' && (
                              <p className="tooltip shadow-lg">
                                Disable automatic scrolling of chat messages.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-warning">
                      These settings will only affect your custom link and won&apos;t save to your settings.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr className="hr-light-blue" />

        <div className="settings-sections-wrapper">
          <div className="settings-section viewCountOverlay-section">
            <h4 id="overlay-settings" className="text-light-blue">View Count Overlay Settings:</h4>
            <div className="viewCountOverlay-input-row">
              <div className="overlay-select-column">
                <label htmlFor="viewCountOverlayOrientation">
                  Overlay Orientation
                  <span className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountOverlayOrientation')}
                    />
                    {tooltip === 'viewCountOverlayOrientation' && (
                      <p className="tooltip shadow-lg">
                        Set the orientation of view count overlay.<br />
                      </p>
                    )}
                  </span>
                </label>
                <div className="tooltip-input">
                  <Select
                    name="viewCountOverlayOrientation"
                    options={overlayOverallOrientationOptions}
                    styles={selectStyles}
                    placeholder="Orientation"
                    onChange={handleSelectChange}
                    value={overlayOverallOrientationOptions.find((x) => x.value == formData.viewCountOverlayOrientation)}
                    isDisabled={isMod}
                  />
                </div>
              </div>
              <div className="overlay-select-column">
                <label htmlFor="viewCountOrientation">
                  Count Orientation
                  <span className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountOrientation')}
                    />
                    {tooltip === 'viewCountOrientation' && (
                      <p className="tooltip shadow-lg">
                        Set the orientation of your platform view counts.<br />
                        <br />
                        <b>Orientation</b><br />
                        - Vertical will stack the view count on top of each other.<br />
                        - Horizontal will place them side by side.<br />
                      </p>
                    )}
                  </span>
                </label>
                <div className="tooltip-input">
                  <Select
                    name="viewCountOrientation"
                    options={overlayOrientationOptions}
                    styles={selectStyles}
                    placeholder="Orientation"
                    onChange={handleSelectChange}
                    value={overlayOrientationOptions.find((x) => x.value == formData.viewCountOrientation)}
                    isDisabled={isMod}
                  />
                </div>
              </div>
              <div className="overlay-select-column viewCountOverlay-toggle">
                <ToggleSwitch
                  name="viewCountPowerchatViewers"
                  onChange={() => handleToggle('viewCountPowerchatViewers')}
                  checked={formData.viewCountPowerchatViewers}
                  disabled={isMod}
                />
                <label htmlFor="viewCountPowerchatViewers">
                  Combined viewer count
                  <span className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountPowerchatViewers')}
                    />
                    {tooltip === 'viewCountPowerchatViewers' && (
                      <p className="tooltip shadow-lg">
                        This option will show the Powerchat logo and a combined count of all viewers from your external integrations.<br />
                        <br />
                        Note: This option hides the external integration logos and viewer counts on the overlay when enabled.
                      </p>
                    )}
                  </span>
                </label>
              </div>
            </div>
            <div className="viewCountOverlay-notes">
              Notes:<br />
              <i>
                - Settings may take up to a minute to update on your overlay automatically, you can refresh the overlay, after saving, if you do not want to wait.<br />
                - If you want to show or hide your external integration views, these settings are on the <a href="/creator-dashboard?tab=external-integrations">External Integrations</a> page.
              </i>
            </div>
          </div>
        </div>

        <div className="settings-sections-wrapper">
          <div className="settings-section">
            <h4 id="emote-wall-settings" className="text-light-blue">Emote Wall Overlay Settings:</h4>
            <div className="flex-row">
              <div className="overlay-select-column">
                <label htmlFor="emoteWallAnimation">
                  Animation
                  <span className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('emoteWallAnimation')}
                    />
                    {tooltip === 'emoteWallAnimation' && (
                      <p className="tooltip shadow-lg" style={{ zIndex: 9999 }}>
                        Selects the animation style for how your emotes will display on the screen.<br />
                        See below for more details on the different animation styles.
                      </p>
                    )}
                  </span>
                </label>
                <div className="tooltip-input">
                  <Select
                    name="emoteWallAnimation"
                    options={emoteWallAnimationOptions}
                    styles={selectStyles}
                    placeholder="Animation"
                    onChange={handleSelectChange}
                    value={emoteWallAnimationOptions.find((x) => x.value == formData.emoteWallAnimation) || emoteWallAnimationOptions[0]}
                    isDisabled={isMod}
                  />
                </div>
              </div>
              <div className="overlay-select-column">
                <label htmlFor="emoteWallMaxEmoteSize">
                  Max Emote Size (pixels)
                  <span className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('emoteWallMaxEmoteSize')}
                    />
                    {tooltip === 'emoteWallMaxEmoteSize' && (
                      <p className="tooltip shadow-lg">
                        Sets the maximum size of the emotes that will display on the screen.<br />
                        This value is in pixels.
                      </p>
                    )}
                  </span>
                </label>
                <div className="tooltip-input">
                  <NumberInput
                    name="emoteWallMaxEmoteSize"
                    placeholder="Max Emote Size"
                    value={formData.emoteWallMaxEmoteSize || '150'}
                    minValue={10}
                    maxValue={1000}
                    step={10}
                    onValueChange={(value, name) =>
                      handleChange({ target: { value, name } })
                    }
                    disabled={isMod}
                  />
                </div>
              </div>
              <div className="overlay-select-column">
                <label htmlFor="emoteWallSpeed">
                  Speed (seconds)
                  <span className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('emoteWallSpeed')}
                    />
                    {tooltip === 'emoteWallSpeed' && (
                      <p className="tooltip shadow-lg">
                        Sets the max time an emote will take to complete it&apos;s animation.<br />
                        This value is in seconds.
                      </p>
                    )}
                  </span>
                </label>
                <div className="tooltip-input">
                  <NumberInput
                    name="emoteWallSpeed"
                    placeholder="Speed"
                    value={formData.emoteWallSpeed || '5'}
                    minValue={1}
                    maxValue={300}
                    step={1}
                    onValueChange={(value, name) =>
                      handleChange({ target: { value, name } })
                    }
                    disabled={isMod}
                  />
                </div>
              </div>
            </div>

            <div className="overlay-spoiler-notes">
              <div className="overlay-spoiler">
                <div className="overlay-spoiler-header">
                  <h3 onClick={() => toggleSpoiler('emoteWallAnimation')} >
                    {spoiler !== 'emoteWallAnimation' ? (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        color="#57bad8"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        color="#57bad8"
                      />
                    )}
                    &nbsp;&nbsp;&nbsp;
                    More about animations
                  </h3>
                </div>
                <div className={`overlay-spoiler-body ${spoiler === 'emoteWallAnimation' ? 'open' : 'closed'}`}>
                  <div className="overlay-spoiler-row">

                    {/* Rainfall */}
                    <div className="flex-column overlay-spoiler-column">
                      <h4 className="text-light-blue">Rainfall</h4>
                      <p>
                        <img className="overlay-preview-img" src="../static/img/overlay-examples/rainfall.gif" alt="Rainfall Animation" />
                      </p>
                      <p>
                        Emotes will fall from top to bottom like rainfall.
                      </p>
                    </div>

                    {/* Screensaver */}
                    <div className="flex-column overlay-spoiler-column">
                      <h4 className="text-light-blue">Screensaver</h4>
                      <p className="align-center">
                        <img className="overlay-preview-img" src="../static/img/overlay-examples/screensaver.gif" alt="Screensaver Animation" />
                      </p>
                      <p className="align-center">
                        Emotes move in a random direciton and bounce off the walls, before fading out.
                      </p>
                    </div>

                    {/* Fireworks */}
                    <div className="flex-column overlay-spoiler-column">
                      <h4 className="text-light-blue">Fireworks</h4>
                      <p className="align-center">
                        <img className="overlay-preview-img" src="../static/img/overlay-examples/fireworks.gif" alt="Fireworks Animation" />
                      </p>
                      <p className="align-center">
                        Turn chat emotes into a Fireworks display. They shoot up from the bottom and explode in the middle of your scene. Best if used as a full screen source.
                      </p>
                    </div>

                    {/* Explosion */}
                    <div className="flex-column overlay-spoiler-column">
                      <h4 className="text-light-blue">Explosion</h4>
                      <p className="align-center">
                        <img className="overlay-preview-img" src="../static/img/overlay-examples/explosion.gif" alt="Explosion Animation" />
                      </p>
                      <p className="align-center">
                        Emotes explode out from the center of your source.
                      </p>
                    </div>

                    {/* Decompression */}
                    <div className="flex-column overlay-spoiler-column">
                      <h4 className="text-light-blue">Decompression</h4>
                      <p className="align-center">
                        <img className="overlay-preview-img" src="../static/img/overlay-examples/decompression.gif" alt="Decompression Animation" />
                      </p>
                      <p className="align-center">
                        Emotes explode out from the center of your source and then get sucked back into the center.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="hr-light-blue" />

        {!isMod && (
          <div>
            <button type="submit">Save</button>
            <button
              type="button"
              className="secondary-btn cancel-btn"
              onClick={cancelSettingsUpdate}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <FormAlert alertState={alertState} width={450} />
    </div>
  );
};

export default OverlaySettings;
