import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import '../css/live-chat.css';
import '../css/fonts.css';
import axios from 'axios';
import Sockette from 'sockette';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'react-avatar';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { filterProfanity } from '../../server/api/textFilters';
import parse from 'html-react-parser';

const LiveChat = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const pingTime = 5 * 60 * 1000; //5 minutes
  const updateTime = 1 * 60 * 1000; // 1 minute
  const TTSMONSTER_MESSAGE_COOLDOWN = 30000; // 30 seconds
  const colors = ['orange', 'white', 'lightyellow', 'lightblue', 'lightgreen', 'lightpink', 'lightgray', 'tomato', 'gold', 'palegreen', 'lightseagreen', 'mediumaquamarine', 'aquamarine', 'lightskyblue', 'mediumspringgreen', 'plum', 'lightsalmon', 'lightslategray', 'mediumorchid', 'mediumslateblue', 'lightcoral', 'khaki', 'palevioletred', 'palegoldenrod', 'mediumturquoise', 'mintcream', 'wheat', 'lavender', 'mistyrose', 'peachpuff'];
  const lastPickedColors = useRef({});
  const defaultMessageColor = 'white'; // Define a default message color
  const defaultChatterColor = ''; // Define a default chatter color, leave blank to allow css to handle it
  
  const { username } = useParams();
  const [chats, setChats] = useState([]);
  const settingsLoaded = useRef(false);

  const ttsQueue = useRef([]);
  const ttsTimeout = useRef(null);
  const maxTtsSeconds = useRef(40);
  const audioText = useRef('');
  const ttsAudioRef = useRef();
  const willPlayTTS = useRef(null);

  const useDefaultChatFilter = useRef(true);
  const profanity = useRef([]);
  
  const [chatSetting, setChatSettings] = useState({ maxChats: 50, autoScrollDisabled: false });
  const chatSettingRef = useRef({ maxChats: 50, autoScrollDisabled: false });  

  const latestMessageTimestampRef = useRef(null);
  const [expirationQueue, setExpirationQueue] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);

  const containerRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const ttsMonsterGens = useRef({});

  useEffect(() => {
    const initializeSettings = async () => {
      if (window.location.href.includes("#")) {
        // Create a new URL that replaces all instances of # with its encoded value (%23)
        let newURL = window.location.href.split("#").join("%23");
        window.location.replace(newURL);
      }

      await getChatSettings(username);
      const refreshSettings = setInterval(() => {
        getChatSettings(username);
      }, updateTime);
  
      let { cozyUser, youtubeUser, rumbleUser } = chatSettingRef.current;
      if (cozyUser === true || youtubeUser === true || rumbleUser === true) {
        chatPing({ cozyUser, youtubeUser, rumbleUser });
        setInterval(() => chatPing({ cozyUser, youtubeUser, rumbleUser }), pingTime);
      }
  
      useDefaultChatFilter.current = chatSettingRef.current.useDefaultChatFilter;
      if (chatSettingRef.current.useDefaultChatFilter) {
        profanity.current = chatSettingRef.current.profanity;
      }
  
      startWebsockets();
  
      if (!settingsLoaded.current && !isNaN(chatSettingRef.current.ttsVolume)) {
        // Ensure ttsAudioRef.current is available
        if (ttsAudioRef.current) {
          initializeTTS();
          settingsLoaded.current = true;
        } else {
          // Wait until ttsAudioRef.current is set
          const intervalId = setInterval(() => {
            if (ttsAudioRef.current) {
              initializeTTS();
              settingsLoaded.current = true;
              clearInterval(intervalId);
            }
          }, 100);
        }
      }
  
      return () => {
        clearInterval(refreshSettings); // Cleanup the interval on component unmount
      };
    };
  
    initializeSettings();
  }, []);

  const getChatSettings = async (username) => {
    const settings = await axios.get('/pubapi/chat-settings', { params: { username } });

    // loop through url flags to set chat settings
    for (let [key, value] of params) {

      // Skip 'maxChats' and 'autoScrollDisabled' as they are handled separately
      if (key === 'maxChats' || key === 'autoScrollDisabled') {
        continue; // Skip to the next parameter
      }

      // if the key is not a valid chatSetting.key skip it.
      if (!Object.keys(settings.data.chatSetting).includes(key)) {
        console.warn(`${key} is not a valid chat setting. It will be ignored.`);
        continue;
      } else {
        // if the key is a valid chatSetting.key set the value
        switch(true){
          case value === 'true':
            value = true;
            break;

          case value === 'false':
            value = false;
            break;

          case isNaN(value):
            break;
          
          default:
            value = Number(value);
        }
        settings.data.chatSetting[key] = value;
      }
    }

    // Handle maxChats URL parameter
    if (params.has('maxChats')) {
      const maxChatsValue = Number(params.get('maxChats'));
      if (!isNaN(maxChatsValue) && maxChatsValue > 0) {
        settings.data.chatSetting.maxChats = maxChatsValue;
      }
    }

    // Handle autoScrollDisabled URL parameter
    if (params.has('autoScrollDisabled')) {
      const autoScrollDisabledValue = params.get('autoScrollDisabled') === 'true';
      settings.data.chatSetting.autoScrollDisabled = autoScrollDisabledValue;
    }

    // Only update the settings if they change. This prevents an infinite loop.
    if (JSON.stringify(settings.data.chatSetting) !== JSON.stringify(chatSettingRef.current)) {
      console.info('Custom Chat Settings:', settings.data.chatSetting);
      setChatSettings(settings.data.chatSetting);
      chatSettingRef.current = settings.data.chatSetting;
    }

    if (!settingsLoaded.current && !isNaN(chatSettingRef.current.ttsVolume)) {
      settingsLoaded.current = true;
      initializeTTS();
    }
  };

  useEffect(() => {
    if (chatSetting.ttl < 1) {
      return;  // Exit early if TTL is < 1, no need to set up the interval
    }

    const checkExpiry = () => {
      const now = Date.now();
    
      const newChats = chats.map(c => {
        // If ttl is < 1, then the chat never expires. Skip the expiration logic.
        if (chatSetting.ttl < 1) {
          return c;
        }
          
        const expiryTime = c.timestamp + (chatSetting.ttl * 1000);
        if (now > expiryTime - 1000 && !c.expiring) {
          return { ...c, expiring: true };
        }
        return c;
      }).filter(c => {
        if (chatSetting.ttl === 0) {
          return true;  // Always keep the chat if ttl is 0
        }
          
        const expiryTime = c.timestamp + (chatSetting.ttl * 1000);
        if (now <= expiryTime) return true;
    
        if (!c.expiring) { // Only add to the queue if not already expiring
          setExpirationQueue(prevQueue => [...prevQueue, c]);
        }
        return false;
      });
    
      setChats(newChats);
    };
  
    const intervalId = setInterval(checkExpiry, 100); // Check every 100ms

    return () => clearInterval(intervalId);
  }, [chats, chatSetting.ttl]);
  
  useEffect(() => {
    if (!expirationQueue.length || isAnimating) {
      return;
    }
  
    const chatToAnimate = expirationQueue[0];
  
    animateChatExpiration(chatToAnimate).then(() => {
      setExpirationQueue(prevQueue => prevQueue.slice(1));
    });
  }, [expirationQueue, isAnimating]);

  useEffect(() => {
    const existingScript = document.getElementById('aws');
    if (!existingScript) {
      const script = document.createElement('script');
      script.setAttribute(
        'src',
        'https://sdk.amazonaws.com/js/aws-sdk-2.864.0.min.js'
      );
      script.id = 'aws';
      script.onload = loadPolly;
      document.head.appendChild(script);
    } else {
      loadPolly();
    }
  }, []);

  const animateChatExpiration = (chat) => {
    return new Promise((resolve) => {
      setIsAnimating(true);
      setTimeout(() => {
        setChats(prevChats => prevChats.filter(c => c.timestamp !== chat.timestamp));
        setIsAnimating(false);
        resolve();
      }, 1000);
    });
  };

  const chatPing = async ({ cozyUser, youtubeUser, rumbleUser }) => {
    await axios.post('/pubapi/chat-ping', { username, cozyUser, youtubeUser, rumbleUser });
  };
  
  const pickRandomColor = (type) => {
    let excludeColor = lastPickedColors.current[type];
    let availableColors = colors;
    if (excludeColor) {
      availableColors = colors.filter(color => color !== excludeColor);
    }
    const pickedColor = availableColors[Math.floor(Math.random() * availableColors.length)];
    lastPickedColors.current[type] = pickedColor;  // Update the last picked color for this type
    return pickedColor;
  };

  const handleModeration = (moderationEvent) => {
    console.log('Processing moderation event:', moderationEvent);
    
    switch (moderationEvent.action) {
      case 'messageDeleted':
        setChats(prevChats => {
          const newChats = prevChats.filter(chat => chat.messageId !== moderationEvent.messageId);
          removeTTSFromQueue(moderationEvent.messageId);
          console.log('Chats after message deletion:', newChats);
          return newChats;
        });
        break;
      case 'userBanned':
        setChats(prevChats => {
          const newChats = prevChats.filter(chat => chat.nick.toLowerCase().replace(/:$/, '') !== moderationEvent.bannedUsername.toLowerCase());
          removeTTSFromQueue(moderationEvent.messageId);
          console.log('Chats after user ban:', newChats);
          return newChats;
        });
        break;
      default:
        console.warn('Unknown moderation action:', moderationEvent.action);
    }
  };

  const addTTSToQueue = (ttsMessage, messageId, voice, voiceTypeId, generatePromise) => {
    // Add the message to the tts queue.
    // Keep track of the message id associated with each message for moderation purposes.
    // Keep track of the time the message was added to the queue so we can add a 3 second delay to allow for moderation
    ttsQueue.current.push({ ttsMessage, messageId, addedAt: Date.now(), voice, voiceTypeId, generatePromise });
    playTTSQueue();
  };

  const removeTTSFromQueue = (messageId) => {
    // Remove the message from the tts queue
    ttsQueue.current = ttsQueue.current.filter(tts => tts.messageId !== messageId);
  };

  const playTTSQueue = () => {
    // If the tts queue is empty, return early
    if (ttsQueue.current.length === 0) {
      return;
    }

    // If the tts is not playing and the next tts is over 3 seconds old, play the next tts
    if (!willPlayTTS.current && Date.now() - ttsQueue.current[0].addedAt > 3000) {
      playNextTTS();
    }

    // If the tts is playing, check again in 1 second
    setTimeout(playTTSQueue, 1000);
  };

  const playNextTTS = () => {
    // If the tts queue is empty, return early
    if (ttsQueue.current.length === 0) {
      return;
    }

    // Set the tts message to be played
    audioText.current = ttsQueue.current[0].ttsMessage;

    // Play the tts message
    playTTS();
  };

  const playTTS = () => {
    if (willPlayTTS.current) {
      return;
    }
  
    willPlayTTS.current = true;
  
    loadTTS();
  
    ttsTimeout.current = setTimeout(() => {
      stopTTS();
    }, maxTtsSeconds.current * 1000);
  };  

  const stopTTS = () => {
    // Stop the tts
    willPlayTTS.current = false;
    
    // Check if the audio is playing before attempting to pause
    if (!ttsAudioRef.current.paused && !ttsAudioRef.current.ended) {
      ttsAudioRef.current.pause();
      ttsAudioRef.current.currentTime = 0; // Reset playback position
    }
    
    ttsQueue.current.shift();
    clearTimeout(ttsTimeout.current);

    // Attempt to play the next TTS message if any
    if (ttsQueue.current.length > 0) {
      playTTSQueue();
    }
  };  

  const initializeTTS = () => {
    if (ttsAudioRef.current) {
      ttsAudioRef.current.volume = chatSettingRef.current.ttsVolume / 100;
  
      ttsAudioRef.current.onended = () => {
        stopTTS();
      };
  
      ttsAudioRef.current.onerror = (e) => {
        console.error('TTS Audio Error:', e);
      };
  
      ttsAudioRef.current.onplay = () => {
        console.info('TTS is playing:', audioText.current);
      };
    } else {
      console.error('ttsAudioRef.current is null');
    }
  };  

  const loadTTS = () => {

    // TTS Monster
    if (ttsQueue.current[0].voiceTypeId == 12) {
      ttsQueue.current[0].generatePromise.then(result => {
        ttsAudioRef.current.src = `/audioproxy?url=${result.data.url}`;
        ttsAudioRef.current.load();
        ttsAudioRef.current.play().catch((error) => {
          console.error('TTS Playback failed:', error);
        });
      });
      return;
    }

    // Create the JSON parameters for getSynthesizeSpeechUrl
    var speechParams = {
      OutputFormat: 'mp3',
      SampleRate: '16000',
      Text: '',
      TextType: 'text',
      VoiceId: ttsQueue.current[0].voice,
      Engine: 'standard', // forcing non-neural voices
    };

    // newscaster voice
    if (ttsQueue.current[0].voiceTypeId == 2) {
      speechParams.TextType = 'ssml';
      speechParams.Text = `<speak><amazon:domain name="news"><![CDATA[${audioText.current}]]></amazon:domain></speak>`;
    }
    // whisper voice
    else if (ttsQueue.current[0].voiceTypeId == 3) {
      speechParams.TextType = 'ssml';
      speechParams.Text = `<speak><amazon:effect name="whispered"><prosody rate="-10%" volume="loud"><![CDATA[${audioText.current}]]></prosody></amazon:effect></speak>`;
    }
    // pitch altered
    else if (ttsQueue.current[0].voiceTypeId == 4) {
      speechParams.TextType = 'ssml';
      speechParams.Text = `<speak><amazon:effect vocal-tract-length="-15%"><![CDATA[${audioText.current}]]></amazon:effect></speak>`;
    }
    else {
      speechParams.Text = audioText.current;
    }

    // Create the Polly service object and presigner object
    var polly = new window.AWS.Polly({ apiVersion: '2016-06-10' });
    var signer = new window.AWS.Polly.Presigner(speechParams, polly);

    // Create presigned URL of synthesized speech file
    signer.getSynthesizeSpeechUrl(speechParams, function (error, url) {
      if (error) {
        abortTtsPlayback();
      } else {
        ttsAudioRef.current.src = url;
        ttsAudioRef.current.load();
        ttsAudioRef.current.play().catch((error) => {
          console.error('TTS Playback failed:', error);
        });
      }
    });
  }

  const abortTtsPlayback = () => { };

  const loadPolly = () => {
    window.AWS.config.region = process.env.AWS_REGION;
    window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
      IdentityPoolId: process.env.AWS_KEY,
    });
  };

  const addChat = (chat) => {
    chat.timestamp = new Date().getTime();
    latestMessageTimestampRef.current = chat.timestamp;
    chat.ttl = chatSettingRef.current.ttl ? chatSettingRef.current.ttl : 0;
    chat.expirationAnimation = chatSettingRef.current.expirationAnimation;
    chat.expiring = false;
    chat.incomingAnimation = chatSettingRef.current.incomingAnimation;
    chat.isViewerPro = chat.isViewerPro || false;
    chat.isStreamerPro = chat.isStreamerPro || false;
    chat.ttsBadgeType = chat.ttsBadgeType || null;

    if(chat.source === 'x') {
      switch (chatSettingRef.current.xUsernameStyle) {
        case 0:
          // Username only, split by " @". first half is the username
          chat.nick = chat.nick.split(' @')[0] + ':';
          break;

        case 1:
          // @Handle Only, split by " @". second half is the handle
          chat.nick = '@' + chat.nick.split(' @')[1];
          break;

        default:
          // Do nothing, already set to username and handle
      }
    }

    setChats((prevChats) => {
      const newChats = [...prevChats, chat];
      const maxChats = Math.min(chatSettingRef.current.maxChats || 50, 300); 
      newChats.splice(0, newChats.length - maxChats);
      return newChats;
    });

    scrollToBottom();
  };

  const displayReceivedMessage = async (data) => {
    if (!data) {
      console.info(`Invalid data received:`, data);
      return;
    }

    if (data.type === 'moderation') {
      console.log('Received moderation event:', data);
      handleModeration(data);
      return;
    }

    // Check if message is an array and has at least one element
    if (!Array.isArray(data.message) || data.message.length === 0) {
      console.info(`Invalid message format:`, data.message);
      return;
    }

    console.log('Received message:', data);

    let wordwrapMessage = data.message;
    
    data.emote = false;
    data.sticker = false;

    if (data.message.some(element => element.includes('chat_emote'))) {
      data.emote = true;
    }

    if (useDefaultChatFilter.current) {
      wordwrapMessage = filterProfanity(wordwrapMessage.join(' '), profanity.current).split(' ');
    }

    let messageColor = defaultMessageColor;
    let chatterColor = defaultChatterColor;

    if (Object.keys(chatSettingRef.current).length !== 0) {
      if(chatSettingRef.current.messageColorRandom) {
        messageColor = pickRandomColor('message');
      } else if(chatSettingRef.current.messageColor) {
        messageColor = chatSettingRef.current.messageColor;
      }

      // Only use pro color if user is pro, otherwise use chat settings
      if (data.isViewerPro || data.isStreamerPro) {
        chatterColor = data.chatterColor;
      } else {
        switch(true) {
          case chatSettingRef.current.chatterColorDefault:
            chatterColor = defaultChatterColor;
            break;
    
          case chatSettingRef.current.chatterColorRandom:
            chatterColor = pickRandomColor('chatter');
            break;
    
          case chatSettingRef.current.chatterColor && chatSettingRef.current.chatterColor !== "":
            chatterColor = chatSettingRef.current.chatterColor;
            break;
    
          default:
            chatterColor = defaultChatterColor;
        }
      }
    }

    wordwrapMessage = wordwrapMessage.flatMap(w => {
      let parsed = parse.default(w.replace(/&nbsp;/g, ' '));
      if (!parsed || typeof(parsed) == 'string' || !parsed.map) {
        return [w];
      } else {
        let word = '';
        return parsed.map(x => {
          if (typeof(x) == 'string') {
            word = x;
            return x;
          } else {
            return w.replace(new RegExp(word), '');
          }
        });
      }
    });

    addChat({
      messageId: data.messageId,
      nick: data.username,
      text: wordwrapMessage,
      emote: data.emote,
      sticker: data.sticker,
      banned: false,
      mod: data.mod,
      source: data.source,
      avatar: data.avatar,
      chatterColor: chatterColor,
      badges: data.badges,
      color: messageColor,
      isViewerPro: data.isViewerPro,
      isStreamerPro: data.isStreamerPro,
      ttsBadgeType: data.ttsBadgeType,
    });

    // Play TTS Message
    let ttsMessage = wordwrapMessage
      .filter(x => typeof(x) === 'string')
      .map(x => {
        try {
          let parsed = parse.default(x.replace(/&nbsp;/g, ' '));
          
          // Check if parsed exists and is the correct structure
          if (parsed && typeof(parsed) === 'object') {
            if (parsed.length > 0) {
              return parsed.filter(x => typeof(x) == 'string').join(' ');
            }
            //return parsed.props.name;
            //for now we want to ignore emote names
            return '';
          }

          return x;
        } catch (err) {
          console.error('Error parsing message:', err);
          return x;
        }
      })
      .join(' ');

    let generateVoice = null;
    let voiceName = null;
    let voiceTypeId = null;
    let externalId = null;

    if (!data.isSpam && ttsMessage.trim().length > 0) {
      // Mode 0: TTS disabled
      if (chatSettingRef.current.ttsChatsMode === 0) {
        return;
      }

      let match = ttsMessage.match(/!\w+/g);
      
      // Check for voice commands in modes 1 or 2
      if (match) {
        let voice = match[0];

        // Add support for !male and !female commands
        if (voice === '!male') {
          voice = '!joey';
        } else if (voice === '!female') {
          voice = '!joanna';
        }

        // Find voice in voiceRules
        let rule = chatSettingRef.current.voiceRules.find(x => x.command === voice);

        // Check permissions based on voice type and user level
        const hasPermission = (
          data.chatterLevel === 4 || // Broadcaster always has permission
          (rule && (
            // For basic voices (voiceTypeId = 1), check permissions based on user level
            (rule.voiceTypeId === 1 && (
              // If all chatters allowed (bit 0), then everyone including mods/subs can use basic voices
              (chatSettingRef.current.ttsChatsPerms & 1) ||
              // Otherwise check specific permissions
              (data.chatterLevel >= 2 && (chatSettingRef.current.ttsChatsPerms & 4)) || // Mod permission (bit 2)
              (data.chatterLevel === 1 && (chatSettingRef.current.ttsChatsPerms & 2))   // Sub permission (bit 1)
            )) ||
            // For TTS Monster voices (voiceTypeId = 12), check mod/sub permissions
            (rule.voiceTypeId === 12 && chatSettingRef.current.isProStreamer && (
              (data.chatterLevel >= 2 && (chatSettingRef.current.ttsChatsPerms & 4)) || // Mod permission (bit 2)
              (data.chatterLevel === 1 && (chatSettingRef.current.ttsChatsPerms & 2))   // Sub permission (bit 1)
              // Note: All chatters (bit 0) not allowed for TTS Monster voices
            ))
          ))
        );

        if (hasPermission && rule) {
          ttsMessage = ttsMessage.slice(voice.length).trim();
          if (chatSettingRef.current.chatReadSender == true) {
            ttsMessage = `${data.username} says: ${ttsMessage}`;
          }
          voiceName = rule.name;
          externalId = rule.externalId;
          voiceTypeId = rule.voiceTypeId;
        } else if (chatSettingRef.current.ttsChatsMode === 1) {
          // In command-only mode (1), if permission denied, don't read anything
          return;
        }
        // In all TTS mode (2), if permission denied, it will fall through to use default voice
      }
      
      // If no valid command voice was set and we're in mode 2, or if we're in mode 1 with an invalid command
      if (!voiceName) {
        // In mode 1, only allow if there was a command attempt
        if (chatSettingRef.current.ttsChatsMode === 1 && !match) {
          return;
        }

        if (chatSettingRef.current.chatReadSender == true) {
          ttsMessage = `${data.username} says: ${ttsMessage}`;
        }
        voiceName = chatSettingRef.current.ttsVoice;
        voiceTypeId = chatSettingRef.current.ttsVoiceTypeId;
        externalId = chatSettingRef.current.ttsVoiceExternalId;
      }

      // TTS Monster
      if (voiceTypeId == 12 && chatSettingRef.current.isProStreamer) {
        // 30 seconds between generations

        let key = `${data.username}:${data.source}`;
        let recentGens = ttsMonsterGens.current[key];
        console.info(recentGens);
        if (recentGens && Object.keys(recentGens).length > 0) {
          return;
        }

        generateVoice = new Promise((res, rej) => {
          axios.post('/pubapi/ttsmonster/generate', {
            voice: externalId,
            voiceName: voiceName,
            text: ttsMessage,
            platform: data.source,
            username,
            chatUsername: data.username,
            messageId: data.messageId,
          }).then(result => res(result))
          .catch(err => rej(err));
        });

        if (!recentGens) {
          ttsMonsterGens.current[key] = {};
        }
        ttsMonsterGens.current[key][data.messageId] = 1;
        setTimeout(() => delete ttsMonsterGens.current[key][data.messageId], TTSMONSTER_MESSAGE_COOLDOWN);
      }
      addTTSToQueue(ttsMessage, data.messageId, voiceName, voiceTypeId, generateVoice);
    }
  };

  const emoteMessageParser = (emoteMessage, index, chat) => {
    try {
      let ret = null;

      // Handle cases where emoteMessage is not a string
      if (Array.isArray(emoteMessage)) {
          // If it's an array, join the elements to form a string
          emoteMessage = emoteMessage.join(" ");
      }
      
      // Now, check if emoteMessage is a string before trying to replace
      if (typeof emoteMessage === 'string') {
          emoteMessage = emoteMessage.replace(/&nbsp;/g, " ");
      } else {
          // If emoteMessage is still not a string, return null or handle accordingly
          console.info("emoteMessage is not a string:", emoteMessage)
          return null;
      }
        
      if (emoteMessage.includes('<div class="chat_emote"')) {
          if(chatSetting.noEmotes) return null;
          
          // Extract background-image URL, alt, and title attributes
          const urlMatch = emoteMessage.match(/background-image: url\('([^']+)'\)/);
          const altMatch = emoteMessage.match(/name="([^"]*)"/);
          const titleMatch = emoteMessage.match(/name="([^"]*)"/);
          
          const imageUrl = urlMatch ? urlMatch[1] : "";
          let altText = altMatch ? altMatch[1] : "";
          let titleText = titleMatch ? titleMatch[1] : "";

          // Construct img tag with the extracted attributes
          const imgTag = `<img src="${imageUrl}" name="${altText}" alt="${altText}" title="${titleText}" style="max-height: ${chatSettingRef.current.emoteSize}px; width: auto;" />`;
          
          // Replace the entire div with a new div without background-image style, and inject img tag into the div.
          emoteMessage = `<div class="chat_emote" style="">${imgTag}</div>`;

          const rawHtml = { __html: emoteMessage };
          ret = <div className="chat-word" key={index} dangerouslySetInnerHTML={rawHtml} />;
      } else {
          if (!chatSetting.emotesOnly) {
              ret = (
                <div className="chat-word" style={{ color: chat.color }} key={index}>
                  {emoteMessage}
                </div>
              );
          }
      }
      return ret;
    } catch (err) {
        console.error(err);
        return null;  // Ensure we return null in case of an error
    }
  };

  const receiveMessage = async (data) => {
    await displayReceivedMessage(data);
  };

  const startWebsockets = () => {
    // we always use a secure connection to test.
    const protocol = 'wss';
    let socket = new Sockette(
      `${protocol}://${baseUrl}/${username.toLowerCase()}_chat`,
      {
        onopen: function (event) {
          event.target.send(
            `Remote client log - ${username} - Open TTS - ${username}`
          );
        },

        onmessage: function (event) {
          event.target.send(`Remote client log - ${username} - [message] Data received from server: ${event.data}`);
          if (event.data == 'pong') {
            return;
          }
          const data = JSON.parse(event.data);
          receiveMessage(data);
          //scrollToBottom(false, "onmessage");
        },

        onclose: function (event) {
          if (event.wasClean) {
            console.info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
          } else {
            console.info('[close] Connection died');
          }
        },

        onerror: function (error) {
          console.error(`[error] ${error.message}`);
        },
      }
    );

    setInterval(() => {
      socket.send(`ping from: ${username} - TTS Overlay`);
    }, 30 * 1000);
  };

  const scrollToBottom = () => {
    if (containerRef.current && !chatSettingRef.current.autoScrollDisabled) {
      const container = containerRef.current;
      container.children[0].scrollTop = container.children[0].scrollHeight;
    }
  };

  function toTitleCase(str) {
    if (typeof str !== 'string') return '';
    let titleCase = str.replace(/([A-Z])/g, ' $1') // Insert space before capital letters
                    .replace(/^./, function(ch) { return ch.toUpperCase(); }) // Capitalize the first letter
                    .replace(/\s+/g, '') // Replace sequences of spaces with a single space
                    .trim(); // Trim any leading spaces
    return titleCase;
  }

  return (
    <div ref={containerRef} className={`livechat ${chatSetting.autoScrollDisabled ? 'auto-scroll-enabled' : ''}`} style={{ backgroundColor: chatSetting.backgroundColor }}>
    {chatSettingRef.current && chatSettingRef.current.hideChats != true && (
      <TransitionGroup className={`chat-container orientation${toTitleCase(chatSettingRef.current.orientation)}`}>
      {(chatSettingRef.current && chatSettingRef.current.orientation && chatSettingRef.current.orientation.toLowerCase().includes("bottom") ?
        [...chats].reverse() : chats).map((chat, idx) => {
          if(chat.banned || (chatSetting.emotesOnly && !chat.emote && !chat.sticker)) {
            const newChats = [...chats];
            newChats.splice(idx, 1);
            setChats(newChats);
            return null;
          }

          const chatKey = `chat-${chat.timestamp}`;          

          const animationClasses = chat.expiring
            ? { enter: '', exit: `expiring-${chat.expiringAnimation}` }
            : { enter: `incoming-${chat.incomingAnimation}`, exit: '' };
          
          const getExpirationAnimation = (chat) => {
            if (!chat.expiring) return '';
            const animationClass = `expiring-${chat.expirationAnimation}`;
            return animationClass;
          };

          const getIncomingAnimation = (chat) => {
            if (chat.timestamp === latestMessageTimestampRef.current && chat.incomingAnimation) {
              return `incoming-${chat.incomingAnimation + chat.incomingAnimation.slice(1)}`;
            }
            return '';
          };

          const parsedMessages = chat.emote 
            ? chat.text.map((element, index) => emoteMessageParser(element, index, chat)).filter(Boolean)
            : chat.text.map((char, index) => <div key={'chatword' + index} className="chat-word" style={{ color: chat.color }}>{char}</div>);
          
          if(parsedMessages.length === 0) {
            return null;
          }

          const chatNickname = () => {
            switch(chatSettingRef.current.theme) {
              // Themes with no : after name
              case 'bubble':
                // if chat.nick ends in : remove it
                return chat.nick.endsWith(':') ? chat.nick.slice(0, -1) : chat.nick;

              case 'console': {
                // add ~$ to the end of chat.nick
                let tempNick = chat.nick.endsWith(':') ? chat.nick.slice(0, -1) : chat.nick;
                tempNick = tempNick + '@' + chat.source + ':~$';
                return tempNick;
              }

              default:
                return chat.nick;
            }
          }

          return (
            <CSSTransition
              key={chatKey}
              timeout={{ enter: 1000, exit: 0 }}
              classNames={animationClasses}
              onEntered={() => {
                scrollToBottom();
              }}
            >
              <div key={chatKey} className={`${getIncomingAnimation(chat, chats)} ${getExpirationAnimation(chat)} ${chatSettingRef.current.font ? chatSettingRef.current.font : 'default'} ${chatSettingRef.current.theme ? `theme${toTitleCase(chatSettingRef.current.theme)}` : 'themeClassic'} ${chatSettingRef.current.orientation ? `orientation${toTitleCase(chatSettingRef.current.orientation)}` : 'orientationTopLeft'} chatMessage`} style={{fontSize:`${chatSettingRef.current.fontSize}px`, backgroundColor: (chatSettingRef.current.theme == 'console' ? chatSettingRef.current.backgroundMessageColor : 'transparent') }}>
                <div className={`title ${chatSettingRef.current.orientation.toLowerCase().includes("right") ? 'rightAlign' : ''}`}  style={{ backgroundColor: chatSettingRef.current.backgroundTitleColor }}>
                {(chat.isStreamerPro || chat.isViewerPro) && (
                  <div className="avatar">
                    <Avatar size={`${chatSettingRef.current.fontSize}px`} round={`${chatSettingRef.current.fontSize}px`} color={Avatar.getRandomColor(chat.nick)} src={(!chat.isStreamerPro && chat.ttsBadgeType.includes('yellow')) ?
                      '/static/img/pc-pro-badge-check-blue.webp' : `/static/img/pc-pro-badge-${chat.ttsBadgeType}.webp`} style={{paddingRight:'0.25rem'}} />
                  </div>
                )}
                  {!chatSettingRef.current.noAvatars && (
                      <div className="avatar">
                          <Avatar size={`${chatSettingRef.current.fontSize}px`} round={`${chatSettingRef.current.fontSize}px`} color={Avatar.getRandomColor(chat.nick)} src={chat.avatar} style={{paddingRight:'0.25rem'}} />
                      </div>
                  )}
                  {!chatSettingRef.current.noBadges && (
                      <div className="badges">
                          {chat.badges && chat.badges.map((badge, index) => <img key={index} height={`${chatSettingRef.current.fontSize}px`} width={`${chatSettingRef.current.fontSize}px`} className="badge" src={badge} />)}
                      </div>
                  )}
                  {!chatSettingRef.current.noUsernames && (
                      <div className="name">
                          {chat.mod && <FontAwesomeIcon className="chat-icon" icon={faWrench} />}
                          <span className={
                            'chat-nick' + 
                            (chat.source == 'kick' ? ' kickNick' : '') + 
                            (chat.source == 'twitch' ? ' twitchNick' : '') + 
                            (chat.source == 'rs' ? ' rsNick' : '') + 
                            (chat.source == 'cozy' ? 
                                (chat.badges && chat.badges.some(badge => badge.includes('vf.svg')) ? ' cozyVerified' : 
                                chat.badges && chat.badges.some(badge => badge.includes('mod.svg')) ? ' cozyMod' : ' cozyNick') 
                            : '') + 
                            (chat.source == 'trovo' ? ' trovoNick' : '') + 
                            (chat.source == 'rumble' ? ' rumbleNick' : '') + 
                            (chat.source == 'dlive' ? ' dliveNick' : '') +
                            (chat.source == 'x' ? ' xNick' : '') +
                            (chat.source == 'parti' ? ' partiNick' : '') +
                            (chat.source == 'noice' ? ' noiceNick' : '')
                        } style={{ color: (chat.chatterColor ? ` ${chat.chatterColor}` : '')}}>
                            {(chat.banned ? '(Banned) ' : '') + chatNickname()}
                        </span>
                      </div>
                  )}
                  </div>
                  <div className={`message ${chatSettingRef.current.orientation.toLowerCase().includes("right") ? 'rightAlign' : ''}`} style={{ backgroundColor: (chatSettingRef.current.theme == 'console' ? 'transparent' : chatSettingRef.current.backgroundMessageColor) }}>
                    {parsedMessages}
                  </div>
              </div>
            </CSSTransition>
          );
        }).filter(Boolean)}
      </TransitionGroup>
    )}
      <audio crossOrigin="anonymous" hidden ref={ttsAudioRef} controls />
    </div>
  );
};

export default LiveChat;
