import React, { useState, useEffect, useRef } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import axios from 'axios';

const SquarePay = ({
  getPowerchat,
  reloadDisclaimer,
  squareAccount,
  disabled,
  completed,
  setLoading,
  squareLoaded,
  paymentMenuIsVisible,
}) => {
  const [loadingGpay, setLoadingGpay] = useState(false);
  const googlePayRef = useRef(null);
  const powerchatRef = useRef(null);

  useEffect(() => {
    const loadSquarePayments = async () => {
      if (!window.Square) {
        console.error('Square.js failed to load properly');
        return;
      }
      if (!squareAccount) {
        return;
      }

      if (googlePayRef.current) {
        googlePayRef.current.destroy();
      }
      setLoadingGpay(true);

      try {
        let pay = window.Square.payments(
          process.env.SQUARE_APP_ID,
          squareAccount
        );
        await initializeGooglePay(pay);
      } catch (err) {
        console.error(err);
      } finally {
        setLoadingGpay(false);
      }
    };
    if (paymentMenuIsVisible && squareLoaded) loadSquarePayments();
  }, [paymentMenuIsVisible, squareAccount, squareLoaded]);

  const buildPaymentRequest = (payments, powerchat) => {
    let amount = powerchat ? powerchat.amount : 1;
    return payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: `${amount}`,
        label: 'Total',
      },
    });
  };

  const initializeGooglePay = async (payments) => {
    let powerchat = getPowerchat();
    powerchatRef.current = powerchat;
    const paymentRequest = buildPaymentRequest(payments, powerchat);
    const gpay = await payments.googlePay(paymentRequest);

    googlePayRef.current = gpay;
    await gpay.attach('#google-pay-button', {
      buttonColor: 'black',
      buttonSizeMode: 'fill',
      buttonType: 'long',
    });
  };

  const createPayment = async (token, powerchat) => {
    await axios.post('/pubapi/square', {
      token,
      currency: 'USD',
      squareUserId: squareAccount,
      ...powerchat,
    });
    completed('Square payment completed');
  };

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  };

  const handlePaymentMethodSubmission = async () => {
    if (disabled) {
      throw 'Payment not allowed in current state';
    }
    setLoading(true);
    try {
      const token = await tokenize(googlePayRef.current);
      await createPayment(token, powerchatRef.current);
    } catch (e) {
      console.error(e.message);
    } finally {
      reloadDisclaimer();
      setLoading(false);
    }
  };

  const disabledStyle = {
    pointerEvents: 'none',
  };

  return (
    <div className="google-pay-container">
      <span className="image-container">
        <img className="icon" src="/static/img/square.svg" />
      </span>
      <div
        id="google-pay-button"
        onClick={handlePaymentMethodSubmission}
        style={disabled ? disabledStyle : {}}
      ></div>
      {loadingGpay && (
        <BarLoader
          color="#000000"
          height={12}
          className="bar-loader"
        />
      )}
    </div>
  );
};

export default SquarePay;
