import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/admin-dashboard.css';
import Card from '../components/card';
import FormAlert from '../components/form-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

const AdminDashboard = () => {
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [twitterDialogOpen, setTwitterDialogOpen] = useState(false);
  const [twitterTokens, setTwitterTokens] = useState({
    csrfToken: '',
    authToken: '',
    guest_id: '',
  });
  const [twitterLogins, setTwitterLogins] = useState([]);
  const [selectedTwitterLogin, setSelectedTwitterLogin] = useState('');

  const handleChange = (event) => {
    setTwitterTokens({
      ...twitterTokens,
      [event.target.name]: event.target.value,
    });
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const restartChatService = async (name) => {
    if (!confirm(`Are you sure you want to restart ${name}?`)) {
      return;
    }
    try {
      await axios.post(`/admapi/${name.toLowerCase()}-restart`);
      triggerAlert(`Restarted ${name}`);
    } catch (err) {
      console.error(err);
      triggerAlert(`Could not restart ${name}`, false);
    }
  };

  const getTwitterLogins = async () => {
    try {
      const response = await axios.post('/admapi/x/getLogins');
      setTwitterLogins(response.data);
    } catch (err) {
      console.error(err);
      triggerAlert('Failed getting X logins');
    }
  };

  const saveTwitterTokens = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/admapi/x/tokens', {
        csrfToken: twitterTokens.csrfToken,
        authToken: twitterTokens.authToken,
        guest_id: twitterTokens.guest_id,
        username: selectedTwitterLogin,
      });
      triggerAlert('Saved X tokens');
    } catch (err) {
      console.error(err);
      triggerAlert('Failed saving X tokens');
    } finally {
      setTwitterDialogOpen(false);
    }
  };

  // Fetch Twitter logins when the dialog opens
  useEffect(() => {
    if (twitterDialogOpen) {
      getTwitterLogins();
    }
  }, [twitterDialogOpen]);

  return (
    <div className="admin-dashboard page-container">
      <Card title="Admin Dashboard" lineClass="admin-dashboard-line">
        <div className="restart-chat form-container">
          <button className="secondary-btn" onClick={() => restartChatService('YouTube')}>
            YouTube
            <img src="/static/img/youtube-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Kick')}>
            Kick.com
            <img src="/static/img/kick-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Twitch')}>
            Twitch
            <img src="/static/img/twitch-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Rumble')}>
            Rumble
            <img src="/static/img/rumble-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('DLive')}>
            DLive
            <img src="/static/img/dlive-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Cozy')}>
            Cozy.tv
            <img src="/static/img/cozytv-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Robotstreamer')}>
            Robotstreamer
            <img src="/static/img/robotlogo.svg" style={{ backgroundColor: '#1DD1A1', padding: 2, borderRadius: 4 }}/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Trovo')}>
            Trovo
            <img src="/static/img/trovo-icon.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Parti')}>
            Parti
            <img src="/static/img/parti-logo.png"/>
          </button>
          <button className="secondary-btn" onClick={() => setTwitterDialogOpen(true)}>
            X (Twitter)
            <img src="/static/img/x/x-logo.webp"/>
          </button>
        </div>
        <FormAlert alertState={alertState} width={400} />
      </Card>
      <Dialog open={twitterDialogOpen}
        onClose={() => setTwitterDialogOpen(false)}
        classes={{ paper: 'twitter-dialog' }}
      >
        <DialogTitle>
          <div className="dialog-title flex-row">
            <div/>
            <IconButton
              aria-label="close"
              onClick={() => setTwitterDialogOpen(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={saveTwitterTokens} className="form-container">
            <label>Twitter Logins</label>
            <select
              value={selectedTwitterLogin}
              onChange={(e) => setSelectedTwitterLogin(e.target.value)}
            >
              {twitterLogins.map((login, index) => (
                <option key={index} value={login.username}>
                  {login.username}{login.locked ? ' (Locked)' : ''}
                </option>
              ))}
            </select>
            <label>CSRF Token (&quot;ct0&quot;)</label>
            <input type="text" name="csrfToken" placeholder="csrf" autoComplete="off"
              onChange={handleChange} value={twitterTokens.csrfToken} />
            <label>Auth Token</label>
            <input type="text" name="authToken" placeholder="auth" autoComplete="off"
              onChange={handleChange} value={twitterTokens.authToken} />
            <label>Guest Id (&quot;guest_id&quot;)</label>
            <input type="text" name="guest_id" placeholder="guest_id" autoComplete="off"
              onChange={handleChange} value={twitterTokens.guest_id} />
            <button className="primary-btn" type="submit">Save</button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AdminDashboard;
