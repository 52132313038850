import React, { useState, useEffect, useCallback, useMemo, Fragment } from 'react';
import axios from 'axios';
import '../css/events.css';
import ToggleSwitch from './toggle-switch';
import Pagination from './pagination';
import { DatePicker } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faRepeat, faSearch } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import TextField from '@mui/material/TextField';
import Select from 'react-select';

let pageSize = 5;

const Events = ({ username, params, userId, isMod=false }) => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [events, setEvents] = useState([]);
  const [,setNewEvents] = useState([]); // newEvents state not being used
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [manualRefreshing, setManualRefreshing] = useState(false);
  const [replayingIndex, setReplayingIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [paymentPlatform, setPaymentPlatform] = useState(null);
  const [paymentPlatformOptions, setPaymentPlatformOptions] = useState([]);

  const resend = async (id, index) => {
    setReplayingIndex(index);
    await axios.post(`/api/resend/${id}`);
    setTimeout(() => setReplayingIndex(null), 1000);
  };

  const platformNameMap = {
    'nmi': 'Powerchat Pay',
    'btc': 'Bitcoin',
    'ltc': 'Litecoin',
    'eth': 'Ethereum',
    'xmr': 'Monero',
    'doge': 'Dogecoin',
    'usdt': 'USD Tether',
  };
  const cryptoCoins = ['btc', 'ltc', 'eth', 'xmr', 'doge', 'usdt'];

  const getEvents = async (params) => {
    const { data } = await axios.get('/api/history', { params: { userId } });
    setTotal(data.total);
    setEvents(data.donations);

    let platforms = data.donations.map(x => x.payment_platform).filter((v, i, a) => a.indexOf(v) == i);

    let options = [{ value: null, label: 'Select..' }];
    if (platforms.includes('stripe')) options.push({ value: 'stripe', label: 'Stripe' });
    if (platforms.includes('square')) options.push({ value: 'square', label: 'Square' });
    if (platforms.includes('nmi')) options.push({ value: 'nmi', label: 'Powerchat Pay' });

    if (cryptoCoins.find(c => platforms.includes(c)) != undefined) {
      options.push({ value: 'powerchat crypto', label: 'Powerchat Crypto' });
    }
    if (params.paymentPlatform) {
      setPaymentPlatform(options.find(x => x.value == params.paymentPlatform));
    }

    setPaymentPlatformOptions(options);
  };

  const dequeueNewEvents = () => {
    setNewEvents((newEvents) => {
      setTimeout(() => newEvents.forEach((x) => (x.isNewEvent = false)), 5000);
      setEvents((events) => [...newEvents, ...events]);
      return [];
    });
  };

  const startRefreshInterval = () => {
    setRefreshInterval(setInterval(dequeueNewEvents, 5000));
  };

  const stopRefreshInterval = () => {
    clearInterval(refreshInterval);
    setRefreshInterval(null);
  };

  const manualRefresh = () => {
    if (autoRefresh) return;
    setManualRefreshing(true);
    setTimeout(() => setManualRefreshing(false), 500);
    dequeueNewEvents();
  };

  useEffect(() => {
    const init = async () => {
      await getEvents(params);
      if (params) {
        setSearchText(params.searchText);
        setMinDate(params.minDate);
        setMaxDate(params.maxDate);
        filterSubmit(new Event(''), params);
      }
    };
    init();
  }, []);

  useEffect(() => {
    if (autoRefresh) {
      startRefreshInterval();
    } else {
      stopRefreshInterval();
    }
  }, [autoRefresh]);

  useEffect(() => {
    startWebsocket();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const currentPageEvents = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setFilteredTotal(events.filter(x => x.hidden !== true).length);
    return events.filter(x => x.hidden !== true).slice(firstPageIndex, lastPageIndex);
  }, [currentPage, events]);

  const startWebsocket = () => {
    const protocol = 'wss';
    let socket = new ReconnectingWebSocket(
      `${protocol}://${baseUrl}/${username.toLowerCase()}_feed`
    );

    setInterval(() => {
      socket.send(`keep alive from: ${username} - Events Component`);
    }, 15 * 1000);

    socket.onopen = function () {
      console.log('[open] Connection established');
    };

    socket.onmessage = function (event) {
      if (event.data == 'pong') {
        return;
      }
      const dataObj = JSON.parse(event.data);
      addEvent(dataObj);
      socket.send(`rec_feed:${dataObj.id}`);
    };

    socket.onclose = function (event) {
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        console.log('[close] Connection died');
      }
    };

    socket.onerror = function (event) {
      console.log(`[error] ${event.message}`);
    };
  };

  const addEvent = (data) => {
    if (data.isReplay) {
      return;
    }
    setNewEvents((newEvents) => {
      let newEvent = {
        amount: data.amount,
        created_at: data.created_at,
        donator: data.donator,
        id: data.id,
        media: data.media,
        media_title: data.mediaTitle,
        message: data.message,
        payment_platform: data.paymentPlatform,
        isNewEvent: true,
      };
      newEvent.hidden = shouldFilter(newEvent);
      return [
        newEvent,
        ...newEvents,
      ];
    });
  };

  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
  };

  const timezoneName = new Intl.DateTimeFormat('en-us', {
    timeZoneName: 'short',
  })
    .formatToParts(new Date())
    .find((part) => part.type == 'timeZoneName').value;

  const searchTextChanged = (event) => {
    setSearchText(event.target.value);
  };

  const filterSubmit = useCallback((event, params) => {
    event.preventDefault();
    if (!params) {
      params = {
        searchText,
        minDate,
        maxDate,
        paymentPlatform: paymentPlatform && paymentPlatform.value,
      };
    }
    setEvents((events) => {
      let newEvents = events.map(x => ({
        ...x,
        hidden: shouldFilter(x, params),
      }));
      return newEvents;
    });
    setCurrentPage(1);
  }, [searchText, minDate, maxDate, paymentPlatform]);

  const shouldFilter = (evt, params) => {
    let lowerSearch = (params.searchText || '').toLowerCase();
    let minDate = params.minDate;
    let maxDate = params.maxDate;
    let paymentPlatform = (params.paymentPlatform);
    return (lowerSearch != '' && (
      !evt.message && !evt.donator || (
        (!evt.message || !evt.message.toLowerCase().includes(lowerSearch)) &&
        (!evt.donator || !evt.donator.toLowerCase().includes(lowerSearch))
      )
    )
      || (!!minDate && minDate > new Date(evt.created_at))
      || (!!maxDate && maxDate < new Date(new Date(evt.created_at).toLocaleDateString()))
      || (!!paymentPlatform && (
        (paymentPlatform != 'powerchat crypto' && paymentPlatform.toLowerCase() != evt.payment_platform.toLowerCase())
        || (paymentPlatform == 'powerchat crypto' && !cryptoCoins.includes(evt.payment_platform))
      ))
    );
  };

  const clearFilter = () => {
    setSearchText('');
    setMinDate(null);
    setMaxDate(null);
    setPaymentPlatform(null);
  };

  const handlePlatformSelect = (option) => {
    if (option.value == null) {
      setPaymentPlatform(null);
    } else {
      setPaymentPlatform(option);
    }
  };

  const selectStyle = {
    control: (provided) => ({
      ...provided,
      color: 'var(--white)',
      backgroundColor: 'transparent',
    }),
    option: (provided, { value }) => ({
      ...provided,
      color: value == null ? '#808080' : 'var(--black)',
    }),
    input: (provided) => ({
      ...provided,
      color: 'var(--white)',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'var(--white)',
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 2,
    }),
  };

  return (
    <div className="events-container">
      <div className="event-controls">
        <form className="event-filter-controls" onSubmit={filterSubmit}>
          <div className="filter-inputs">
            <div>
              <input className="search" type="search" name="searchText" placeholder="Filter by message or username"
                onChange={searchTextChanged} value={searchText} />
            </div>
            <div className="date-pick">
              <DatePicker
                format="MM/DD/YYYY"
                label="Start Date"
                value={minDate}
                maxDate={maxDate}
                sx={{
                  width: 200,
                }}
                slotProps={{
                  field: { clearable: true, onClear: () => setMinDate(null) }
                }}
                onChange={(date) => setMinDate(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className="date-pick">
              <DatePicker
                format="MM/DD/YYYY"
                label="End Date"
                value={maxDate}
                minDate={minDate}
                sx={{
                  width: 200,
                }}
                slotProps={{
                  field: { clearable: true, onClear: () => setMaxDate(null) }
                }}
                onChange={(date) => setMaxDate(date)}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div className="select-input">
              <Select
                value={paymentPlatform}
                onChange={handlePlatformSelect}
                options={paymentPlatformOptions}
                placeholder="Payment Platform"
                isSearchable={false}
                styles={selectStyle}
              />
      
            </div>
          </div>
          <div className="form-container">
          <button className="primary-btn" onClick={filterSubmit}>
            Search&nbsp;
            <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
          </button>
            <button className="secondary-btn" onClick={clearFilter}>
              Clear
            </button>
          </div>
        </form>
      {!isMod && (
        <div className="event-refresh-controls">
          <button
            onClick={manualRefresh}
            className={
              (manualRefreshing ? 'refreshing' : '') +
              (autoRefresh ? ' disabled' : '')
            }
          >
            <FontAwesomeIcon icon={faRotateRight} size="2x" color="#57bad8" />
          </button>
          <label htmlFor="autoRefresh" title="Refresh every 5 seconds">
            Auto&nbsp;Refresh
          </label>
          <div title="Refresh every 5 seconds" style={{ width: '45px' }}>
            <ToggleSwitch
              name="autoRefresh"
              onChange={toggleAutoRefresh}
              checked={autoRefresh}
            />
          </div>
        </div>
      )}
      </div>
      {currentPageEvents.length ? (
        <Fragment>
          <TransitionGroup>
            {currentPageEvents.map(
              (
                {
                  id,
                  donator,
                  message,
                  sub_message,
                  amount,
                  currency,
                  payment_platform,
                  media,
                  media_title,
                  created_at,
                  isNewEvent,
                },
                idx
              ) => (
                <CSSTransition
                  key={String(id)}
                  timeout={5000}
                  enter={true}
                  exit={false}
                >
                  <div className={'event' + (isNewEvent ? ' new-event' : '')}>
                    <div className="event-title">
                      <div className="event-replay">
                      {!isMod && (
                        <button
                          title="Replay"
                          className={idx === replayingIndex ? 'animate' : ''}
                          onClick={() => resend(id, idx)}
                        >
                          <FontAwesomeIcon
                            icon={faRepeat}
                            size="2x"
                            color="#57bad8"
                          />
                        </button>
                      )}
                        <h3 className="capitalize" style={{ marginLeft: '15px' }}>
                          {platformNameMap[payment_platform] || payment_platform}
                        </h3>
                      </div>
                      <h3>
                        {format(
                          new Date(created_at),
                          `Y-MM-dd HH:mm '${timezoneName}'`
                        )}
                      </h3>
                    </div>
                    <div className="event-details">
                      <div>
                        <h3>{donator}</h3>
                        {payment_platform == 'trovo' || payment_platform.startsWith('Twitch') || payment_platform.startsWith('Kick') || payment_platform.startsWith('DLive') ? (
                          <h4>
                            {amount} {currency}
                          </h4>
                        ) : (payment_platform == 'cozy') ? ( <h4>{currency}</h4> ) : (
                          <h4>${amount ? amount.toFixed(2) : 0}</h4>
                        )}
                        {media && (
                          <h4>
                            <a href={media} rel="noreferrer" target="_blank">
                              {media_title}
                            </a>
                          </h4>
                        )}
                      </div>
                      {message && message.length > 0 && (
                        <div className="message">
                          <p>{message}</p>
                        </div>
                      )}
                      {sub_message && sub_message.length > 0 && (
                        <div className="message">
                          <p>{sub_message}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </CSSTransition>
              )
            )}
          </TransitionGroup>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredTotal}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Fragment>
      ) : total > 0 ? (
        <div>No events fit the search criteria</div>
      ) : (
        <div>No event history to display</div>
      )}
    </div>
  );
};

export default Events;
