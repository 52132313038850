import React, { useState, useEffect } from 'react';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import axios from 'axios';
import '../css/account-settings.css';
import ChangePasswordForm from '../components/change-password-form';
import EmailSettingsForm from '../components/email-settings';
import SecurityForm from '../components/security-form';
import Card from '../components/card';
import FormAlert from '../components/form-alert';
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faRotateRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Following from '../components/following';
import ProSubscribe from '../components/pro-subscribe';
import TotpDialog from '../components/dialogs/totp-dialog';
import FadeLoader from 'react-spinners/FadeLoader';
import ColorInput from '../components/color-input';

const AccountSettings = () => {
  const { email, displayName, setDisplayName } = useAuth();
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [displayAvatar, setDisplayAvatar] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [visibleTab, setVisibleTab] = useState('account');
  const [accountSettings, setAccountSettings] = useState({
    email: '',
    avatar: '',
    canChangeUsername: true,
    canChangeEmail: true,
  });
  const [securitySettings, setSecuritySettings] = useState({
    totpSetup: false,
    tipaltiLinked: false,
  });
  const [subscriptions, setSubscriptions] = useState([]);
  const [following, setFollowing] = useState([]);
  const [totpDialogOpen, setTotpDialogOpen] = useState(false);
  const [totpError, setTotpError] = useState(false);
  const [savingAccountSettings, setSavingAccountSettings] = useState(false);
  const [proSettings, setProSettings] = useState({
    ttsProDonatorColor: '#ffd700',
    ttsProChatNameColor: '#ffd700',
    ttsBadgeType: 'check-blue'
  });

  const defaultUserPhotoURL = 'user-default.jpg';

  const tabs = [
    {
      name: 'account',
      display: 'Account',
    },
    {
      name: 'password',
      display: 'Password',
    },
    {
      name: 'emails',
      display: 'Emails',
    },
    {
      name: 'security',
      display: 'Security',
    },
    {
      name: 'powerchat pro',
      display: (<span>Powerchat <b className="text-light-blue">Pro</b><sup>BETA</sup></span>)
    },
    {
      name: 'subscriptions',
      display: 'Subscriptions',
    },
    {
      name: 'following',
      display: 'Following',
    }
  ];

  const handleChange = (event) => {
    setAccountSettings({
      ...accountSettings,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    const getAccountSettings = async () => {
      try {
        const accountSettings = await axios.get('/api/account-settings');
        const {
          username,
          email,
          subscriptions,
          following,
          avatar,
          legacyTotp,
          totpSetup,
          canChangeUsername,
          changeUsernameDate,
          canChangeEmail,
          changeEmailMinutes,
          ttsProDonatorColor,
          ttsProChatNameColor,
          ttsBadgeType,
          proViewer,
          proStreamer
        } = accountSettings.data;
        setAccountSettings({
          email,
          avatar,
          username,
          legacyTotp,
          totpSetup,
          canChangeUsername,
          changeUsernameDate,
          canChangeEmail,
          changeEmailMinutes,
          ttsProDonatorColor,
          ttsProChatNameColor,
          ttsBadgeType,
          proViewer,
          proStreamer
        });
        setFollowing(following);
        setSubscriptions(
          subscriptions.filter((x) => x.paymentPlatform != 'powerchat-test')
        );
        setDisplayAvatar(avatar);
        setProSettings({
          ttsProDonatorColor,
          ttsProChatNameColor,
          ttsBadgeType
        });
      } catch (err) {
        console.error(err);
      }
    };
    getAccountSettings();
  }, []);

  useEffect(() => {
    const getSecuritySettings = async () => {
      let response = await axios.get('/api/security-settings');
      setSecuritySettings({
        ...response.data
      });
    };
    getSecuritySettings();
  }, []);

  useEffect(() => {
    const loadTab = async () => {
      let urlParams = new URLSearchParams(window.location.search);
      let tabName = urlParams.get('tab');
      tabName = tabName ? tabName : 'account';
      selectTab(tabName);
    };
    loadTab();
  }, []);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const selectTab = (tab) => {
    setVisibleTab(tab);
    window.history.replaceState(null, null, '?tab=' + tab);
    if (window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  };

  const cancelSubscription = async (id, index) => {
    const response = await axios.post('/api/stripe/subscription/cancel', {
      subscription_id: id,
    });
    if (response.status == 200) {
      setSubscriptions((subscriptions) => {
        let subs = [...subscriptions];
        let sub = { ...subs[index] };
        sub['recurring'] = false;
        subs[index] = sub;
        return subs;
      });
    } else {
      console.error('Problem cancelling subscription', id);
    }
  };

  const restartSubscription = async (id, index) => {
    const response = await axios.post('/api/stripe/subscription/restart', {
      subscription_id: id,
    });
    if (response.status == 200) {
      setSubscriptions((subscriptions) => {
        let subs = [...subscriptions];
        let sub = { ...subs[index] };
        sub['recurring'] = true;
        subs[index] = sub;
        return subs;
      });
    } else {
      console.error('Problem restarting subscription', id);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const triggerAlert = (message, success = true) => {
    setAlertState({
      ...alertState,
      message,
      success,
    });
    // Prevent alert from reappearing after switching tabs
    setTimeout(() => setAlertState({ ...alertState, message: '' }), 3000);
  };

  const submitAccountSettings = async (event) => {
    event.preventDefault();
    try {
      if (
        accountSettings.username != displayName ||
        accountSettings.email != email
      ) {
        setTotpDialogOpen(true);
      } else {
        saveAccountSettings();
      }
    } catch (err) {
      console.error(err);
      triggerAlert('Could not update account settings', false);
    }
  };

  const saveAccountSettings = async (totp) => {
    try {
      setSavingAccountSettings(true);
      let changingUsername = accountSettings.username != displayName;
      let changingEmail = accountSettings.email != email;
      if (accountSettings.username.trim() == '') {
        triggerAlert('Username cannot be empty');
        return;
      }
      if (accountSettings.email.trim() == '') {
        triggerAlert('Email cannot be empty');
        return;
      }

      let response = await axios.put('/api/account-settings', {
        ...accountSettings,
        ttsProDonatorColor: proSettings.ttsProDonatorColor,
        ttsProChatNameColor: proSettings.ttsProChatNameColor,
        ttsBadgeType: proSettings.ttsBadgeType,
        totp,
      });
      triggerAlert('Account settings successfully updated');

      let { changeUsernameDate, changeEmailMinutes } = response.data;
      setAccountSettings({
        ...accountSettings,
        canChangeUsername:
          accountSettings.canChangeUsername && !changingUsername,
        changeUsernameDate:
          changeUsernameDate || accountSettings.changeUsernameDate,
        canChangeEmail: accountSettings.canChangeEmail && !changingEmail,
        changeEmailMinutes:
          changeEmailMinutes || accountSettings.changeEmailMinutes,
      });
      setDisplayAvatar(accountSettings.avatar);
      setDisplayName(accountSettings.username);
      setTotpDialogOpen(false);
    } catch (error) {
      console.error(error);
      if (error.response.data == 'Failed 2FA') {
        setTotpError(true);
        setTotpDialogOpen(true);
      } else if (typeof error.response.data == 'string') {
        triggerAlert(error.response.data, false);
        setTotpDialogOpen(false);
      } else {
        triggerAlert('Could not update account settings', false);
        setTotpDialogOpen(false);
      }
    } finally {
      setSavingAccountSettings(false);
    }
  };

  const onTotpDialogComplete = async ({totp}) => {
    saveAccountSettings(totp);
  };

  const onTotpDialogClose = () => {
    setTotpDialogOpen(false);
    setTotpError(false);
  };

  const manageStripeSubscriptions = async () => {
    location.href = 'api/stripe/manage-subs';
  };

  const spinnerStyle = {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  const usernameChangeDisabled = () =>
    !accountSettings.totpSetup ||
    accountSettings.legacyTotp ||
    !accountSettings.canChangeUsername;

  const emailChangeDisabled = () =>
    !accountSettings.totpSetup || !accountSettings.canChangeEmail;

  const handleProSettingChange = (name, value) => {
    setProSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="page-container">
      <div className="account-settings-container tabbed-cards-container">
        {windowWidth <= 600 && (
          <Drawer
            variant="temporary"
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            className="drawer-container"
          >
            <IconButton
              onClick={handleDrawerToggle}
              className="drawer-toggle-button-open"
            >
              <ChevronLeftIcon />
            </IconButton>
            <List className="drawer-list">
              {tabs.map((tab, idx) => (
                <ListItem
                  button
                  key={tab.name + String(idx)}
                  onClick={() => {
                    selectTab(tab.name);
                    handleDrawerToggle();
                  }}
                  className={`tab ${tab.name === visibleTab ? 'active' : ''}`}
                >
                  <ListItemText primary={tab.display} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        )}
        {windowWidth <= 600 && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className="drawer-toggle-button-closed"
            sx={{ ...(drawerOpen ? { display: 'none' } : {}) }}
          >
            <ChevronRightIcon />
          </IconButton>
        )}
        <div className="desktop-tab-menu tab-menu">
          {tabs.map((tab, idx) => (
            <div
              key={tab.name + String(idx)}
              onClick={() => selectTab(tab.name)}
              className={`tab ${tab.name === visibleTab ? 'active' : ''} ${
                tab.name === 'account'
                  ? 'overlays-border'
                  : tab.name === 'following'
                  ? 'privacy-border'
                  : ''
              }`}
            >
              <div>{tab.display}</div>
            </div>
          ))}
        </div>
        {visibleTab === 'account' && (
          <Card
            title="Account"
            lineClass="account-line"
            className="form-container"
          >
            <div className="account-section-container form-container">
              <form onSubmit={submitAccountSettings}>
                <div className="account-section">
                  <span>Email:</span>
                  <input
                    name="email"
                    placeholder="Email"
                    disabled={emailChangeDisabled()}
                    type="email"
                    value={accountSettings.email}
                    onChange={handleChange}
                  />
                  {!accountSettings.totpSetup ? (
                    <div className="note">
                      <a href="account-settings?tab=security">
                        2-Factor Authentication{' '}
                      </a>
                      must be setup to change email.
                    </div>
                  ) : !accountSettings.canChangeEmail ? (
                    <div className="note">
                      You can change your email again in{' '}
                      {accountSettings.changeEmailMinutes} minute
                      {accountSettings.changeEmailMinutes > 1 ? 's' : ''}
                    </div>
                  ) : (
                    <div />
                  )}
                </div>
                <div className="account-section">
                  <span>Username:</span>
                  <input
                    name="username"
                    placeholder="Username"
                    disabled={usernameChangeDisabled()}
                    type="text"
                    value={accountSettings.username}
                    onChange={handleChange}
                    maxLength={16}
                  />
                  {!accountSettings.totpSetup ? (
                    <div className="note">
                      <a href="account-settings?tab=security">
                        2-Factor Authentication{' '}
                      </a>
                      must be setup to change username.
                    </div>
                  ) : accountSettings.legacyTotp ? (
                    <div className="note">
                      You will need to unlink and relink
                      <a href="account-settings?tab=security">
                        {' '}
                        2-Factor Authentication{' '}
                      </a>
                      in order to change your username. This is a one-time
                      action and will not be necessary for username changes in
                      the future.
                    </div>
                  ) : !accountSettings.canChangeUsername ? (
                    <div className="note">
                      You can change your username again on{' '}
                      {accountSettings.changeUsernameDate}
                    </div>
                  ) : (
                    <div className="note">
                      You can only change your username once every 30 days
                    </div>
                  )}
                </div>
                <TotpDialog
                  message={
                    'Authentication required for changing username or email'
                  }
                  open={totpDialogOpen}
                  onComplete={onTotpDialogComplete}
                  onClose={onTotpDialogClose}
                  showTotpError={totpError}
                />
                <div className="account-section input-row">
                  <div>
                    <label htmlFor="avatar">Avatar:</label>
                    <div className="crop-wrapper">
                      <img
                        title="User avatar"
                        src={
                          displayAvatar || `/static/img/${defaultUserPhotoURL}`
                        }
                      />
                    </div>
                    <input
                      name="avatar"
                      placeholder="Ex: https://i.imgur.com/XXXXXXX.png"
                      type="text"
                      value={accountSettings.avatar || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="account-section">
                  <label>
                    Powerchat Pro Badge:
                    &ensp;
                    <a href="https://learn.powerchat.live/account-management/powerchat-pro" target="_blank" rel="noreferrer">
                      Learn More
                    </a>
                  </label>
                  <div className="pro-badge-container">
                    <div className="pro-badge-row">
                      {/* Blue Badge Section */}
                      <div className="pro-badge">
                        <div className="pro-badge-icons">
                          <img 
                            src="/static/img/pc-pro-badge-check-blue.webp"
                            alt="Blue Check Badge"
                            className={`
                              ${accountSettings.proViewer ? '' : 'disabled'}
                              ${proSettings.ttsBadgeType === 'check-blue' && accountSettings.proViewer ? 'active' : ''}
                            `}
                            onClick={() => {
                              if (accountSettings.proViewer) {
                                handleProSettingChange('ttsBadgeType', 
                                  proSettings.ttsBadgeType === 'check-blue' ? null : 'check-blue'
                                );
                              }
                            }}
                          />
                          <img 
                            src="/static/img/pc-pro-badge-lightning-blue.webp"
                            alt="Blue Lightning Badge"
                            className={`
                              ${accountSettings.proViewer ? '' : 'disabled'}
                              ${proSettings.ttsBadgeType === 'lightning-blue' && accountSettings.proViewer ? 'active' : ''}
                            `}
                            onClick={() => {
                              if (accountSettings.proViewer) {
                                handleProSettingChange('ttsBadgeType',
                                  proSettings.ttsBadgeType === 'lightning-blue' ? null : 'lightning-blue'
                                );
                              }
                            }}
                          />
                        </div>
                      </div>

                      {/* Yellow Badge Section */}
                      <div className="pro-badge">
                        <div className="pro-badge-icons">
                          <img 
                            src="/static/img/pc-pro-badge-check-yellow.webp"
                            alt="Yellow Check Badge"
                            className={`
                              ${accountSettings.proStreamer ? '' : 'disabled'}
                              ${proSettings.ttsBadgeType === 'check-yellow' && accountSettings.proStreamer ? 'active' : ''}
                            `}
                            onClick={() => {
                              if (accountSettings.proStreamer) {
                                handleProSettingChange('ttsBadgeType',
                                  proSettings.ttsBadgeType === 'check-yellow' ? null : 'check-yellow'
                                );
                              }
                            }}
                          />
                          <img 
                            src="/static/img/pc-pro-badge-lightning-yellow.webp"
                            alt="Yellow Lightning Badge"
                            className={`
                              ${accountSettings.proStreamer ? '' : 'disabled'}
                              ${proSettings.ttsBadgeType === 'lightning-yellow' && accountSettings.proStreamer ? 'active' : ''}
                            `}
                            onClick={() => {
                              if (accountSettings.proStreamer) {
                                handleProSettingChange('ttsBadgeType',
                                  proSettings.ttsBadgeType === 'lightning-yellow' ? null : 'lightning-yellow'
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {!accountSettings.proStreamer && (
                      <div>
                        <a
                          className="upgrade"
                          href="/account-settings?tab=powerchat%20pro"
                        >
                          {accountSettings.proViewer ? 'Switch plans ' : 'Upgrade '}
                        </a>
                        {accountSettings.proViewer ? 'to Powerchat Pro Streamer' : 'to Powerchat Pro'}
                      </div>
                    )}
                    {accountSettings.proViewer && (
                      <div style={{ marginTop: 5 }}>
                        <FontAwesomeIcon icon={faCircleExclamation} color="var(--gold)" />&ensp;
                        <b>
                          Link your <a href="/creator-dashboard?tab=external-integrations">
                            External Integrations
                          </a> in order to see your badge in chat overlays
                        </b>
                      </div>
                    )}
                  </div>
                </div>
                <div className="account-section">
                  <label>
                    Powerchat Pro Chat Color:
                    &ensp;
                    <a href="https://learn.powerchat.live/account-management/powerchat-pro" target="_blank" rel="noreferrer">
                      Learn More
                    </a>
                  </label>
                  <div className={`color-picker-wrapper ${!accountSettings.proViewer && !accountSettings.proStreamer ? 'disabled' : ''}`}>
                    <ColorInput
                      name="ttsProChatNameColor"
                      value={proSettings.ttsProChatNameColor}
                      onValueChange={(value) => handleProSettingChange('ttsProChatNameColor', value)}
                      disabled={!accountSettings.proViewer && !accountSettings.proStreamer}
                    />
                    {!accountSettings.proViewer && !accountSettings.proStreamer && (
                      <div className="note">
                        Upgrade to Powerchat Pro to customize chat colors
                      </div>
                    )}
                  </div>
                  {accountSettings.proViewer && (
                    <div style={{ marginTop: 5 }}>
                      <FontAwesomeIcon icon={faCircleExclamation} color="var(--gold)" />&ensp;
                      <b>
                        Link your <a href="/creator-dashboard?tab=external-integrations">
                          External Integrations
                        </a> in order to see your chat color in chat overlays
                      </b>
                    </div>
                  )}
                </div>
                <div className="account-section">
                  <label>
                    Powerchat Pro Powerchat Color:
                    &ensp;
                    <a href="https://learn.powerchat.live/account-management/powerchat-pro" target="_blank" rel="noreferrer">
                      Learn More
                    </a>
                  </label>
                  <div className={`color-picker-wrapper ${!accountSettings.proViewer && !accountSettings.proStreamer ? 'disabled' : ''}`}>
                    <ColorInput
                      name="ttsProDonatorColor"
                      value={proSettings.ttsProDonatorColor}
                      onValueChange={(value) => handleProSettingChange('ttsProDonatorColor', value)}
                      disabled={!accountSettings.proViewer && !accountSettings.proStreamer}
                    />
                    {!accountSettings.proViewer && !accountSettings.proStreamer && (
                      <div className="note">
                        Upgrade to Powerchat Pro to customize powerchat colors
                      </div>
                    )}
                  </div>
                </div>
                <button type="submit">Save</button>
              </form>
              <div style={spinnerStyle}>
                <FadeLoader
                  color="#ffffff"
                  loading={savingAccountSettings}
                  size={20}
                />
              </div>
              <FormAlert alertState={alertState} width={400} />
            </div>
          </Card>
        )}
        {visibleTab === 'password' && (
          <Card title="Password" lineClass="password-line">
            <ChangePasswordForm />
          </Card>
        )}
        {visibleTab === 'emails' && (
          <Card title="Emails" lineClass="emails-line">
            <EmailSettingsForm />
          </Card>
        )}
        {visibleTab === 'security' && (
          <Card title="Security" lineClass="security-line">
            <SecurityForm email={accountSettings.email}
              securitySettings={securitySettings}
              setSecuritySettings={setSecuritySettings} />
          </Card>
        )}
        {visibleTab === 'powerchat pro' && (
          <Card title={<div>Powerchat Pro<sup>BETA</sup></div>} lineClass="powerchat-pro-line">
            <ProSubscribe triggerAlert={triggerAlert} />
            <div style={{ marginTop: 10, width: '100%',
                display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
              <FormAlert alertState={alertState} width={600} />
            </div>
          </Card>
        )}
        {visibleTab === 'subscriptions' && (
            <Card title="Subscriptions" lineClass="subscriptions-line">
              <button
                className="manage-subs-btn"
                onClick={manageStripeSubscriptions}
                disabled={!subscriptions || subscriptions.length == 0}
              >
                Manage Subscriptions
              </button>
              <div className="subscriptions-settings">
                {subscriptions && (
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <span>Streamer</span>
                        </th>
                        <th>
                          <span>Status</span>
                        </th>
                        <th>
                          <span>Next Pay Period</span>
                        </th>
                        <th>
                          <span>Auto Renew</span>
                        </th>
                        <th>
                          <span>Streak</span>
                        </th>
                        <th>
                          <span>Renew / Cancel</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {subscriptions.map(
                        (
                          {
                            subscription_to,
                            status,
                            recurring,
                            expires_at,
                            streak,
                            id,
                          },
                          index
                        ) => (
                          <tr key={id}>
                            <td>{subscription_to}</td>
                            <td className={status === 'Active' && ''}>
                              {status}
                            </td>
                            <td>{expires_at}</td>
                            <td>{recurring ? 'Yes' : 'No'}</td>
                            <td>{streak}</td>
                            <td className="text-light-blue">
                              {recurring ? (
                                <div className="toggle-sub-btn">
                                  <button
                                    onClick={() =>
                                      cancelSubscription(id, index)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      size="lg"
                                      color="#57bad8"
                                    />
                                  </button>
                                </div>
                              ) : (
                                <div className="toggle-sub-btn">
                                  <button
                                    onClick={() =>
                                      restartSubscription(id, index)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faRotateRight}
                                      size="lg"
                                      color="#57bad8"
                                    />
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                )}
              </div>
            </Card>
          )}
        {visibleTab === 'following' && (
            <Card title="Following" lineClass="following-line">
              <Following
                selfUsername={displayName}
                list={following}
                hasTooltip={false}
              />
            </Card>
          )}
      </div>
    </div>
  );
};

export default AccountSettings;
