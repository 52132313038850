import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
const crypto = require("crypto");
const dayjs = require('dayjs');
import useAuth from '../hooks/useAuth';
import '../css/settings-form.css';
import '../css/payment-integrations.css';
import { ThemeProvider, createTheme } from '@mui/material';
import ToggleSwitch from './toggle-switch';
import FormAlert from './form-alert';
import TotpDialog from './dialogs/totp-dialog';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CoinSelection from './coin-selection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  faCheckSquare,
  faTimesSquare,
} from '@fortawesome/free-solid-svg-icons';
import businessDays from 'business-days-js';

const PaymentIntegrations = ({ updateData, isMod = false, ...props }) => {
  const { user } = useAuth();
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [formData, setFormData] = useState({
    ...props,
    paypalEmail: props.paypalEmail || '',
    stripeLinked: !!props.stripeUserId,
    stripeEnabled: props.stripeEnabled,
    squareEnabled: props.squareEnabled,
    squareLinked: !!props.squareUserId,
    paypalLinked: !!props.paypalMerchantId,
    tipaltiActive: props.tipaltiActive,
    tipaltiEnabled: !!props.tipaltiEnabled,
    coinName: props.coinName || 'Litecoin',
    coinCode: props.coinCode || 'LTC',
    coingeckoId: props.coingeckoId || 'litecoin',
  });
  const twoFaSetup = props.twoFaSetup;
  const initialData = props;
  const [tooltip, setTooltip] = useState('');
  const [waitingSave, setWaitingSave] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [stripeExpressLoading, setStripeExpressLoading] = useState(false);
  const [tipaltiVisible, setTipaltiVisible] = useState(false);
  const [tipaltiSource, setTipaltiSource] = useState('');
  //const coinsLoaded = false;
  const [totpDialogOpen, setTotpDialogOpen] = useState(false);
  const [totpDialogMessage, setTotpDialogMessage] = useState('');
  const [totpError, setTotpError] = useState(false);
  const totpDialogCallback = useRef(null);
  const [coinOptions, setCoinOptions] = useState([]);
  const [coinDialogOpen, setCoinDialogOpen] = useState(false);
  const CRYPTO_DISPLAY_MIN = 0.000001;
  const [flashState, setFlashState] = useState({
    stripe: false,
    square: false,
    tipalti: false,
    crypto: false,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggle = (name) => {
    setFormData({
      ...formData,
      [name]: !formData[name],
    });
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const linkStripe = async () => {
    setStripeLoading(true);
    location.href = '/api/stripe/auth';
  };

  const createStripeExpress = async () => {
    setStripeExpressLoading(true);
    const response = await axios.post('/api/stripe/express');
    setStripeExpressLoading(false);
    location.href = response.data.url;
  };

  const manageStripeExpress = async () => {
    setStripeExpressLoading(true);
    location.href = '/api/stripe/express-dashboard';
  };

  const onUnlinkStripeClick = () => {
    let result = confirm(
      'Are you sure? Unlinking will cancel any recurring Stripe subscriptions to your account.'
    );
    if (!result) {
      return;
    }
    if (twoFaSetup) {
      setTotpDialogMessage('Authentication required for unlinking Stripe');
      totpDialogCallback.current = async (totp) => {
        try {
          await unlinkStripe(totp);
          totpDialogClose();
        } catch (err) {
          console.error(err);
          if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
            setTotpError(true);
          }
        }
      };
      setTotpDialogOpen(true);
    }
  };

  const unlinkStripe = async (totp = null) => {
    setStripeLoading(true);
    await axios.delete('/api/stripe', { data: { totp }});
    setFormData({
      ...formData,
      stripeUserId: '',
      stripeLinked: false,
    });
    setStripeLoading(false);
    updateData({ stripeUserId: '' });
  };

  const linkSquare = async () => {
    location.href = '/api/square/auth';
  };

  const onUnlinkSquareClick = () => {
    if (twoFaSetup) {
      setTotpDialogMessage('Authentication required for unlinking Square');
      totpDialogCallback.current = async ({totp}) => {
        try {
          await unlinkSquare(totp);
          totpDialogClose();
        } catch (err) {
          console.error(err);
          if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
            setTotpError(true);
          }
        }
      };
      setTotpDialogOpen(true);
    }
  };

  const unlinkSquare = async (totp = null) => {
    await axios.delete('/api/square', { data: { totp }});
    setFormData({
      ...formData,
      squareUserId: '',
      squareLinked: false,
    });
    updateData({ squareUserId: '' });
  };

  const linkPaypal = async () => {
    location.href = '/api/paypal/link';
  };

  const unlinkPaypal = async () => {
    await axios.delete('/api/paypal/link');
    setFormData({
      ...formData,
      paypalLinked: false,
    });
    updateData({ paypalMerchantId: '' });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setWaitingSave(true);
    try {
      // If user is changing their crypto address, ask for 2FA
      let hasNewAddress = !!formData.cryptoAddress && formData.cryptoAddress != '';
      let hasOldAddress = !!initialData.cryptoAddress && initialData.cryptoAddress != '';
      let addressChanged = formData.cryptoAddress != initialData.cryptoAddress
        && (hasNewAddress || hasOldAddress);
      let coinChanged = formData.coinCode != initialData.coinCode;
      if (addressChanged || coinChanged) {
        //verify address is valid
        if (hasNewAddress) {
          let verifyResponse = await axios.post('/api/verify-address', {
            address: formData.cryptoAddress,
            coin: formData.coinCode,
          });
          if (verifyResponse.data !== true) {
            triggerAlert(`Not a valid address for ${formData.coinCode}`, false);
            return;
          }
        }

        if (!twoFaSetup) {
          triggerAlert('Must have Two-Factor Authentication setup to change cryptocurrency address', false);
          return;
        }
        else {
          setTotpDialogMessage('Authentication required for changing cryptocurrency address');
          totpDialogCallback.current = async ({totp}) => {
            try {
              await savePaymentIntegrations(totp);
              totpDialogClose();
            } catch (err) {
              console.error(err);
              if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
                setTotpError(true);
              }
            }
          };
          setTotpDialogOpen(true);
        }
      }
      else {
        savePaymentIntegrations();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setWaitingSave(false);
    }
  };

  const savePaymentIntegrations = async (totp = null) => {
    try {
      await axios.post('/api/payment-integrations', {
        paypalEmail: formData.paypalEmail,
        paypalEnabled: formData.paypalEnabled,
        stripeEnabled: formData.stripeEnabled,
        squareEnabled: formData.squareEnabled,
        cryptoEnabled: formData.cryptoEnabled,
        tipaltiEnabled: formData.tipaltiEnabled,
        address: formData.cryptoAddress,
        instantAlerts: formData.cryptoInstantAlerts,
        coinName: formData.coinName,
        coinCode: formData.coinCode,
        coingeckoId: formData.coingeckoId,
        totp,
      });
      updateData(formData);
      triggerAlert('Payment settings successfully updated!');
    } catch (err) {
      if (err.response && err.response.data == 'Failed 2-Factor Authentication') {
        throw err;
      }
      console.error(err);
      triggerAlert('Could not update payment settings', false);
    }
  };

  const linkTipalti = () => {
    if (!twoFaSetup) {
      return;
    }
    setTipaltiVisible(true);
    setFormData({
      ...formData,
      tipaltiEnabled: true,
    });
  };

  const totpDialogClose = () => {
    setTotpDialogOpen(false);
    setTotpError(false);
  };

  useEffect(() => {
    const loadCoins = async () => {
      let response = await axios.get('/pubapi/crypto/coins');
      setCoinOptions(response.data.map(x => ({
        ...x,
        visible: true,
      })));

      //TODO uncomment after enabling payouts with other coins
      //setCoinsLoaded(true);
    };
    loadCoins();
  }, []);

  /*const openCoinDialog = () => {
    if (formData.cryptoEnabled && twoFaSetup) {
      setCoinDialogOpen(true);
    }
  }*/

  const onCoinSelected = (coin) => {
    setCoinDialogOpen(false);
    setFormData({
      ...formData,
      coinName: coin.name,
      coinCode: coin.code,
      coingeckoId: coin.coingeckoId,
      cryptoAddress: '',
    });
    updateData({
      coinName: coin.name,
      coinCode: coin.code,
      coingeckoId: coin.coingeckoId,
      cryptoAddress: '',
    });
  };

  const selectedCoin = () => ({
    name: formData.coinName,
    code: formData.coinCode,
  });

  const spinnerStyle = {
    margin: 10,
    verticalAlign: 'middle',
  };

  const getTipaltiIframe = async () => {
    if (!user.id) {
      triggerAlert('Could not link Tipalti account. Try logging out and back in.');
      return;
    }
    const baseUrl = process.env.TIPALTI_IFRAME;

    let ts = Date.now()/1000;
    let params = `idap=${process.env.TIPALTI_USER_PREFIX}${user.id}&payer=Powerchat&ts=${ts}`;

    let secret = process.env.TIPALTI_KEY;
    let hmac = getHmac(secret, params, 'sha256', false);
    params += `&hashkey=${hmac}`;

    return baseUrl + params;
  };

  useEffect(() => {
    if (tipaltiVisible) {
      getTipaltiIframe().then(x => setTipaltiSource(x));
    } else if (!formData.tipaltiActive) {
      checkTipaltiActive();
      setTimeout(checkTipaltiActive, 5000);
      setTimeout(checkTipaltiActive, 30000);
    }
  }, [tipaltiVisible, formData.tipaltiActive]);

  const checkTipaltiActive = useCallback(async () => {
    if (formData.tipaltiActive) {
      return;
    }
    try {
      let response = await axios.get('/api/tipalti-settings');
      setFormData({
        ...formData,
        tipaltiActive: response.data.active,
      });
    } catch (err) {
      console.error(err);
    }
  }, [formData.tipaltiActive]);

  const getHmac = (secret, data, alg, includeAlg = true) => {
    let hmac = crypto.createHmac(alg, secret);
    let digest = Buffer.from(`${includeAlg ? alg + '=' : ''}${hmac.update(data).digest('hex')}`, 'utf8');
    return digest.toString();
  };

  const viewTransactions = (paymentPlatform, bDays = false) => {
    let now = new Date();
    let monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);

    // For powerchat pay - 5 business days before end of month for pay period
    if (bDays) {
      setEndOfCycle(now);
      setEndOfCycle(monthAgo);
    }

    props.selectTab('events', {
      minDate: dayjs(monthAgo),
      maxDate: dayjs(now),
      paymentPlatform,
    });
  };

  const setEndOfCycle = (date) => {
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    let bdays = businessDays();

    let i = 5;
    // Count backwards from first day of next month until 3 business days pass
    while (i > 0) {
      date.setDate(date.getDate() - 1);
      if (bdays.check(date)) {
        i--;
      }
    }
  };

  const getNextPayoutDate = () => {
    let date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(1);
    return date.toLocaleDateString('default', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const flashNote = (section, on = true) => {
    setFlashState({
      ...flashState,
      [section]: on,
    });
  };

  return (
    <div className="payment-integrations form-container settings-container">
      {process.env.STRIPE_ENABLED == 'true' && (
        <div className="form-section">
          <h3 className="text-light-blue">
            Stripe: &nbsp;
            {formData.stripeLinked && (formData.stripeExpress == null || formData.stripeExpress == 2) ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h3>
          <div>
            {formData.stripeLinked ?
              formData.stripeExpress ? (
                <div>
                  <div className="radio-input">
                    <ToggleSwitch
                      name="stripeEnabled"
                      onChange={() => handleToggle('stripeEnabled')}
                      checked={formData.stripeEnabled}
                      disabled={isMod}
                    />
                    <label htmlFor="stripeEnabled">Stripe Enabled</label>
                  </div>
                  <button className="secondary-btn" onClick={manageStripeExpress} disabled={isMod}>
                    {formData.stripeExpress == 2 ? 'Manage Stripe' : 'Setup Stripe'}
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeExpressLoading} size={20} />
                  </span>
                  <div style={{margin:0}} className="note">
                    {formData.stripeExpress == 2
                      ? "Your Stripe Express account has been created. You&apos;re all set!"
                      : "Your Stripe Express account needs some info. Please complete setup."
                    }
                  </div>
                </div>
              ) : (
                <div>
                  <div className="radio-input">
                    <ToggleSwitch
                      name="stripeEnabled"
                      onChange={() => handleToggle('stripeEnabled')}
                      checked={formData.stripeEnabled}
                    />
                    <label htmlFor="stripeEnabled">Stripe Enabled</label>
                  </div>
                  <button className="secondary-btn" onClick={onUnlinkStripeClick}
                    disabled={!twoFaSetup}>
                    Unlink Stripe
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeLoading} size={20} />
                  </span>
                  <div style={{margin:0}} className="note">
                    Your Standard Stripe account is currently linked. You&apos;re all set!
                  </div>
                  {!twoFaSetup && (
                    <div className={"note " + (flashState.stripe ? 'flash' : '')}
                      style={{marginBottom: 0, marginTop: 10}}>
                      <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                      &nbsp;must be setup to unlink Stripe.
                    </div>
                  )}
                </div>
            ) : (
              <div>
                <div className="stripe-grid">
                  <label>Create a Stripe account:</label>
                  <button className="secondary-btn" onClick={createStripeExpress}>
                    Create Account
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeExpressLoading} size={20} />
                  </span>
                  <label>Link an existing account:</label>
                  <button className="secondary-btn" onClick={linkStripe}>
                    Link an Account
                  </button>
                  <span style={spinnerStyle}>
                    <ClipLoader color="#ffffff" loading={stripeLoading} size={20} />
                  </span>
                </div>
                <div className="note" style={{marginTop: 5, marginBottom: 0}}>
                  You can start getting paid on Powerchat in minutes with Stripe.
                  Simply click on one of the two buttons above to create or link an account.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="form-section">
        <h3 className="text-light-blue">
          Square &nbsp;
          {formData.squareLinked ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faTimesSquare} />
          )}
        </h3>
        <div className="integration-container">
          <div>
            {formData.squareLinked ? (
              <div>
                <div className="grid">
                  <button className="panel" onClick={onUnlinkSquareClick} disabled={!twoFaSetup || isMod}
                    onMouseOver={() => flashNote('square')} onMouseOut={() => flashNote('square', false)}>
                    Unlink Square
                  </button>
                  <button className="panel" onClick={() => viewTransactions('square')} style={{ gridColumn: 2 }}>
                    View Transactions
                  </button>
                  <a className="panel" href="https://app.squareup.com/dashboard" target="_blank" rel="noreferrer"
                    style={{ gridColumn: 3 }}>
                    Manage
                  </a>
                </div>
                {!twoFaSetup && (
                  <div className={"note note-2fa " + (flashState.square ? 'flash' : '')}>
                    <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                    &nbsp;must be setup to unlink Square.
                  </div>
                )}
              </div>
            ) : (
              <div>
                <button className="secondary-btn" disabled={!twoFaSetup || isMod} onClick={linkSquare}
                  onMouseOver={() => flashNote('square')} onMouseOut={() => flashNote('square', false)}>
                  Link Square
                </button>
              {!twoFaSetup && (
                <div className={"note note-2fa " + (flashState.square ? 'flash' : '')}>
                  <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                  &nbsp;must be setup to link Square.
                </div>
              )}
              </div>
            )}
          </div>
          <div className="note">
            Square is available for <b>USA based creators only</b>.
          </div>
          <div className="note">
            Square payouts occur daily on business days.
          </div>
          <div className="note">
            Note: The <b>&apos;Location Type&apos;</b> of your business should be set to&nbsp;
            <b>&apos;Mobile Location&apos;</b> under&nbsp;
            <a href="https://squareup.com/dashboard/locations" target="_blank" rel="noreferrer">
              Square location settings
            </a>
            &nbsp;to hide your physical address.
          </div>
          <div className="radio-inputs">
            <div className="radio-input">
              <ToggleSwitch
                name="squareEnabled"
                onChange={() => handleToggle('squareEnabled')}
                checked={formData.squareLinked && formData.squareEnabled}
                disabled={!formData.squareLinked || isMod}
              />
              <label htmlFor="squareEnabled">Square Enabled</label>
            </div>
          </div>
        </div>
      </div>
    {props.powerchatPayEnabled && (
      <ThemeProvider theme={createTheme({})}>
      <div className="form-section">
        <h3 className="text-light-blue">
          Powerchat Pay<sup>BETA</sup> &nbsp;
          {formData.tipaltiActive ? (
            <FontAwesomeIcon icon={faCheckSquare} />
          ) : (
            <FontAwesomeIcon icon={faTimesSquare} />
          )}
        </h3>
      {!tipaltiVisible && formData.tipaltiActive && (
        <div className="integration-container">
          <div className="grid">
            <div className="panel">
              {(formData.tipaltiBalance || '$0.00')}
            </div>
            <button className="panel" onClick={linkTipalti} style={{ gridColumn: 2 }}>
              Manage
            </button>
            <button className="panel" onClick={() => viewTransactions('nmi', true)} style={{ gridColumn: 3 }}>
              View Transactions
            </button>
            <div className="panel" style={{ gridRow: 2, gridColumn: "1 / 3" }}>
              Next Payout Expected: {getNextPayoutDate()}
            </div>
          </div>
          <div className="note">
            Powerchat Pay is available for <b>USA and International creators</b>.
          </div>
          <div className="note">
            Powerchat.live partners with&nbsp;
            <a href="https://tipalti.com/" target="_blank" rel="noreferrer">Tipalti</a>&nbsp;
            for secure and easy payments.
          </div>
          <div className="radio-inputs">
            <div className="radio-input">
              <ToggleSwitch
                name="tipaltiEnabled"
                onChange={() => handleToggle('tipaltiEnabled')}
                checked={formData.tipaltiEnabled}
                disabled={isMod}
              />
              <label htmlFor="tipaltiEnabled">Powerchat Pay Enabled</label>
            </div>
          </div>
        </div>
      )}
      {!tipaltiVisible && !formData.tipaltiActive ? (
        <div className="integration-container tipalti-container">
          <div>
            <button onClick={linkTipalti} disabled={!twoFaSetup} className="secondary-btn"
              onMouseOver={() => flashNote('tipalti')} onMouseOut={() => flashNote('tipalti', false)}>
              Setup
            </button>
            {!twoFaSetup && (
              <div className={"note note-2fa " + (flashState.tipalti ? 'flash' : '')}>
                <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                &nbsp;must be setup for Powerchat Pay.
              </div>
            )}
            <div className="note">
              Powerchat Pay is available for <b>USA and International creators.</b>
            </div>
            <div className="note">
              Payouts from Powerchat Pay occur monthly.<br/>
            </div>
            <div className="note">
              Powerchat.live partners with&nbsp;
              <a href="https://tipalti.com/" target="_blank" rel="noreferrer">Tipalti</a>&nbsp;
              for secure and easy payments.<br/>
            </div>
          </div>
        </div>
      ) : tipaltiVisible && (
        <div className="tipalti-container">
          <button className="secondary-btn" onClick={() => setTipaltiVisible(false)}>Close</button>
          <iframe src={tipaltiSource} title="tipalti" />
        </div>
      )}
      </div>
      </ThemeProvider>
    )}
      <form onSubmit={handleSubmit}>
        {props.paypalLegacy && (
          <div className="form-section">
            <h3 className="text-light-blue">
              Paypal
              {formData.paypalScrape &&
                (formData.paypalLinked ? (
                  <span>
                    : &nbsp;
                    <FontAwesomeIcon icon={faCheckSquare} />
                  </span>
                ) : (
                  <span>
                    : &nbsp;
                    <FontAwesomeIcon icon={faTimesSquare} />
                  </span>
                ))}
            </h3>
            <div className="radio-input">
              <ToggleSwitch
                name="paypalEnabled"
                onChange={() => handleToggle('paypalEnabled')}
                checked={formData.paypalEnabled}
                disabled={isMod}
              />
              <label htmlFor="paypalEnabled">Paypal Enabled</label>
            </div>
            {props.paypalScrape ? (
              <div>
                {formData.paypalLinked ? (
                  <button onClick={unlinkPaypal} className="secondary-btn" disabled={isMod}>
                    Unlink Paypal
                  </button>
                ) : (
                  <button onClick={linkPaypal} className="secondary-btn" disabled={isMod}>
                    Link Paypal
                  </button>
                )}
              </div>
            ) : (
              <div>
                <input
                  name="paypalEmail"
                  type="email"
                  placeholder="Enter your Paypal email"
                  onChange={handleChange}
                  className="top-input-margin"
                  value={formData.paypalEmail}
                  disabled={!formData.paypalEnabled || isMod}
                />
              </div>
            )}
          </div>
        )}
        <div className="form-section">
          <h3 className="text-light-blue">
            Cryptocurrency &nbsp;
            {formData.cryptoAddress && formData.cryptoAddress.trim() != '' ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h3>
          <div className="integration-container">
            <div className="grid">
              <div className="panel">
                {formData.cryptoBalance > CRYPTO_DISPLAY_MIN ? formData.cryptoBalance : 0}
                &nbsp;LTC
              </div>
              <button className="panel" onClick={() => viewTransactions('powerchat crypto')} style={{ gridColumn: 2 }}>
                View Transactions
              </button>
              <div style={{ gridColumn: 3 }} />
            </div>
            {/*<h4>Currency: //TODO uncomment
              <button type="button" disabled={!coinsLoaded || !formData.cryptoEnabled || !twoFaSetup || isMod}
                onMouseOver={() => flashNote('crypto')} onMouseOut={() => flashNote('crypto', false)}
                className="select-coin" onClick={openCoinDialog} title="Changing payout coin not available yet">
                {selectedCoin() && selectedCoin().name}
              </button>
            </h4>*/}
            <div className="note">
              Users can donate to you with any supported cryptocurrency.<br/>
              Powerchat Crypto converts all cryptocurrency to Litecoin (LTC) for easier payouts.
            </div>
            <div className="note">
              Link a Litecoin (LTC) wallet address to start receiving crypto.
              {/*TODO uncomment/*You will be paid out using this selected currency and address.*/}
            </div>
            <Dialog open={coinDialogOpen} onClose={() => setCoinDialogOpen(false)}
              classes={{ paper: 'crypto-dialog' }}>
              <DialogTitle>
                <div className="dialog-title">Select a Coin</div>
                <IconButton
                  aria-label="close"
                  onClick={() => setCoinDialogOpen(false)}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div className="dialog-container">
                  <CoinSelection
                    options={coinOptions}
                    onCoinSelected={onCoinSelected}
                    selectedCoin={selectedCoin()}
                  />
                </div>
              </DialogContent>
            </Dialog>
            <input
              name="cryptoAddress"
              placeholder="Enter your cryptocurrency address"
              onChange={handleChange}
              className="top-input-margin"
              disabled={!formData.cryptoEnabled || !twoFaSetup}
              value={formData.cryptoAddress}
              onMouseOver={() => flashNote('crypto')} onMouseOut={() => flashNote('crypto', false)}
            />
            {!twoFaSetup && (
              <div className={"note note-2fa " + (flashState.crypto ? 'flash' : '')}>
                <a href="/account-settings?tab=security">Two-Factor Authentication</a>
                &nbsp;must be setup to change your crypto address.
              </div>
            )}
            <div className="note">
              Payouts occur once daily and require a minimum balance of $20.00 USD.
            </div>
            <div className="radio-inputs">
              <div className="radio-input">
                <ToggleSwitch
                  name="cryptoEnabled"
                  onChange={() => handleToggle('cryptoEnabled')}
                  checked={formData.cryptoEnabled}
                  disabled={isMod}
                />
                <label htmlFor="cryptoEnabled">Crypto Payments Enabled</label>
              </div>
              <div className="flex-row">
                <div className="radio-input">
                  <ToggleSwitch
                    name="cryptoInstantAlerts"
                    onChange={() => handleToggle('cryptoInstantAlerts')}
                    disabled={!formData.cryptoEnabled}
                    checked={formData.cryptoInstantAlerts}
                  />
                  <label htmlFor="cryptoInstantAlerts">
                    Play Alerts Instantly Before Transaction is Confirmed
                  </label>
                </div>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('cryptoInstantAlerts')}
                  />
                  {tooltip === 'cryptoInstantAlerts' && (
                    <p className="tooltip shadow-lg">
                      Donation alert will play before the transactions are confirmed on the blockchain.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isMod && <button type="submit" disabled={waitingSave}>Save</button>}
      </form>
      <span style={spinnerStyle}>
        <ClipLoader color="#ffffff" loading={waitingSave} size={20} />
      </span>
      <TotpDialog
        message={totpDialogMessage}
        open={totpDialogOpen}
        onComplete={totpDialogCallback.current}
        onClose={totpDialogClose}
        showTotpError={totpError}
      />
      <FormAlert alertState={alertState} width={400} margin={0} />
    </div>
  );
};

export default PaymentIntegrations;
