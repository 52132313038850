import React, { useState, useEffect } from 'react';
import { 
  Button, 
  TextField} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlay, faPause, faTrash, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { voiceCategories } from './voice-categories';
import CopyButton from './copy-button';
import BasicSelect from './basic-select';
import ToggleSwitch from './toggle-switch';

const VoiceCard = ({ 
  voice, 
  type, 
  listId, 
  onRemove, 
  onActivate, 
  onDeactivate, 
  canDeactivate, 
  isEditing, 
  onSave, 
  onCancel, 
  onEdit, 
  disabled, 
  eventEmitter,
  isOwnVoice,
  voices
}) => {
  const normalizeCategory = (icon) => {
    if (!icon) return 'Default';
    return icon.charAt(0).toUpperCase() + icon.slice(1).toLowerCase();
  };

  const category = normalizeCategory(voice.icon);
  const { icon, color } = voiceCategories[category] || voiceCategories.Default;
  const sampleUrl = voice.sampleUrl.startsWith('https://') ? `/audioproxy?url=${voice.sampleUrl}` : voice.sampleUrl;
  const [editedName, setEditedName] = useState(voice.name || '');
  const [editedCategory, setEditedCategory] = useState(voice.icon || 'Default');
  const [editedCommand, setEditedCommand] = useState(voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`);
  const [editedPrivacy, setEditedPrivacy] = useState(voice.isPublic || false);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.useRef(null);
  const [nameError, setNameError] = useState(null);
  const [commandError, setCommandError] = useState(null);

  eventEmitter.on('playing', (message) => {
    if (message.voiceId != voice.id) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  });

  useEffect(() => {
    if (isEditing) {
      setEditedName(voice.name || '');
      setEditedCategory(voice.icon || 'Default');
      setEditedCommand(voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`);
      setEditedPrivacy(voice.isPublic || false);
      setNameError(null);
      setCommandError(null);
    }
  }, [isEditing, voice]);

  const validateName = (name) => {
    name = name.trim();
    if (name.length < 3) {
      setNameError('Name must be at least 3 characters');
      return false;
    }

    let unique = true;
    for (let type of ['my', 'basic', 'pro']) {
      for (let v of voices[type]) {
        if (v.id !== voice.id && v.name.toLowerCase() === name.toLowerCase()) {
          unique = false;
          break;
        }
      }
    }
    
    if (!unique) {
      setNameError('Name must be unique');
      return false;
    }

    setNameError(null);
    return true;
  };

  const validateCommand = (command) => {
    command = command.trim();
    if (!command.match(/^![A-Z0-9.\-_]+$/gi)) {
      setCommandError('Command must be ! followed by letters or numbers');
      return false;
    }

    let unique = command !== '!male' && command !== '!female';
    for (let type of ['my', 'basic', 'pro']) {
      for (let v of voices[type]) {
        if (v.id !== voice.id && 
            (v.command && v.command.toLowerCase() === command.toLowerCase() ||
             !v.command && `!${v.name.toLowerCase().replace(/\s+/g, '')}` === command.toLowerCase())) {
          unique = false;
          break;
        }
      }
    }

    if (!unique) {
      setCommandError('Command must be unique');
      return false;
    }

    setCommandError(null);
    return true;
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setEditedName(newName);
    validateName(newName);
  };

  const handleCommandChange = (e) => {
    const newCommand = e.target.value;
    setEditedCommand(newCommand);
    validateCommand(newCommand);
  };

  const handleSave = () => {
    if (!validateName(editedName) || !validateCommand(editedCommand)) {
      return;
    }

    onSave({ 
      ...voice, 
      name: editedName.trim(), 
      category: editedCategory, 
      command: editedCommand.trim(),
      isPublic: editedPrivacy,
      type 
    });
  };

  const truncateName = (name) => {
    if (name.length > 30) {
      return name.substring(0, 28) + '..';
    }
    return name;
  };

  const truncateCommand = (command) => {
    if (command.length > 25) {
      return command.substring(0, 23) + '..';
    }
    return command;
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      eventEmitter.emit('playing', { voiceId: voice.id });
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  const handleCancel = () => {
    setNameError(null);
    setCommandError(null);
    setEditedName(voice.name || '');
    setEditedCategory(voice.icon || 'Default');
    setEditedCommand(voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`);
    setEditedPrivacy(voice.isPublic || false);
    onCancel();
  };

  return (
    <div className={`voice-card ${type} ${voice.active ? 'active' : ''} ${isEditing ? 'editing' : ''} ${type === 'community' || type === 'pro' ? 'pro-style' : ''}`} data-list-id={listId}>
      {type === 'my' && isEditing && (
        <FontAwesomeIcon 
          icon={faTrash}
          title="Delete voice"
          className="trash-icon"
          onClick={onRemove}
        />
      )}
      {!isEditing && (
        <div className="voice-header">
          <div className="header-left">
            <div className="voice-icon" style={{ backgroundColor: color }}>
              <FontAwesomeIcon icon={icon} size="xs" />
            </div>
            <div className="voice-flair">{category}</div>
          </div>
        </div>
      )}
      {!isEditing && (
        <React.Fragment>
          <div 
            className={`voice-info ${isPlaying ? 'playing' : ''}`}
            onClick={togglePlay}
          >
            <h3 className="voice-name">{truncateName(voice.name)}</h3>
            <div className="play-button">
              <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
            </div>
          </div>
          <audio
            ref={audioRef}
            src={sampleUrl}
            onEnded={handleAudioEnded}
            style={{ display: 'none' }}
          />
          <div className="voice-command-container">
            <label className="voice-command-label">Command:</label>
            <CopyButton 
              text={voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`}
              display={truncateCommand(voice.command || `!${voice.name.toLowerCase().replace(/\s+/g, '')}`)}
              size="1x"
              title="Click to copy"
            />
          </div>
          {voice.creatorName && type === 'community' && (
            <div className="creator-name">
              Created by {voice.creatorName}
            </div>
          )}
        </React.Fragment>
      )}
      {isEditing && (
        <React.Fragment>
          {type === 'my' && (
            <React.Fragment>
              <TextField
                label="Name"
                value={editedName}
                onChange={handleNameChange}
                fullWidth
                size="small"
                className="edit-field"
                error={!!nameError}
                helperText={nameError}
              />
              <BasicSelect
                value={editedCategory}
                onChange={(e) => setEditedCategory(e.target.value)}
                options={Object.entries(voiceCategories).map(([category, data]) => ({
                  value: category,
                  label: (
                    <div className="category-menu-item">
                      <FontAwesomeIcon 
                        icon={data.icon} 
                        style={{ color: data.color }}
                      />
                      <span>{category}</span>
                    </div>
                  )
                }))}
                className="edit-field"
              />
              <TextField
                label="Command"
                value={editedCommand}
                onChange={handleCommandChange}
                fullWidth
                size="small"
                className="edit-field"
                error={!!commandError}
                helperText={commandError}
              />
                <div className="privacy-toggle">
                  <ToggleSwitch
                    checked={editedPrivacy}
                    onChange={(e) => setEditedPrivacy(e.target.checked)}
                    label="Public Voice"
                  />
                </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <div className="voice-actions">
        {isEditing ? (
          <div className="edit-actions">
            <button
              className="voice-card-btn"
              onClick={handleSave}
              color="primary"
              size="small"
            >
              <FontAwesomeIcon icon={faCheck} />
              Save
            </button>
            <button
              className="voice-card-btn-clear"
              onClick={handleCancel}
              size="small"
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </button>
          </div>
        ) : (
          <React.Fragment>
            {type === 'community' && isOwnVoice ? (
              <Button
                variant="outlined"
                className="your-voice-btn"
                disabled
                fullWidth
                size="small"
              >
                YOUR VOICE
              </Button>
            ) : (
              voice.active ? (
                <Button
                  variant="outlined"
                  className="deactivate-btn"
                  onClick={onDeactivate}
                  disabled={!canDeactivate}
                  fullWidth
                  size="small"
                >
                  DEACTIVATE
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  className="activate-btn"
                  onClick={onActivate}
                  disabled={disabled}
                  fullWidth
                  size="small"
                >
                  ACTIVATE
                </Button>
              )
            )}
            {type === 'my' && (
              <div className="my-voice-actions">
                <Button
                  variant="outlined"
                  className="edit-btn"
                  onClick={onEdit}
                  size="small"
                  fullWidth
                  startIcon={<FontAwesomeIcon icon={faPencil} />}
                >
                  Edit
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default VoiceCard;
