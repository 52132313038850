import React, { useEffect, useRef } from 'react';
import axios from 'axios';
const crypto = require('crypto');

const PowerchatPay = ({
  getPowerchat,
  reloadDisclaimer,
  disabled,
  completed,
  setLoading,
  collectLoaded,
  paymentMenuIsVisible,
}) => {
  const sessionId = useRef(null);
  const domLoaded = useRef(false);

  useEffect(() => {
    if (paymentMenuIsVisible && collectLoaded) {
      initializeGooglePay(getPowerchat());
      sessionId.current = getSessionId();
    }
  }, [collectLoaded, paymentMenuIsVisible]);

  const getSessionId = () => {
    return crypto.randomBytes(16).toString('hex').toUpperCase();
  };

  const initializeGooglePay = async (powerchat) => {
    let amount = powerchat ? powerchat.amount : 1;
    let config = {
      variant: 'inline',
      callback: createPayment,
      country: 'US',
      price: amount.toString(),
      currency: 'USD',
      paymentType: 'cc',
      //fieldsAvailableCallback: () => setFieldsLoaded(true),
      styleSniffer: false,
      timeoutDuration: 10000,
      timeoutCallback: function () {
        alert("The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity");
        //setLoadingPayment(false);
      },
      customCss: {
        'font-family': 'Arial',
        'font-size': '15px',
        'height': '31px',
      },
      invalidCss: {
        'border-color': 'red'
      },
      fields: {
        googlePay: {
          buttonColor: 'black',
          selector: '#google-pay-button-pc',
          billingAddressRequired: true,
          billingAddressParameters: {
            format: "FULL"
          },
        }
      }
    };
    window.CollectJS.configure(config);
    if (!domLoaded.current) {
      document.dispatchEvent(new Event('DOMContentLoaded'));
      domLoaded.current = true;
    }
  };

  const createPayment = async (response) => {
    try {
      setLoading(true);
      let powerchat = getPowerchat();
      response['payment_token'] = response.token;
      let body = {
        payment_token: response.token,
        transaction_session_id: sessionId.current,
        ...powerchat,
      }
      let addressDetails = { ...response.wallet.billingInfo };
      Object.assign(body, addressDetails);

      await axios.post('/pubapi/powerchat-pay', {
        ...body,
        currency: 'USD',
        ...powerchat,
      });
      completed('Payment completed');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      reloadDisclaimer();
    }
  };

  const errorStyle = {
    color: 'var(--red)',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    position: 'relative',
    top: -15,
  };

  return (
    <div className="google-pay-container">
      <span className="image-container">
        <img className="icon" src="/static/img/PowerchatPayLogo-black-no-logo.webp" />
      </span>
      <div
        id="google-pay-button-pc"
        style={{
          pointerEvents: disabled ? 'none' : 'inherit',
          marginTop: 10,
          opacity: disabled ? 0.5 : 1,
        }}
      ></div>
      {disabled && <div style={errorStyle}>
        Powerchat Pay amount must be $100 or under
      </div>}
    </div>
  );
};

export default PowerchatPay;
