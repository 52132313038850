import React from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, faMars, faVenus, faBalanceScale, faFilm, 
  faMicrophone, faStar, faGamepad, faMask, faDragon, 
  faPaintBrush, faVideo, faNewspaper, faGlobe,
  faMusic, faLaughSquint, faSearch
} from '@fortawesome/free-solid-svg-icons';

const { Option, DropdownIndicator, GroupHeading } = components;

const iconMapping = {
  'Default': { icon: faUser, color: '#57bad8', label: 'Default' },
  'Male': { icon: faMars, color: '#4caf50', label: 'Male' },
  'Female': { icon: faVenus, color: '#ff4081', label: 'Female' },
  'Politics': { icon: faBalanceScale, color: '#9c27b0', label: 'Politics' },
  'Cartoon': { icon: faPaintBrush, color: '#ffc107', label: 'Cartoon' },
  'Creator': { icon: faMicrophone, color: '#ff5722', label: 'Creator' },
  'Celebrity': { icon: faStar, color: '#f44336', label: 'Celebrity' },
  'Game': { icon: faGamepad, color: '#2196f3', label: 'Game' },
  'Movie': { icon: faFilm, color: '#795548', label: 'Movie' },
  'Fantasy': { icon: faDragon, color: '#009688', label: 'Fantasy' },
  'Anime': { icon: faMask, color: '#e91e63', label: 'Anime' },
  'TV': { icon: faVideo, color: '#3f51b5', label: 'TV' },
  'News': { icon: faNewspaper, color: '#607d8b', label: 'News' },
  'Foreign': { icon: faGlobe, color: '#00bcd4', label: 'Foreign' },
  'Singer': { icon: faMusic, color: '#673ab7', label: 'Singer' },
  'Comedy': { icon: faLaughSquint, color: '#ffeb3b', label: 'Comedy' }
};

const CustomGroupHeading = (props) => (
  <GroupHeading {...props}>
    <div className="voice-type-group">
      <div className="voice-type-label">{props.children}</div>
    </div>
  </GroupHeading>
);

const IconOption = (props) => {
  // Convert icon name to match our mapping (capitalize first letter)
  const iconKey = props.data.icon ? 
    props.data.icon.charAt(0).toUpperCase() + props.data.icon.slice(1).toLowerCase() : 
    'Default';
  
  const iconInfo = iconMapping[iconKey] || iconMapping.Default;
  const labelParts = props.data.label.split('\n').map((part, index) => (
    <div key={index}>{part}</div>
  ));
  
  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: iconInfo.color
      ? `linear-gradient(90deg, ${iconInfo.color}40 0%, rgba(255,255,255,0) 40%)`
      : 'none',
    width: '100%',
    padding: '10px',
    opacity: props.data.isDisabled ? 0.5 : 1,
    cursor: props.data.isDisabled ? 'not-allowed' : 'pointer'
  };

  const isProVoice = props.data.voiceTypeId === 10;
  const isTTSMonster = props.data.voiceTypeId === 12 && !props.data.isCustom;
  const isCustomVoice = props.data.isCustom;

  // Add badge styles inline to avoid modifying CSS file
  const badgeStyle = {
    fontSize: '0.65em',
    padding: '2px 6px',
    borderRadius: '3px',
    marginLeft: '6px',
    fontWeight: 'bold',
    display: 'inline-block',
    backgroundColor: isCustomVoice ? '#4caf50' : '#5bc7ec',
    color: 'white',
  };

  const handleClick = (e) => {
    if (props.data.isDisabled) {
      e.preventDefault();
      e.stopPropagation();
      window.location.href = '/account-settings?tab=powerchat%20pro';
    }
  };

  return (
    <Option {...props}>
      <div 
        style={style}
        onClick={handleClick}
        title={props.data.isDisabled ? 
          (isProVoice ? 'Subscribe to Pro Viewer to use this voice' : 
           'This voice requires Pro Viewer or Pro Streamer activation') : 
          `${props.data.label}\nThis is a ${iconInfo.label} voice`
        }
      >
        <FontAwesomeIcon 
          icon={iconInfo.icon} 
          style={{ 
            marginRight: '0.5rem',
            color: iconInfo.color,
            width: '16px'
          }} 
        />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {/* Only wrap in flex container if it's an activated Pro/Custom voice */}
          {props.data.isActivated && (isTTSMonster || isCustomVoice) ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {labelParts}
              <span style={badgeStyle}>
                {isCustomVoice ? 'CUSTOM' : 'PRO'}
              </span>
            </div>
          ) : (
            labelParts
          )}
          
          {(isProVoice || isTTSMonster || isCustomVoice) && props.data.isDisabled && (
            <div className="pro-required-tag">
              {isProVoice ? 'Pro Required' : 'Pro or Activated Required'}
            </div>
          )}
        </div>
      </div>
    </Option>
  );
};

const CustomDropdownIndicator = props => {
  return (
    <DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSearch} />
    </DropdownIndicator>
  );
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    width: '100%',
    minWidth: '200px',
    marginTop: '10px',
    borderRadius: '4px',
    borderColor: state.isFocused ? '#5bc7ec' : base.borderColor,
    boxShadow: state.isFocused ? '0 0 0 1px #5bc7ec' : base.boxShadow,
    '&:hover': {
      borderColor: state.isFocused ? '#5bc7ec' : base.borderColor,
    }
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    color: 'black',
    padding: 0,
    background: state.isFocused
      ? `rgba(91, 199, 236, 0.1)`
      : 'none',
  }),
  menu: (base) => ({
    ...base,
    borderRadius: '4px',
    marginTop: '0',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    "::-webkit-scrollbar": {
      width: '8px',
    },
    "::-webkit-scrollbar-track": {
      background: '#f1f1f1',
    },
    "::-webkit-scrollbar-thumb": {
      background: '#5bc7ec',
      borderRadius: '4px',
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: 'rgba(87, 186, 216, 0.9)',
    }
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: '4px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: 0,
    "::-webkit-scrollbar": {
      width: '8px',
    },
    "::-webkit-scrollbar-track": {
      background: '#f1f1f1',
      borderRadius: '4px',
    },
    "::-webkit-scrollbar-thumb": {
      background: '#5bc7ec',
      borderRadius: '4px',
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: 'rgba(87, 186, 216, 0.9)',
    },
  }),
  group: (base) => ({
    ...base,
    padding: 0,
    margin: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    margin: 0,
    padding: 0,
  }),
};

const VoiceSelect = ({ name, options, value, onChange, placeholder, disabled = false, isProViewer = false, streamerUsername }) => {
  // Organize options into groups
  const organizeOptions = (opts) => {
    const activated = [];
    const basic = [];
    const proVoices = [];

    opts.forEach(opt => {
      // Basic voices (1-83) always go in basic section
      if (opt.voiceTypeId <= 10) {
        basic.push(opt);
      }
      // Pro voices (84-153) - renamed from TTS Monster voices
      else if (opt.voiceTypeId === 12) {
        if (opt.isActivated) {
          activated.push(opt);
        } else {
          // Only add to Pro Voices section if not activated
          proVoices.push({
            ...opt,
            isDisabled: !isProViewer
          });
        }
      }
      // Custom voices
      else if (opt.isCustom) {
        if (opt.isActivated) {
          activated.push({
            ...opt,
            isCustom: true,
            voiceExternalId: opt.external_id
          });
        }
      }
    });

    const groups = [];
    
    // Only show activated section if there are activated voices
    if (activated.length > 0) {
      groups.push({
        label: streamerUsername ? `${streamerUsername}'s Voices` : 'Streamer Voices',
        options: activated
      });
    }

    // Always show basic voices section
    groups.push({
      label: 'Basic Voices',
      options: basic
    });

    // Show Pro Voices section if there are any non-activated voices
    if (proVoices.length > 0) {
      groups.push({
        label: 'Pro Voices',
        options: proVoices
      });
    }

    return groups;
  };

  return (
    <div className="voice-select-container">
      <Select
        name={name}
        options={organizeOptions(options)}
        styles={customStyles}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        components={{ 
          Option: IconOption, 
          DropdownIndicator: CustomDropdownIndicator,
          GroupHeading: CustomGroupHeading
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: '#5bc7ec',
            primary: '#5bc7ec',
          },
        })}
        isDisabled={disabled}
      />
    </div>
  );
};

export default VoiceSelect;
