import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/external-integrations.css';
import '../css/settings-form.css';
import FormAlert from './form-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCheckSquare,
  faTimesSquare,
  faChevronDown,
  faChevronUp,
  faLink,
  faLinkSlash,
  faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import CategorizedOptions from './categorized-options';
import ToggleSwitch from './toggle-switch';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

// External Integrations = Streaming platform links + external tts links
const ExternalIntegrations = ({ updateData, isMod = false, userIsMod = false, ...props }) => {
  const [alertState, setAlertState] = useState({
    trovo: {
      message: '',
      success: true,
      show: false,
    },
    kick: {
      message: '',
      success: true,
      show: false,
    },
    cozy: {
      message: '',
      success: true,
      show: false,
    },
    robotstreamer: {
      message: '',
      success: true,
      show: false,
    },
    twitch: {
      message: '',
      success: true,
      show: false,
    },
    youtube: {
      message: '',
      success: true,
      show: false,
    },
    rumble: {
      message: '',
      success: true,
      show: false,
    },
    dlive: {
      message: '',
      success: true,
      show: false,
    },
    x: {
      message: '',
      success: true,
      show: false,
    },
    parti: {
      message: '',
      success: true,
      show: false,
    },
    noice: {
      message: '',
      success: true,
      show: false,
    }
  });
  const [formData, setFormData] = useState({
    ...props.robotstreamerSettings,
    ...props.cozySettings,
    streamlabsLinked: !!props.slAccessToken,
    trovoLinked: !!props.trovoSettings && !!props.trovoSettings.channelId,
    ...props.trovoSettings,
    kickLinked: !!props.kickSettings && !!props.kickSettings.kickChannelId,
    ...props.kickSettings,
    twitchLinked: !!props.twitchSettings && !!props.twitchSettings.twitchId,
    ...props.twitchSettings, 
    youtubeLinked: !!props.youtubeSettings && !!props.youtubeSettings.channelId,
    ...props.youtubeSettings,
    dliveLinked: !!props.dliveSettings && !!props.dliveSettings.dliveUsername,
    ...props.dliveSettings,
    xLinked: !!props.xSettings && (!!props.xSettings.xId || !!props.xSettings.xLogin),
    ...props.xSettings,
    ...props.overlaySettings,
    rumbleLinked: !!props.rumbleSettings && !!props.rumbleSettings.rumbleChannelName,
    ...props.rumbleSettings,
    partiLinked: !!props.partiSettings && !!props.partiSettings.partiLink,
    ...props.partiSettings,
    noiceLinked: !!props.noiceSettings && !!props.noiceSettings.noiceChannelName,
    ...props.noiceSettings,
  });

  const [tooltip, setTooltip] = useState('');
  const [showOptions, setShowOptions] = useState({
    trovo: false,
    kick: false, 
    cozy: false,
    twitch:false,
    youtube: false,
    rumble: false,
    dlive: false,
    x: false,
    robotstreamer: false,
    parti: false,
    noice: false,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSlider = (evt) => {
    const { name, value } = evt.target;
    setFormData({
        ...formData,
        [name]: value,
    });
  };

  const triggerAlert = (section, message, success = true) => {
    setAlertState({
      ...alertState,
      [section]: {
        message,
        success,
      },
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const [isDliveSaving, setIsDliveSaving] = useState(false);
  const saveDlive = async (event) => {
    event.preventDefault();
    setIsDliveSaving(true); // Disable the submit button
    try {
      await axios.put('/api/dlive-settings', formData);
      updateData(formData);
      triggerAlert('dlive', 'Dlive settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('dlive', 'Could not update Dlive settings', false);
    } finally {
      setIsDliveSaving(false); // Re-enable the submit button
    }
  };

  const [isXSaving, setIsXSaving] = useState(false);
  const saveX = async (event) => {
    event.preventDefault();
    setIsXSaving(true); // Disable the submit button
    try {
      await axios.put('/api/x-settings', formData);
      updateData(formData);
      triggerAlert('x', 'X settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('x', 'Could not update X settings', false);
    } finally {
      setIsXSaving(false); // Re-enable the submit button
    }
  };

  const [isYouTubeSaving, setIsYouTubeSaving] = useState(false);
  const saveYoutubeSettings = async (event) => {
    event.preventDefault();
    setIsYouTubeSaving(true); // Disable the submit button
    try {
      await axios.put('/api/youtube-settings', formData);
      
      updateData(formData);
      triggerAlert('youtube', 'YouTube settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('youtube', 'Could not update YouTube settings', false);
    } finally {
      setIsYouTubeSaving(false); // Re-enable the submit button
    }
  };

  const [isTrovoSaving, setIsTrovoSaving] = useState(false);
  const saveTrovo = async (event) => {
    event.preventDefault();
    setIsTrovoSaving(true); // Disable the submit button
    try {
      await axios.put('/api/trovo-settings', formData);

      updateData(formData);
      triggerAlert('trovo', 'Trovo settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('trovo', 'Could not update Trovo settings', false);
    } finally {
      setIsTrovoSaving(false); // Re-enable the submit button
    }
  };

  const [isKickSaving, setIsKickSaving] = useState(false);
  const saveKick = async (event) => {
    event.preventDefault();
    setIsKickSaving(true); // Disable the submit button
    try {
      await axios.put('/api/kick-settings', formData);
  
      const lValue = formData.kickChannelId ? true : false;
      setFormData({
        ...formData,
        kickLinked: lValue,
      });
  
      updateData(formData);
      triggerAlert('kick', 'Kick settings successfully updated');
    } catch (err) {
      console.error(err);
      const errorMessage = (err.response && err.response.data)
                           ? err.response.data
                           : '';
      triggerAlert('kick', `Could not update Kick settings. ${errorMessage}`, false);
    } finally {
      setIsKickSaving(false); // Re-enable the submit button
    }
  };

  const [isRobotstreamerSaving, setIsRobotstreamerSaving] = useState(false);
  const saveRobotstreamer = async (event) => {
    event.preventDefault();
    setIsRobotstreamerSaving(true); // Disable the submit button
    try {
      if (formData.robotId && formData.robotId.length > 0 && (isNaN(formData.robotId) || formData.robotId < 1)) {
        throw new Error('Robot Id must be a positive number.');
      }

      await axios.put('/api/robotstreamer-settings', formData);

      updateData(formData);
      triggerAlert('robotstreamer', 'Robotstreamer ID successfully updated');
    } catch (err) {
      console.error('robotstreamer:', err);
      triggerAlert('robotstreamer', `Could not update Robotstreamer. ${err.message}`, false);
    } finally {
      setIsRobotstreamerSaving(false); // Re-enable the submit button
    }
  };

  const [isCozySaving, setIsCozySaving] = useState(false);
  const saveCozy = async (event) => {
    event.preventDefault();
    setIsCozySaving(true); // Disable the submit button
    try {
      await axios.put('/api/cozy', formData);

      updateData(formData);
      triggerAlert('cozy', 'Cozy.tv settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('cozy', 'Could not update Cozy.tv username', false);
    } finally {
      setIsCozySaving(false); // Re-enable the submit button
    }
  };

  const [isTwitchSaving, setIsTwitchSaving] = useState(false);
  const saveTwitch = async (event) => {
    event.preventDefault();
    setIsTwitchSaving(true); // Disable the submit button
    try {
      await axios.put('/api/twitch-settings', formData);

      updateData(formData);
      triggerAlert('twitch', 'Twitch settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('twitch', 'Could not update Twitch settings', false);
    } finally {
      setIsTwitchSaving(false); // Re-enable the submit button
    }
  };

  const [isRumbleSaving, setIsRumbleSaving] = useState(false);
  const saveRumble = async (event) => {
    event.preventDefault();
    setIsRumbleSaving(true); // Disable the submit button
    try {
      let { data } = await axios.put('/api/rumble-settings', formData);

      setFormData({
        ...formData,
        ...data,
      });

      triggerAlert('rumble', 'Rumble settings successfully updated');
    } catch (err) {
      console.error(err);
      if (err.response.data == 'NotExists') {
        triggerAlert('rumble', formData.rumbleChannelName + ' is not a valid Rumble channel', false);
      } else {
        triggerAlert('rumble', 'Could not update Rumble settings', false);
      }
    } finally {
      setIsRumbleSaving(false); // Re-enable the submit button
    }
  };

  const toggleShowOptions = (name) => {
    setShowOptions({
      ...showOptions,
      [name]: !showOptions[name],
    });
  };


  const linkStreamlabs = async () => {
    window.location.href = '/api/streamlabs_link';
  };

  const unlinkStreamlabs = async () => {
    const data = await axios.get('/api/streamlabs_unlink');
    if (data) {
      setFormData({
        ...formData,
        streamlabsLinked: false,
      });
      updateData({ streamlabsLinked: false });
    }
  };

  const linkDlive = async () => {
    location.href = '/pubapi/dlive/auth';
  };

  const unlinkDlive = async () => {
    await axios.delete('/api/dlive');
    setFormData({
      ...formData,
      dliveLinked: false,
    });
    updateData({ dliveChannelId: '' });
  };

  const linkX = async () => {
    location.href = '/pubapi/x/auth';
  };

  const unlinkX = async () => {
    await axios.delete('/api/x');
    setFormData({
      ...formData,
      xLinked: false,
    });
    updateData({ xId: '' });
  };

  const linkYoutube = () => {
    location.href = '/pubapi/youtube/auth';
  };

  const unlinkYoutube = async () => {
    await axios.delete('/api/youtube');
    setFormData({
      ...formData,
      youtubeLinked: false,
    });
    updateData({ youtubeChannelId: '' });
  };

  const linkTrovo = () => {
    location.href = '/pubapi/trovo/auth';
  };

  const unlinkTrovo = async () => {
    await axios.delete('/api/trovo');
    setFormData({
      ...formData,
      trovoLinked: false,
    });
    updateData({ trovoChannelId: '' });
  };

  const linkTwitch = () => {
    location.href = '/pubapi/twitch/auth';
  };

  const unlinkTwitch = async () => {
    await axios.delete('/api/twitch');
    setFormData({
      ...formData,
      twitchLinked: false,
    });
    updateData({ twitchChannelId: '' });
  };

  const [isPartiSaving, setIsPartiSaving] = useState(false);

  const saveParti = async (e) => {
    e.preventDefault();
    setIsPartiSaving(true);
    try {
      let { data } = await axios.put('/api/parti-settings', formData);

      setFormData({
        ...formData,
        ...data,
        partiLinked: !!formData.partiLink
      });

      updateData(formData);
      setAlertState({
        ...alertState,
        parti: {
          message: 'Parti settings saved successfully!',
          success: true,
          show: true
        }
      });
    } catch (error) {
      const errorMessage = error.response && error.response.data ? error.response.data : 'Error saving Parti settings';
      setAlertState({
        ...alertState,
        parti: {
          message: errorMessage,
          success: false,
          show: true
        }
      });
    } finally {
      setIsPartiSaving(false);
    }
  };

  const [isNoiceSaving, setIsNoiceSaving] = useState(false);

  const saveNoice = async (e) => {
    e.preventDefault();
    setIsNoiceSaving(true);
    try {
      let { data } = await axios.put('/api/noice-settings', formData);

      setFormData({
        ...formData,
        ...data,
        noiceLinked: !!formData.noiceChannelName
      });

      updateData(formData);
      setAlertState({
        ...alertState,
        noice: {
          message: 'Noice settings saved successfully!',
          success: true,
          show: true
        }
      });
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.error 
        ? error.response.data.error 
        : 'Error saving Noice settings';
      setAlertState({
        ...alertState,
        noice: {
          message: errorMessage,
          success: false,
          show: true
        }
      });
    } finally {
      setIsNoiceSaving(false);
    }
  };

  return (
    <div className="external-integrations">
      {/* YouTube */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.youtubeLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.youtubeLinked) toggleShowOptions('youtube');
            }}
          >
            {showOptions.youtube ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img style={{ maxWidth: '32px' }} className="icon" src="/static/img/youtube-icon.webp" />
            YouTube:&nbsp;
            {formData.youtubeLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.youtubeLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkYoutube}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkYoutube}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.youtubeLinked && showOptions.youtube && (
          <form onSubmit={saveYoutubeSettings}>
            <CategorizedOptions
              formData={formData}
              handleToggle={handleToggle}
              handleChange={handleChange}
              toggleTooltip={toggleTooltip}
              tooltip={tooltip}
              isMod={isMod}
              platform="youtube"
            />
            {!isMod && <button type="submit" disabled={isYouTubeSaving}>{isYouTubeSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.youtube}
              width={400}
            />
          </form>
        )}
      </div>

      {/* Twitch */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.twitchLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.twitchLinked) toggleShowOptions('twitch');
            }}
          >
            {showOptions.twitch ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/twitch.svg" />
            Twitch.tv&nbsp;
            {formData.twitchLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.twitchLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkTwitch}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkTwitch}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.twitchLinked && showOptions.twitch && (
          <form onSubmit={saveTwitch}>
            <CategorizedOptions
              formData={formData}
              handleToggle={handleToggle}
              handleChange={handleChange}
              toggleTooltip={toggleTooltip}
              tooltip={tooltip}
              isMod={isMod}
              platform="twitch"
            />
            {!isMod && <button type="submit" disabled={isTwitchSaving}>{isTwitchSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.twitch}
              width={400}
            />
          </form>
        )}
      </div>

      {/* Rumble */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle'
            }
            onClick={() => {
              toggleShowOptions('rumble');
            }}
          >
            {showOptions.rumble ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/rumble-logo.webp" />
            Rumble&nbsp;
            {formData.rumbleLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
        </div>
        {showOptions.rumble && (
          <form onSubmit={saveRumble}>
            <div className="options form-container">
              <div className="options-section">
                <div className="input-container">
                  <input
                    name="rumbleChannelName"
                    className="rumble-channel-name"
                    placeholder="Rumble Channel Name"
                    onChange={handleChange}
                    value={formData.rumbleChannelName || ""}
                    disabled={isMod}
                  />
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('rumbleChannelName')}
                    />
                    {tooltip === 'rumbleChannelName' && (
                      <p className="tooltip shadow-lg">
                        Copy and paste your full Rumble URL (&quot;<b>https://rumble.com/user/MyUsername</b>&quot;
                          or &quot;<b>https://rumble.com/c/MyChannelName</b>&quot;).
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <CategorizedOptions
                formData={formData}
                handleToggle={handleToggle}
                handleChange={handleChange}
                toggleTooltip={toggleTooltip}
                tooltip={tooltip}
                isMod={isMod}
                platform="rumble"
              />
            </div>
            {!isMod && <button id="rumbleSubmitBtn" type="submit" disabled={isRumbleSaving}>{isRumbleSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.rumble}
              width={400}
            />
          </form>
        )}
      </div>

      {/* Trovo */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.trovoLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.trovoLinked) toggleShowOptions('trovo');
            }}
          >
            {showOptions.trovo ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/trovo-icon.webp" />
            Trovo:&nbsp;
            {formData.trovoLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.trovoLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkTrovo}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkTrovo}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.trovoLinked && showOptions.trovo && (
          <form onSubmit={saveTrovo}>
            <div className="options form-container">
              <CategorizedOptions
                formData={formData}
                handleToggle={handleToggle}
                handleChange={handleChange}
                toggleTooltip={toggleTooltip}
                tooltip={tooltip}
                isMod={isMod}
                platform="trovo"
              />
              <div className="options-section" style={{ flex: 1 }}>
                <label htmlFor="playManaMinimum">Mana TTS Minimum</label>
                <input
                  name="playManaMinimum"
                  placeholder="Mana TTS Minimum"
                  onChange={handleChange}
                  disabled={
                    !formData.enableAllAlerts ||
                    !formData.enableManaSpells ||
                    !formData.playManaTts ||
                    isMod
                  }
                  value={formData.playManaMinimum}
                />
                <label htmlFor="playElixirMinimum">Elixir TTS Minimum</label>
                <input
                  name="playElixirMinimum"
                  placeholder="Elixir TTS Minimum"
                  onChange={handleChange}
                  disabled={
                    !formData.enableAllAlerts ||
                    !formData.enableElixirSpells ||
                    !formData.playElixirTts ||
                    isMod
                  }
                  value={formData.playElixirMinimum}
                />
              </div>
            </div>
            {!isMod && <button type="submit" disabled={isTrovoSaving}>{isTrovoSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.trovo}
              width={400}
            />
          </form>
        )}
      </div>

      {/* Kick */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle'
            }
            onClick={() => {
              toggleShowOptions('kick');
            }}
          >
            {showOptions.kick ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/kick-logo.webp" />
            Kick.com&nbsp;
            {formData.kickLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
        </div>
        {showOptions.kick && (
          <form onSubmit={saveKick}>
            <div className="options form-container">
              <div className="options-section">
                <div className="input-container">
                  <input style={{width:244}}
                    name="kickChannelId"
                    placeholder="Kick Channel Name"
                    onChange={handleChange}
                    value={formData.kickChannelId || ""}
                    disabled={isMod}
                  />
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('kickChannelId')}
                    />
                    {tooltip === 'kickChannelId' && (
                      <p className="tooltip shadow-lg">
                        Enter your Kick channel name (e.g. &quot;<b>MyUsername</b>&quot;).
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ color: '#ff4444', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px', maxWidth: '500px' }}>
                  IMPORTANT: You must make the user &quot;PowerchatBot&quot; a moderator on your Kick channel for follower tracking to work.
                </div>
              </div>
              <CategorizedOptions
                formData={formData}
                handleToggle={handleToggle}
                handleChange={handleChange}
                toggleTooltip={toggleTooltip}
                tooltip={tooltip}
                isMod={isMod}
                platform="kick"
              />
            </div>
            {!isMod && <button id="kickSubmitBtn" type="submit" disabled={isKickSaving}>{isKickSaving ? "Saving...": "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.kick}
              width={400}
            />
          </form>
        )}
      </div>

      {/* DLive */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.dliveLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.dliveLinked) toggleShowOptions('dlive');
            }}
          >
            {showOptions.dlive ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4 className="integration-header">
            <img className="icon" src="/static/img/dlive-logo.webp" />
            DLive.tv&nbsp;
            {formData.dliveLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.dliveLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkDlive}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkDlive}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.dliveLinked && showOptions.dlive && (
          <form onSubmit={saveDlive}>
            <div className="options form-container">
              <CategorizedOptions
                formData={formData}
                handleToggle={handleToggle}
                handleChange={handleChange}
                toggleTooltip={toggleTooltip}
                tooltip={tooltip}
                isMod={isMod}
                platform="dlive"
              />
            </div>
            {!isMod && <button type="submit" disabled={isDliveSaving}>{isDliveSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.dlive}
              width={400}
            />
          </form>
        )}
      </div>

      {/* X (Twitter) */}
      {userIsMod && (
        <div className="integration form-container">
          <div className="integration-header">
            <div
              className={
                'dropdown-toggle' + (formData.xLinked ? '' : ' disabled')
              }
              onClick={() => {
                if (formData.xLinked) toggleShowOptions('x');
              }}
            >
              {showOptions.x ? (
                <FontAwesomeIcon icon={faChevronUp} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="lg" />
              )}
            </div>
            <h4 className="integration-header">
              <img className="icon" src="/static/img/x/x-logo.webp" />
              X.com&nbsp;
              {formData.xLinked ? (
                <FontAwesomeIcon icon={faCheckSquare} />
              ) : (
                <FontAwesomeIcon icon={faTimesSquare} />
              )}
            </h4>
            <div>
              {formData.xLinked ? (
                <button
                  type="button"
                  className="secondary-btn"
                  onClick={unlinkX}
                  disabled={isMod}
                >
                  Unlink&nbsp;
                  <FontAwesomeIcon icon={faLinkSlash} />
                </button>
              ) : (
                <button
                  type="button"
                  className="secondary-btn"
                  onClick={linkX}
                  disabled={isMod}
                >
                  Link&nbsp;
                  <FontAwesomeIcon icon={faLink} />
                  <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
                </button>
              )}
            </div>
          </div>
          {formData.xLinked && showOptions.x && (
            <form onSubmit={saveX}>
              <div className="options form-container">
                <CategorizedOptions
                  formData={formData}
                  handleToggle={handleToggle}
                  handleChange={handleChange}
                  toggleTooltip={toggleTooltip}
                  tooltip={tooltip}
                  isMod={isMod}
                  platform="xcom"
                />
                <div className="options-section">
                  <Box style={{ maxWidth: 400 }}>
                    <label htmlFor="xUsernameStyle" style={{ marginBottom:'-1rem' }}>
                      Chatter Style
                      <span className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('xUsernameStyle')}
                        />
                        {tooltip === 'xUsernameStyle' && (
                          <p className="tooltip shadow-lg">
                            Changes how names display in live chat.<br />
                            Name = Just their name as it is displayed on their profile<br />
                            Handle = Just their @handle<br />
                            Both = Both the name and @handle, like it is shown on the X broadcast page.
                          </p>
                        )}
                      </span>
                    </label>
                    <Slider
                      name="xUsernameStyle"
                      step={1}
                      track={false}
                      min={0}
                      max={2}
                      marks={[
                        {
                          value: 0,
                          label: 'Name',
                        },
                        {
                          value: 1,
                          label: 'Handle',
                        },
                        {
                          value: 2,
                          label: 'Both',
                        },
                      ]}
                      valueLabelDisplay="off"
                      value={formData.xUsernameStyle}
                      onChange={handleSlider}
                      disabled={isMod}
                    />
                  </Box>
                </div>
              {userIsMod && (
                <div className="options-section">
                  <div className="toggle-container">
                    <ToggleSwitch
                      name="enableXDevMode"
                      onChange={() => handleToggle('enableXDevMode')}
                      checked={formData.enableXDevMode}
                      disabled={isMod}
                    />
                    <label htmlFor="enableXDevMode">Enable X Dev Mode</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('enableXDevMode')}
                      />
                      {tooltip === 'enableXDevMode' && (
                        <p className="tooltip shadow-lg">
                          Enable this to test your X integration with another X channel.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {userIsMod && formData.enableXDevMode && (
                <div className="options-section" style={{ flex: 1 }}>
                  <label htmlFor="xDevUsername">@Username you want to test with</label>
                  <input
                    name="xDevUsername"
                    placeholder="Username"
                    onChange={handleChange}
                    disabled={isMod}
                    value={formData.xDevUsername}
                  />
                </div>
              )}
              {!userIsMod && (
                formData.enableXDevMode = false,
                formData.xDevUsername = ''
              )}
              </div>
              {!isMod && <button type="submit" disabled={isXSaving}>{isXSaving ? "Saving...": "Save"}</button>}
              <FormAlert
                margin={'10px 0 0 0'}
                alertState={alertState.x}
                width={400}
              />
            </form>
          )}
        </div>
      )}
      
      { /* Cozy*/
      formData.cozytvEnabled && (
        <div className="integration form-container">
          <div className="integration-header">
            <div
              className={
                'dropdown-toggle' + (formData.cozytvEnabled ? '' : ' disabled')
              }
              onClick={() => {
                if (formData.cozytvEnabled) toggleShowOptions('cozy');
              }}
            >
              {showOptions.cozy ? (
                <FontAwesomeIcon icon={faChevronUp} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="lg" />
              )}
            </div>
            <h4 className="integration-header">
              <img className="icon" src="/static/img/cozy-icon.svg" />
              Cozy
            </h4>
          </div>
          {showOptions.cozy && (
            <form onSubmit={saveCozy}>
              <input
                name="cozytvUsername"
                placeholder="Enter your cozy.tv username"
                onChange={handleChange}
                value={formData.cozytvUsername}
                disabled={isMod}
              />
              <div className="options form-container">
                <CategorizedOptions
                  formData={formData}
                  handleToggle={handleToggle}
                  handleChange={handleChange}
                  toggleTooltip={toggleTooltip}
                  tooltip={tooltip}
                  isMod={isMod}
                  platform="cozy"
                />
              </div>
              {!isMod && <button type="submit" disabled={isCozySaving}>{isCozySaving ? "Saving..." : "Save"}</button>}
              <FormAlert margin={"10px 0 0 0"} alertState={alertState.cozy} width={400} />
            </form>
          )}
        </div>
      )}

      {/* Parti */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className="dropdown-toggle"
            onClick={() => toggleShowOptions('parti')}
          >
            {showOptions.parti ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/parti-logo.png" />
            Parti.com&nbsp;
            {formData.partiLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
        </div>
        {showOptions.parti && (
          <form onSubmit={saveParti}>
            <div className="options form-container">
              <div className="options-section">
                <div className="input-container">
                  <input style={{width:244}}
                    name="partiLink"
                    placeholder="Parti Channel Link"
                    onChange={handleChange}
                    value={formData.partiLink || ""}
                    disabled={isMod}
                  />
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('partiLink')}
                    />
                    {tooltip === 'partiLink' && (
                      <p className="tooltip shadow-lg">
                        Enter your full Parti channel link (e.g. &quot;https://parti.com/creator/parti/MyUsername&quot;).
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <CategorizedOptions
                formData={formData}
                handleToggle={handleToggle}
                handleChange={handleChange}
                toggleTooltip={toggleTooltip}
                tooltip={tooltip}
                isMod={isMod}
                platform="parti"
              />
            </div>
            {!isMod && <button type="submit" disabled={isPartiSaving}>{isPartiSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.parti}
              width={400}
              className={alertState.parti.success ? 'success' : 'error'}
            />
          </form>
        )}
      </div>

      {/* Noice */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className="dropdown-toggle"
            onClick={() => toggleShowOptions('noice')}
          >
            {showOptions.noice ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/noice-logo.webp" />
            Noice&nbsp;
            {formData.noiceLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
        </div>
        {showOptions.noice && (
          <form onSubmit={saveNoice}>
            <div className="options form-container">
              <div className="options-section">
                <div className="input-container">
                  <input style={{width:244}}
                    name="noiceChannelName"
                    placeholder="Noice Channel Name"
                    onChange={handleChange}
                    value={formData.noiceChannelName || ""}
                    disabled={isMod}
                  />
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('noiceChannelName')}
                    />
                    {tooltip === 'noiceChannelName' && (
                      <p className="tooltip shadow-lg">
                        Enter your Noice channel name (e.g. &quot;MyUsername&quot;).
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ color: '#ff4444', fontWeight: 'bold', marginTop: '10px', marginBottom: '10px', maxWidth: '500px' }}>
                  IMPORTANT: You must make the user &quot;PowerchatBot&quot; a moderator on your Noice channel.
                </div>
              </div>
              <CategorizedOptions
                formData={formData}
                handleToggle={handleToggle}
                handleChange={handleChange}
                toggleTooltip={toggleTooltip}
                tooltip={tooltip}
                isMod={isMod}
                platform="noice"
              />
            </div>
            {!isMod && <button type="submit" disabled={isNoiceSaving}>{isNoiceSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.noice}
              width={400}
              className={alertState.noice.success ? 'success' : 'error'}
            />
          </form>
        )}
      </div>

      {/* Robotstreamer */}
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className="dropdown-toggle"
            onClick={() => toggleShowOptions('robotstreamer')}
          >
            {showOptions.robotstreamer ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4 className="integration-header">
            <img style={{ backgroundColor: '#1dd1a1', padding: '2px', borderRadius: '4px' }} className="icon" src="/static/img/robotlogo.svg" />
            Robotstreamer
          </h4>
        </div>
        {showOptions.robotstreamer && (
          <form onSubmit={saveRobotstreamer}>
            <div className="options form-container">
              <div className="options-section">
                <div className="input-container">
                  <input
                    name="robotId"
                    placeholder="Enter your Robotstreamer Channel ID (Robot ID)"
                    onChange={handleChange}
                    value={formData.robotId || ""}
                    disabled={isMod}
                  />
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('robotId')}
                    />
                    {tooltip === 'robotId' && (
                      <p className="tooltip shadow-lg">
                        Enter your Robotstreamer Channel ID (Robot ID).
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <CategorizedOptions
                formData={formData}
                handleToggle={handleToggle}
                handleChange={handleChange}
                toggleTooltip={toggleTooltip}
                tooltip={tooltip}
                isMod={isMod}
                platform="robotstreamer"
              />
            </div>
            {!isMod && <button type="submit" disabled={isRobotstreamerSaving}>{isRobotstreamerSaving ? "Saving..." : "Save"}</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.robotstreamer}
              width={400}
            />
          </form>
        )}
      </div>

      {/* Streamlabs */}
      <div className="integration form-container">
        <div className="integration-header">
          <h4>
            <img className="icon" src="/static/img/streamlabs-icon.webp" />
            Streamlabs:&nbsp;
            {formData.streamlabsLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.streamlabsLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkStreamlabs}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkStreamlabs}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
          <div style={{ flex: 1 }} />
        </div>
        <div className="note">
          Linking Streamlabs will allow donation and subscription alerts from
          Powerchat to show up in your Streamlabs overlay.
        </div>
      </div>
    </div>
  );
};

export default ExternalIntegrations;
