import React, { useState, useRef, useEffect } from 'react';
import '../css/basic-select.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const BasicSelect = ({ 
  value, 
  onChange, 
  options, 
  disabled = false,
  className = '',
  placeholder = 'Select...'
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    onChange({ target: { value: option.value } });
    setIsOpen(false);
  };

  const selectedOption = options.find(opt => opt.value === value);

  return (
    <div 
      className={`basic-select ${isOpen ? 'open' : ''} ${disabled ? 'disabled' : ''} ${className}`}
      ref={selectRef}
    >
      <div 
        className="select-trigger"
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <div className="selected-value">
          {selectedOption ? (
            typeof selectedOption.label === 'string' ? 
              selectedOption.label : 
              React.cloneElement(selectedOption.label, {
                className: `${selectedOption.label.props.className || ''} menu-item-content`
              })
          ) : placeholder}
        </div>
        <FontAwesomeIcon 
          icon={faChevronDown} 
          className={`arrow-icon ${isOpen ? 'open' : ''}`}
        />
      </div>
      {isOpen && !disabled && (
        <div className="options-container">
          {options.map((option, index) => (
            <div
              key={index}
              className={`option ${option.value === value ? 'selected' : ''}`}
              onClick={() => handleSelect(option)}
            >
              {typeof option.label === 'string' ? 
                option.label : 
                React.cloneElement(option.label, {
                  className: `${option.label.props.className || ''} menu-item-content`
                })}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BasicSelect; 