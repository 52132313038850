import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import '../css/powerchat-pro.css';
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch, faMicrophone, faCloudUpload, faCheck, faTimes, faArrowDownAZ, faArrowUpAZ, faArrowDownShortWide, faArrowUpShortWide, faFilter, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Slider, TextField, ThemeProvider,
  Pagination, FormControlLabel, createTheme,
  useMediaQuery, useTheme, Button,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  DialogContentText,
  Checkbox
} from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import BarLoader from 'react-spinners/BarLoader';
import VoiceCard from './voice-card';
import ToggleSwitch from './toggle-switch';
import FormAlert from './form-alert';
import CustomAudioPlayer from './custom-audio-player';
import { voiceCategories } from './voice-categories';
import { useDropzone } from 'react-dropzone';
import { EventEmitter } from './event-emitter';
import CopyButton from './copy-button';
import BasicSelect from './basic-select';

// Create a custom theme
const customTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#57bad8',
    },
    background: {
      paper: '#1e1f22',
      default: '#2b2d31',
    },
    text: {
      primary: '#ffffff',
      secondary: grey[400],
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#57bad8',
          },
          '&:hover fieldset': {
            borderColor: blue[300],
          },
          '&.Mui-focused fieldset': {
            borderColor: '#57bad8',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#57bad8',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: 'rgba(87, 186, 216, 0.2)',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'rgba(87, 186, 216, 0.3)',
          },
        },
      },
    },
  },
});

const DEFAULT_VOICES = ['Joey', 'Joanna', 'Hans', 'Zhiyu'];

const PowerchatPro = () => {
  const { user } = useAuth();
  const MAX_FILES = 1;
  const voicesPerPage = 14;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [dropError, setDropError] = useState(null);
  const [ttsSettings, setTtsSettings] = useState({
    ttsChatsVolume: 50,
    ttsChatsMode: 0,
    ttsChatsAll: false,
    ttsChatsSubOnly: false,
    ttsChatsModOnly: false,
    chatReadSender: false,
    isProStreamer: false,
    hideChats: false,
    voiceName: 'Joey'
  });
  const allChatVoices = useRef(null);
  const [ttsChatsVoice, setTtsChatsVoice] = useState({});
  const [alertState, setAlertState] = useState({
    ttsSettings: {
      message: '',
      success: true,
    },
    myVoices: {
      message: '',
      success: true,
    },
    createVoice: {
      message: '',
      success: true,
    },
  });
  const [tooltip, setTooltip] = useState('');
  const [voices, setVoices] = useState({
    my: [],
    basic: [],
    pro: [],
  });
  const [voiceRules, setVoiceRules] = useState([]);
  const [proVoiceSearch, setProVoiceSearch] = useState('');
  const [proVoiceCategory, setProVoiceCategory] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [newVoiceName, setNewVoiceName] = useState('');
  const [newVoiceCategory, setNewVoiceCategory] = useState('Default');
  const [editingVoices, setEditingVoices] = useState({
    my: null,
    basic: null,
    pro: null
  });
  const [newVoiceCommand, setNewVoiceCommand] = useState('');
  const [newVoicePublic, setNewVoicePublic] = useState(true);
  const eventEmitter = new EventEmitter();
  const [submittingNewVoice, setSubmittingNewVoice] = useState(false);
  const [nameErrorText, setNameErrorText] = useState(null);
  const [commandErrorText, setCommandErrorText] = useState(null);
  const [communityVoiceSearch, setCommunityVoiceSearch] = useState('');
  const [communityVoiceCategory, setCommunityVoiceCategory] = useState('all');
  const [currentCommunityPage, setCurrentCommunityPage] = useState(1);
  const [activationFilter, setActivationFilter] = useState('all');
  const [communityActivationFilter, setCommunityActivationFilter] = useState('all');
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    voice: null
  });
  const [proVoiceSort, setProVoiceSort] = useState('nameAsc');
  const [communityVoiceSort, setCommunityVoiceSort] = useState('createdDesc');
  const [isLoading, setIsLoading] = useState(true);
  const [warningDialog, setWarningDialog] = useState({
    open: false,
    setting: null,
    currentValue: false
  });
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  // Add debounce delay constant
  const DEBOUNCE_DELAY = 500; // 500ms delay

  // Update the debounced function to handle empty name
  const debouncedUpdateCommand = useCallback(
    (name) => {
      // Clear command if name is empty, otherwise update based on name
      let value = name ? `!${name.toLowerCase().replace(/\s+/g, '')}` : '';
      setNewVoiceCommand(value);
      validateCommand(value);
    },
    []
  );

  // Add effect for debounced name changes
  useEffect(() => {
    const timer = setTimeout(() => {
      debouncedUpdateCommand(newVoiceName);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timer);
  }, [newVoiceName, debouncedUpdateCommand]);

  const triggerAlert = (message, section, success = true) => {
    setAlertState({
      ...alertState,
      [section]: {
        message,
        success,
      }
    });
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        // Load voices first
        const voicesResponse = await axios.get('/api/chat-tts-voices');
        const basicVoices = voicesResponse.data
          .filter(v => v.voiceTypeId == 1 && !v.isNeural)
          .map(v => ({
            ...v,
            category: v.icon == 'male' ? 'Male' : 'Female',
          }));
        
        setVoices({
          my: voicesResponse.data.filter(v => v.isOwner),
          basic: basicVoices,
          pro: voicesResponse.data.filter(v => (v.voiceTypeId == 12 && !v.custom) || v.isPublic)
        });

        // Then load settings
        const { data } = await axios.get('/api/pro-tts-settings');
        // Convert permissions bitflag to individual settings
        const perms = data.ttsSettings.ttsChatsPerms || 0;
        setTtsSettings({
          ...data.ttsSettings,
          ttsChatsAll: (perms & 1) === 1,
          ttsChatsSubOnly: (perms & 2) === 2,
          ttsChatsModOnly: (perms & 4) === 4
        });
        
        // Find the voice object that matches the voice name from settings
        const defaultVoice = basicVoices.find(v => v.name === data.ttsSettings.voiceName) || 
                            basicVoices.find(v => v.name === 'Joey'); // Fallback to Joey
        setTtsChatsVoice(defaultVoice || {});
        setVoiceRules(data.voiceRules);
      } catch (error) {
        console.error('Error loading data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    let voiceIds = voiceRules.filter(x => !x.custom).map(x => x.id);
    let customVoiceIds = voiceRules.filter(x => x.custom).map(x => x.id);
    
    // Update my voices
    setVoices(prevVoices => ({
      ...prevVoices,
      my: prevVoices.my.map(voice =>
        customVoiceIds.includes(voice.id) ? { ...voice, active: true } : { ...voice, active: false }
      )
    }));

    // Update basic and pro voices
    for (let type of ['basic', 'pro']) {
      setVoices(prevVoices => ({
        ...prevVoices,
        [type]: prevVoices[type].map(voice => {
          // Handle both regular pro voices and community voices
          if (voice.custom) {
            return customVoiceIds.includes(voice.id) ? { ...voice, active: true } : { ...voice, active: false };
          } else {
            return voiceIds.includes(voice.id) ? { ...voice, active: true } : { ...voice, active: false };
          }
        })
      }));
    }
  }, [voiceRules]);

  const handleTTSSettingChange = (setting, value) => {
    if (setting === 'ttsChatsMode') {
      // If switching to OFF (0), disable all permission flags
      if (value === 0) {
        setTtsSettings({
          ...ttsSettings,
          ttsChatsMode: value,
          ttsChatsAll: false,
          ttsChatsSubOnly: false,
          ttsChatsModOnly: false
        });
      } 
      // If switching to FLAG (1) or ALL (2), enable sub and mod permissions by default
      else if (ttsSettings.ttsChatsMode === 0) {
        setTtsSettings({
          ...ttsSettings,
          ttsChatsMode: value,
          ttsChatsAll: false,
          ttsChatsSubOnly: true,
          ttsChatsModOnly: true
        });
      } 
      // Otherwise just update the mode
      else {
        setTtsSettings({
          ...ttsSettings,
          ttsChatsMode: value
        });
      }
    } 
    // Handle permission switches
    else if (setting === 'ttsChatsSubOnly' || setting === 'ttsChatsModOnly' || setting === 'ttsChatsAll') {
      // Don't allow disabling if it would result in no permissions being enabled
      if (!value && 
          !ttsSettings[setting === 'ttsChatsSubOnly' ? 'ttsChatsModOnly' : 'ttsChatsSubOnly'] &&
          !ttsSettings.ttsChatsAll) {
        setWarningDialog({
          open: true,
          setting,
          currentValue: value
        });
        return;
      }
      setTtsSettings({
        ...ttsSettings,
        [setting]: value
      });
    }
    // Handle voice name changes
    else if (setting === 'voiceName') {
      // Find the voice object that matches the new voice name
      const newVoice = voices.basic.find(v => v.name === value) || 
                      voices.basic.find(v => v.name === 'Joey'); // Fallback to Joey
      setTtsChatsVoice(newVoice || {});
      setTtsSettings({
        ...ttsSettings,
        voiceName: value
      });
    }
    else {
      setTtsSettings({
        ...ttsSettings,
        [setting]: value
      });
    }
  };
    
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Calculate permissions bitflag by combining all enabled permissions
      let ttsChatsPerms = 0;
      if (ttsSettings.ttsChatsAll) ttsChatsPerms |= 1;    // Set bit 0 for all chatters
      if (ttsSettings.ttsChatsSubOnly) ttsChatsPerms |= 2; // Set bit 1 for subs
      if (ttsSettings.ttsChatsModOnly) ttsChatsPerms |= 4; // Set bit 2 for mods

      await axios.post('/api/pro-tts-settings', {
        ttsChatsVolume: ttsSettings.ttsChatsVolume,
        ttsChatsMode: ttsSettings.ttsChatsMode,
        chatReadSender: ttsSettings.chatReadSender,
        hideChats: ttsSettings.hideChats,
        ttsVoiceId: ttsChatsVoice.id,
        voiceName: ttsSettings.voiceName,
        ttsChatsPerms
      });
      triggerAlert('Settings saved successfully', 'ttsSettings');
    } catch (error) {
      console.error('Error updating TTS settings:', error);
      triggerAlert('Could not save settings', 'ttsSettings', false);
    }
  };

  const handleDeleteOpen = (voice) => {
    setDeleteDialog({
      open: true,
      voice
    });
  };

  const handleDeleteClose = () => {
    setDeleteDialog({
      open: false,
      voice: null
    });
  };

  const removeVoice = async () => {
    try {
      const voice = deleteDialog.voice;
      await axios.delete(`/api/ttsmonster/voice/${voice.id}`);
      setVoices(prevVoices => ({
        ...prevVoices,
        my: prevVoices.my.filter(v => v.id !== voice.id),
        pro: prevVoices.pro.filter(v => v.id !== voice.id)
      }));
      triggerAlert('Voice successfully deleted', 'myVoices');
      handleDeleteClose();
    } catch (error) {
      console.error('Error deleting voice:', error);
      triggerAlert('Error deleting voice', 'myVoices', false);
    }
  };

  const findVoice = (id, custom) => {
    return voices.my.find(x => x.id === id && !!x.custom == custom) ||
           voices.basic.find(x => x.id === id && !!x.custom == custom) ||
           voices.pro.find(x => x.id === id && !!x.custom == custom);
  };

  let isCustom = (type) => type === 'my' || type === 'community';
  const activateVoice = async (voiceId, type) => {
    try {
      let custom = isCustom(type);
      await axios.post('/api/pro-voice/activate/' + voiceId, { custom });
      let voice = findVoice(voiceId, custom);
      setVoiceRules(rules => [...rules, voice]);
    } catch (error) {
      console.error('Error activating voice:', error);
    }
  };

  const deactivateVoice = async (voiceId, type) => {
    try {
      let custom = isCustom(type);
      await axios.post('/api/pro-voice/deactivate/' + voiceId, { custom });
      setVoiceRules(rules => rules.filter(x => !(x.id == voiceId && !!x.custom == custom)));
    } catch (error) {
      console.error('Error deactivating voice:', error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.map(file => ({
        file,
        preview: URL.createObjectURL(file)
      }));
      /*if (uploadedFiles.length + acceptedFiles.length > MAX_FILES) {
        setDropError('Max files: ' + MAX_FILES);
        return;
      }*/
      // Must be under 10 MB
      if (newFiles[0].file.size >= 10485760) {
        setDropError('File must be under 10 MB');
        return;
      }
      let audio = document.createElement('audio');
      audio.src = newFiles[0].preview;
      audio.addEventListener('loadedmetadata', () => {
        if (audio.duration < 30) {
          setDropError('File must be longer than 30 seconds');
          return;
        }
        if (audio.duration >= 5 * 60) {
          setDropError('File must be shorter than 5 minutes');
          return;
        }
        setDropError(null);
        axios.get('/pubapi/ttsmonster/chat-voices').then(res => {
          allChatVoices.current = res.data;
        });
        setUploadedFiles([...newFiles]);
      });
    },
    accept: '.mp3,.wav',
    uploadMultiple: false,
    maxFiles: MAX_FILES,
    onDropRejected: (files) => {
      if (files.length > MAX_FILES) {
        setDropError('Max files: ' + MAX_FILES);
      }
    },
    disabled: !ttsSettings.isProStreamer,
  });

  const onNameChanged = (e) => {
    const name = e.target.value;
    setNewVoiceName(name);
    
    // Validate name as before
    let unique = true;
    for (let voice of allChatVoices.current) {
      if (voice.name.toLowerCase() == name.toLowerCase().trim()) {
        unique = false;
        break;
      }
    }
    setNameErrorText(unique ? null : 'Name must be unique');
  };

  const onCommandChanged = (e) => {
    let { value } = e.target;
    value = value.replace(/ /g, '').toLowerCase().trim();
    setNewVoiceCommand(value);
    validateCommand(value);
  };

  const validateCommand = (value) => {
    if (value == null || value == '') {
      return;
    }

    if (!value.match(/^![A-Z0-9.\-_]+$/gi)) {
      setCommandErrorText('Command must be ! followed by letters or numbers');
      return;
    }

    let unique = value != '!male' && value != '!female';
    for (let voice of allChatVoices.current) {
      if (voice.command && voice.command.toLowerCase() == value
        || !voice.command && `!${voice.name.toLowerCase().replace(/\s+/g, '')}` == value) {
        unique = false;
        break;
      }
    }
    setCommandErrorText(unique ? null : 'Command must be unique');
  };

  const createVoice = async () => {
    try {
      let form = new FormData();
      let { file } = uploadedFiles[0];
      form.append('file', file);
      
      form.append('name', newVoiceName);
      form.append('command', newVoiceCommand);
      form.append('category', newVoiceCategory);
      form.append('audioType', file.name.endsWith('mp3') ? 'audio/mpeg' : 'audio/wav');
      form.append('isPublic', newVoicePublic);
      form.append('permissions', JSON.stringify({
        all: true,
        sub: false,
        mod: false
      }));
      
      setSubmittingNewVoice(true);
      let { data } = await axios.post('/api/ttsmonster/create', form);
      let newVoice = {
        ...data,
        name: newVoiceName,
        value: newVoiceName,
        label: newVoiceName,
        command: newVoiceCommand,
        voiceTypeId: 12,
        icon: newVoiceCategory.toLowerCase(),
        custom: true,
        isPublic: newVoicePublic,
        isOwner: true,
        creatorName: user.username
      };

      setVoices(prevVoices => ({
        ...prevVoices,
        my: [...prevVoices.my, newVoice],
        // If public, also add to pro voices
        pro: newVoicePublic ? [...prevVoices.pro, newVoice] : prevVoices.pro
      }));

      setUploadedFiles([]);
      setNewVoiceName('');
      setNewVoiceCategory('Default');
      setNewVoiceCommand('');
      setNewVoicePublic(false);
      triggerAlert('Voice successfully created', 'createVoice');
    } catch (error) {
      console.error('Error creating voice:', error);
      const errorMessage = error.response && error.response.data && error.response.data.message 
        ? error.response.data.message 
        : 'Error creating voice';
      triggerAlert(errorMessage, 'createVoice', false);
    } finally {
      setSubmittingNewVoice(false);
    }
  };

  const sortVoices = (voices, sortType) => {
    return [...voices].sort((a, b) => {
      switch (sortType) {
        case 'nameAsc':
          return a.name.localeCompare(b.name);
        case 'nameDesc':
          return b.name.localeCompare(a.name);
        case 'createdAsc':
          return new Date(a.createdAt) - new Date(b.createdAt);
        case 'createdDesc':
          return new Date(b.createdAt) - new Date(a.createdAt);
        default:
          return 0;
      }
    });
  };

  const filteredProVoices = sortVoices(
    voices.pro.filter(voice => {
      const matchesSearch = voice.name.toLowerCase().includes(proVoiceSearch.toLowerCase());
      const matchesCategory = proVoiceCategory === 'all' || 
        (voice.icon && voice.icon.toLowerCase() === proVoiceCategory.toLowerCase());
      const matchesActivation = activationFilter === 'all' || 
        (activationFilter === 'active' && voice.active) ||
        (activationFilter === 'inactive' && !voice.active);
      return !voice.custom && voice.voiceTypeId == 12 && matchesSearch && matchesCategory && matchesActivation;
    }),
    proVoiceSort
  );

  const indexOfLastVoice = currentPage * voicesPerPage;
  const indexOfFirstVoice = indexOfLastVoice - voicesPerPage;
  const currentVoices = filteredProVoices.slice(indexOfFirstVoice, indexOfLastVoice);

  const pageCount = Math.max(1, Math.ceil(filteredProVoices.length / voicesPerPage));

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startEditing = (voice, listType) => {
    setEditingVoices(prev => ({
      ...prev,
      [listType]: voice
    }));
  };

  const cancelEditing = (listType) => {
    setEditingVoices(prev => ({
      ...prev,
      [listType]: null
    }));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggle = (name) => {
    const value = ttsSettings[name] ? false : true;
    setTtsSettings({
      ...ttsSettings,
      [name]: value,
    });
  };

  const activatedVoices = () => {
    // Create a Map to store unique voices by ID
    const uniqueVoices = new Map();
    
    // Helper function to add voices to the Map
    const addVoices = (voiceArray) => {
      voiceArray.forEach(voice => {
        if (voice.active) {
          uniqueVoices.set(voice.id, voice);
        }
      });
    };

    // Add voices from each category
    addVoices(voices.my);
    addVoices(voices.basic);
    addVoices(voices.pro);

    // Convert Map values back to array and sort by name
    return Array.from(uniqueVoices.values()).sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const allVoiceText = () => {
    return activatedVoices()
      .map(v => {
        const command = v.command || `!${v.name.toLowerCase().replace(/\s+/g, '')}`;
        return `${v.name} ${command}`;
      })
      .join('\n');
  };

  const handleVoiceEdit = async (voice) => {
    try {
      await axios.put(`/api/ttsmonster/voice/${voice.id}`, {
        name: voice.name,
        command: voice.command,
        category: voice.category,
        isPublic: voice.isPublic,
        permissions: voice.permissions
      });

      // Refresh the voices list
      const { data: updatedVoices } = await axios.get('/api/chat-tts-voices');
      
      // Show all owned voices in My Voices
      const myVoices = updatedVoices.filter(v => v.isOwner);
      // Show non-custom pro voices and ALL public voices
      const proVoices = updatedVoices.filter(v => 
        (v.voiceTypeId == 12 && !v.custom) || v.isPublic
      );

      setVoices(prev => ({
        ...prev,
        my: myVoices,
        pro: proVoices
      }));

      cancelEditing('my');
    } catch (error) {
      console.error('Error updating voice:', error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  const filteredCommunityVoices = sortVoices(
    voices.pro.filter(voice => {
      const matchesSearch = voice.name.toLowerCase().includes(communityVoiceSearch.toLowerCase());
      const matchesCategory = communityVoiceCategory === 'all' || 
        (voice.icon && voice.icon.toLowerCase() === communityVoiceCategory.toLowerCase());
      const matchesActivation = communityActivationFilter === 'all' || 
        (communityActivationFilter === 'active' && voice.active) ||
        (communityActivationFilter === 'inactive' && !voice.active);
      return voice.isPublic && matchesSearch && matchesCategory && matchesActivation;
    }),
    communityVoiceSort
  );

  const indexOfLastCommunityVoice = currentCommunityPage * voicesPerPage;
  const indexOfFirstCommunityVoice = indexOfLastCommunityVoice - voicesPerPage;
  const currentCommunityVoices = filteredCommunityVoices.slice(indexOfFirstCommunityVoice, indexOfLastCommunityVoice);

  const communityPageCount = Math.max(1, Math.ceil(filteredCommunityVoices.length / voicesPerPage));

  const handleCommunityPageChange = (event, value) => {
    setCurrentCommunityPage(value);
  };

  // Add new functions for bulk activation/deactivation
  const bulkToggleVoices = async (voices, activate, type) => {
    try {
      const voiceIds = voices.map(v => v.id);
      let isCustom = type === 'community' || type === 'my';
      await axios.post('/api/pro-voice/bulk-toggle', {
        voiceIds,
        activate,
        isCustom,
      });

      // Update local state
      setVoices(prev => ({
        ...prev,
        pro: prev.pro.map(voice => 
          voiceIds.includes(voice.id) ? { ...voice, active: activate } : voice
        ),
        my: prev.my.map(voice =>
          voiceIds.includes(voice.id) ? { ...voice, active: activate } : voice
        )
      }));

      // Update voice rules
      if (activate) {
        setVoiceRules(prev => [...prev, ...voices]);
      } else {
        setVoiceRules(prev => prev.filter(v => !!v.custom !== isCustom || !voiceIds.includes(v.id)));
      }
    } catch (error) {
      console.error('Error bulk toggling voices:', error);
      const errorMessage = error.response && error.response.data 
        ? error.response.data 
        : 'Error toggling voices';
      alert(errorMessage);
    }
  };

  // Add helper functions to check if all voices are disabled
  const areAllVoicesDisabled = (voices) => {
    return voices.every(v => !v.active || v.id === ttsChatsVoice.id);
  };

  const createVoiceDisabled = submittingNewVoice || newVoiceName == '' || !newVoiceName || newVoiceCommand == '' || !newVoiceCommand || commandErrorText || !uploadedFiles.length || !!dropError || !disclaimerAccepted;

  return (
    <ThemeProvider theme={customTheme}>
      <div className="powerchat-pro">
        <div className="active-voices">
          <h3>Activated Voices ({activatedVoices().length})</h3>
          <div style={{ marginBottom: 8 }}>
            <CopyButton 
              text={allVoiceText()}
              display="Copy All"
              size="1x"
            />
          </div>
          {activatedVoices().map(v => (
            <div key={v.id}>
              <b>{v.name}</b>
              <span className="command">
                &ensp;{v.command || `!${v.name.toLowerCase().replace(/\s+/g, '')}`}
              </span>
            </div>
          ))}
        </div>
        <div className="settings-and-create-container">
          <section className="tts-settings">
            <form className="form-container tts-settings-content" onSubmit={handleSubmit}>
              <h3>
                Live Chat TTS Settings
                &ensp;
                <a href="https://learn.powerchat.live/overlay-configuration/powerchat-pro" target="_blank" rel="noreferrer">
                  Learn More
                </a>
              </h3>
              <div className="chat-overlay-disclaimer">
                <div className="warning-text">
                  <FontAwesomeIcon icon={faCircleExclamation} />
                  <b>Chat Overlay must be enabled in your streaming software to function</b>
                </div>
                <div className="chat-overlay-link">
                  <CopyButton 
                    text={`${window.location.origin}/${user.username}/chat`}
                    display={`${window.location.origin}/${user.username}/chat`}
                    className="copy-btn"
                  />
                </div>
              </div>

              <div className="tts-settings-grid">
                {/* Volume Control */}
                <div className="settings-panel volume-panel">
                  <div className="panel-header">
                    <h4>Volume</h4>
                    <span className="value-display">{ttsSettings.ttsChatsVolume}%</span>
                  </div>
                  <Slider
                    value={ttsSettings.ttsChatsVolume}
                    onChange={(_, value) => handleTTSSettingChange('ttsChatsVolume', value)}
                    min={0}
                    max={100}
                    step={1}
                  />
                </div>

                {/* Mode Control */}
                <div className="settings-panel mode-panel">
                  <div className="panel-header">
                    <h4>TTS Mode</h4>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('ttsLimit')}
                      />
                      {tooltip === 'ttsLimit' && (
                        <p className="tooltip shadow-lg">
                          Off: TTS is disabled.<br/>
                          Flag: Only !tts flags are used (requires sub/mod permissions based on settings).<br/>
                          All: Every message is read with default TTS voice and allows subs and mods to use !tts flag.
                        </p>
                      )}
                    </div>
                  </div>
                  <Slider
                    sx={{ width: '100%', margin: '10px 0' }}
                    value={ttsSettings.ttsChatsMode}
                    onChange={(_, value) => handleTTSSettingChange('ttsChatsMode', value)}
                    step={1}
                    track={false}
                    marks={[
                      { value: 0, label: 'Off' },
                      { value: 1, label: 'Flag' },
                      { value: 2, label: 'All' },
                    ]}
                    min={0}
                    max={2}
                  />
                </div>

                {/* Permission Controls */}
                <div className="settings-panel permissions-panel">
                  <div className="panel-header">
                    <h4>Permissions</h4>
                  </div>
                  <div className="permission-switches">
                    <div className="permission-switch-container">
                      <ToggleSwitch
                        checked={ttsSettings.ttsChatsAll}
                        onChange={() => handleTTSSettingChange('ttsChatsAll', !ttsSettings.ttsChatsAll)}
                        disabled={ttsSettings.ttsChatsMode === 0}
                        label="All Chatters Allowed"
                      />
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('allChatters')}
                        />
                        {tooltip === 'allChatters' && (
                          <p className="tooltip shadow-lg">
                            When enabled, all chatters can use basic voice commands (!male, !female, !hans, !zhiyu)
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="permission-switch-container">
                      <ToggleSwitch
                        checked={ttsSettings.ttsChatsSubOnly}
                        onChange={() => handleTTSSettingChange('ttsChatsSubOnly', !ttsSettings.ttsChatsSubOnly)}
                        disabled={ttsSettings.ttsChatsMode === 0}
                        label="Subscribers Only"
                      />
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('subsOnly')}
                        />
                        {tooltip === 'subsOnly' && (
                          <p className="tooltip shadow-lg">
                            When enabled, subscribers can use all activated voice commands
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="permission-switch-container">
                      <ToggleSwitch
                        checked={ttsSettings.ttsChatsModOnly}
                        onChange={() => handleTTSSettingChange('ttsChatsModOnly', !ttsSettings.ttsChatsModOnly)}
                        disabled={ttsSettings.ttsChatsMode === 0}
                        label="Moderators Only"
                      />
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('modsOnly')}
                        />
                        {tooltip === 'modsOnly' && (
                          <p className="tooltip shadow-lg">
                            When enabled, moderators can use all activated voice commands
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Voice Selection */}
                <div className="settings-panel voice-panel">
                  <div className="panel-header">
                    <h4>Default Voice</h4>
                  </div>
                  <div className="voice-settings">
                    <BasicSelect
                      value={ttsSettings.voiceName}
                      onChange={(e) => handleTTSSettingChange('voiceName', e.target.value)}
                      options={DEFAULT_VOICES.map(voice => ({
                        value: voice,
                        label: voice
                      }))}
                      className="voice-select"
                      disabled={!ttsSettings.ttsChatsAll && !ttsSettings.ttsChatsSubOnly && !ttsSettings.ttsChatsModOnly}
                    />
                  </div>
                </div>

                {/* Additional Options */}
                <div className="settings-panel options-panel">
                  <div className="panel-header">
                    <h4>Additional Options</h4>
                  </div>
                  <div className="options-grid">
                    <div className="toggle-container">
                      <ToggleSwitch
                        name="chatReadSender"
                        onChange={() => handleToggle('chatReadSender')}
                        checked={ttsSettings.chatReadSender}
                      />
                      <label htmlFor="chatReadSender">Read Sender Name</label>
                    </div>

                    <div className="toggle-container">
                      <ToggleSwitch
                        name="hideChats"
                        onChange={() => handleToggle('hideChats')}
                        checked={ttsSettings.hideChats}
                      />
                      <label htmlFor="hideChats">Hide Chats</label>
                      <div className="icon-btn tooltip-wrapper">
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('hideChats')}
                        />
                        {tooltip === 'hideChats' && (
                          <p className="tooltip shadow-lg">
                            Chats will no longer display on overlay. TTS may still play.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="settings-footer">
                <button
                  type="submit"
                  className="primary-btn"
                  disabled={isLoading}
                >
                  Save
                </button>
                <FormAlert alertState={alertState.ttsSettings} margin={0} />
              </div>
            </form>
          </section>
          <section className="create-voice">
            <h3>
              <FontAwesomeIcon icon={faMicrophone} />
              Create Voice
              <a href="https://tts.monster" target="_blank" rel="noreferrer" className="logo">
                <img src="/static/img/powered-by-ttsmonster.webp" alt="TTS Monster Logo" />
              </a>
            </h3>
            
            {!ttsSettings.isProStreamer && (
              <div className="unlock-note">
                <FontAwesomeIcon icon={faCircleExclamation} color="var(--gold)" />&nbsp;
                You must <a href="/account-settings?tab=powerchat%20pro">subscribe</a> to Powerchat Pro Streamer to unlock voice cloning
              </div>
            )}

            <div 
              {...getRootProps()} 
              className={`dropzone ${isDragActive ? 'active' : ''} ${!ttsSettings.isProStreamer ? 'disabled' : ''}`}
            >
              <input {...getInputProps()} disabled={!ttsSettings.isProStreamer} />
              <div className="dropzone-content">
                <FontAwesomeIcon icon={faCloudUpload} className="dropzone-icon" />
                <p>Drag & drop an audio file here, or click to select</p>
                <small>Audio should be between 30 seconds and 5 minutes</small>
                <small>Supported formats: MP3, WAV (max {MAX_FILES} file)</small>
                {dropError && <div className="invalid-message">{dropError}</div>}
              </div>
            </div>

            {uploadedFiles.length > 0 && (
              <React.Fragment>
                <CustomAudioPlayer
                  src={uploadedFiles[0].preview}
                  title={uploadedFiles[0].file.name}
                  onDelete={() => setUploadedFiles([])}
                  onLoadedMetadata={() => {}}
                />
                
                <div className="new-voice-details">
                  <TextField
                    className="form-field"
                    label="Voice Name"
                    value={newVoiceName}
                    onChange={onNameChanged}
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                    size="small"
                    inputProps={{
                      autoComplete: "off"
                    }}
                  />

                  <TextField
                    className="form-field"
                    label="Command"
                    value={newVoiceCommand}
                    onChange={onCommandChanged}
                    error={!!commandErrorText}
                    helperText={commandErrorText}
                    size="small"
                    inputProps={{
                      autoComplete: "off"
                    }}
                  />

                  <div className="voice-category">
                    <BasicSelect
                      value={newVoiceCategory}
                      onChange={(e) => setNewVoiceCategory(e.target.value)}
                      options={Object.entries(voiceCategories).map(([category, data]) => ({
                        value: category,
                        label: (
                          <div className="category-menu-item">
                            <FontAwesomeIcon icon={data.icon} style={{ color: data.color }} />
                            <span>{category}</span>
                          </div>
                        )
                      }))}
                      className="category-field"
                    />
                  </div>

                  <div className="privacy-toggle">
                    <ToggleSwitch
                      checked={newVoicePublic}
                      onChange={(e) => setNewVoicePublic(e.target.checked)}
                      label="Public Voice"
                    />
                  </div>
                </div>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={disclaimerAccepted}
                      onChange={(e) => setDisclaimerAccepted(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <span style={{ fontSize: '0.8rem', color: '#a0a0a0' }}>
                      I certify that I possess the necessary rights to upload and clone this voice and agree not to use the content for any unlawful or harmful activities.
                    </span>
                  }
                  className="disclaimer-checkbox"
                />

                <Button
                  className="create-voice-btn"
                  onClick={createVoice}
                  disabled={createVoiceDisabled}
                >
                  {submittingNewVoice ? (
                    <div className="loading-wrapper">
                      <BarLoader 
                        color="#57bad8"
                        height={2}
                        className="bar-loader"
                      />
                    </div>
                  ) : (
                    'Create Voice'
                  )}
                </Button>
              </React.Fragment>
            )}
          </section>
        </div>

      {ttsSettings.isProStreamer && (
        <section className="my-voices">
          <h3>My Voices ({voices.my.length}/5)</h3>
        {voices.my.length > 0 && (
          <div className={`voice-grid my-voices-grid ${isMobile ? 'mobile' : ''}`}>
            {voices.my.map((voice) => (
              <VoiceCard
                key={`my-${voice.id}`}
                voice={voice}
                type="my"
                listId="my-voices"
                onActivate={() => activateVoice(voice.id, 'my')}
                onDeactivate={() => deactivateVoice(voice.id, 'my')}
                canDeactivate={voice.id != ttsChatsVoice.id}
                onRemove={() => handleDeleteOpen(voice)}
                onEdit={() => startEditing(voice, 'my')}
                isEditing={editingVoices.my && editingVoices.my.id === voice.id}
                onCancel={() => cancelEditing('my')}
                onSave={handleVoiceEdit}
                eventEmitter={eventEmitter}
                voices={voices}
              />
            ))}
          </div>
        )}
          <FormAlert alertState={alertState.myVoices} width={400} />
        </section>
      )}

        <section className="basic-voices">
          <h3>Basic Voices</h3>
          <div className={`voice-grid ${isMobile ? 'mobile' : ''}`}>
            {voices.basic.map((voice) => (
              <VoiceCard
                key={`basic-${voice.id}`}
                voice={voice}
                type="basic"
                listId="basic-voices"
                onActivate={() => activateVoice(voice.id, 'basic')}
                onDeactivate={() => deactivateVoice(voice.id, 'basic')}
                canDeactivate={voice.id != ttsChatsVoice.id}
                onEdit={() => startEditing(voice, 'basic')}
                isEditing={editingVoices.basic && editingVoices.basic.id === voice.id}
                onCancel={() => cancelEditing('basic')}
                eventEmitter={eventEmitter}
              />
            ))}
          </div>
        </section>

        <section className="pro-voices">
          <h3>Pro Voices
            <a href="https://tts.monster" target="_blank" rel="noreferrer" className="logo">
              <img src="/static/img/powered-by-ttsmonster.webp" alt="TTS Monster Logo" />
            </a>
          </h3>
          <div className={`pro-voices-controls ${isMobile ? 'mobile' : ''}`}>
            <div className="search-container">
              <FontAwesomeIcon icon={faSearch} className="search-icon" />
              <input
                type="text"
                className="search-input"
                placeholder="Search voices"
                value={proVoiceSearch}
                onChange={(e) => setProVoiceSearch(e.target.value)}
              />
            </div>
            <BasicSelect
              value={proVoiceCategory}
              onChange={(e) => setProVoiceCategory(e.target.value)}
              options={[
                { value: 'all', label: (
                  <div className="category-menu-item">
                    <FontAwesomeIcon icon={faFilter} style={{ color: '#57bad8' }} />
                    <span>All Categories</span>
                  </div>
                )},
                ...Object.entries(voiceCategories).map(([category, data]) => ({
                  value: category,
                  label: (
                    <div className="category-menu-item">
                      <FontAwesomeIcon icon={data.icon} style={{ color: data.color }} />
                      <span>{category}</span>
                    </div>
                  )
                }))
              ]}
              className="pro-voice-category-select"
            />
            <BasicSelect
              value={activationFilter}
              onChange={(e) => setActivationFilter(e.target.value)}
              options={[
                { value: 'all', label: (
                  <div className="filter-menu-item">
                    <FontAwesomeIcon icon={faFilter} className="icon" />
                    <span>All Voices</span>
                  </div>
                )},
                { value: 'active', label: (
                  <div className="filter-menu-item">
                    <FontAwesomeIcon icon={faCheck} style={{ color: '#4caf50' }} />
                    <span>Active</span>
                  </div>
                )},
                { value: 'inactive', label: (
                  <div className="filter-menu-item">
                    <FontAwesomeIcon icon={faTimes} style={{ color: '#f44336' }} />
                    <span>Inactive</span>
                  </div>
                )}
              ]}
              className="filter-select"
            />
            <BasicSelect
              value={proVoiceSort}
              onChange={(e) => setProVoiceSort(e.target.value)}
              options={[
                { value: 'nameAsc', label: (
                  <div className="filter-menu-item">
                    <FontAwesomeIcon icon={faArrowDownAZ} />
                    <span>Name (A-Z)</span>
                  </div>
                )},
                { value: 'nameDesc', label: (
                  <div className="filter-menu-item">
                    <FontAwesomeIcon icon={faArrowUpAZ} />
                    <span>Name (Z-A)</span>
                  </div>
                )},
                { value: 'usageAsc', label: (
                  <div className="filter-menu-item">
                    <FontAwesomeIcon icon={faArrowDownShortWide} />
                    <span>Usage (Low-High)</span>
                  </div>
                )},
                { value: 'usageDesc', label: (
                  <div className="filter-menu-item">
                    <FontAwesomeIcon icon={faArrowUpShortWide} />
                    <span>Usage (High-Low)</span>
                  </div>
                )}
              ]}
              className="filter-select sort-select"
            />
            <div className="bulk-action-buttons">
              <Button
                className="enable-all-btn"
                variant="outlined"
                size="small"
                onClick={() => bulkToggleVoices(
                  filteredProVoices.filter(v => !v.active && v.id !== ttsChatsVoice.id),
                  true,
                  'pro'
                )}
                disabled={!ttsSettings.isProStreamer}
                startIcon={<FontAwesomeIcon icon={faCheck} />}
              >
                Activate All
              </Button>
              <Button
                className="disable-all-btn"
                variant="outlined"
                size="small"
                onClick={() => bulkToggleVoices(
                  filteredProVoices.filter(v => v.active && v.id !== ttsChatsVoice.id),
                  false,
                  'pro'
                )}
                disabled={areAllVoicesDisabled(filteredProVoices)}
                startIcon={<FontAwesomeIcon icon={faTimes} />}
              >
                Deactivate All
              </Button>
            </div>
          </div>
          <div className={`voice-grid pro-voices-grid ${isMobile ? 'mobile' : ''}`}>
            {currentVoices
              .filter(voice => !voice.custom && voice.voiceTypeId == 12)
              .map((voice) => (
                <VoiceCard
                  key={`pro-${voice.id}`}
                  voice={voice}
                  type="pro"
                  listId="pro-voices"
                  disabled={!ttsSettings.isProStreamer}
                  onActivate={() => activateVoice(voice.id, 'pro')}
                  onDeactivate={() => deactivateVoice(voice.id, 'pro')}
                  canDeactivate={voice.id != ttsChatsVoice.id}
                  onEdit={() => startEditing(voice, 'pro')}
                  isEditing={editingVoices.pro && editingVoices.pro.id === voice.id}
                  onCancel={() => cancelEditing('pro')}
                  eventEmitter={eventEmitter}
                />
              ))}
          </div>
          <div className={`pagination-container ${isMobile ? 'mobile' : ''}`}>
            <Pagination 
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              size={isMobile ? "medium" : "large"}
              showFirstButton
              showLastButton
              siblingCount={isMobile ? 0 : 1}
              boundaryCount={1}
              shape="rounded"
            />
          </div>
          <div className="community-voices">
            <h3>
              Community Voices
              <a href="https://tts.monster" target="_blank" rel="noreferrer" className="logo">
                <img src="/static/img/powered-by-ttsmonster.webp" alt="TTS Monster Logo" />
              </a>
            </h3>
            <div className={`pro-voices-controls ${isMobile ? 'mobile' : ''}`}>
              <div className="search-container">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search voices"
                  value={communityVoiceSearch}
                  onChange={(e) => setCommunityVoiceSearch(e.target.value)}
                />
              </div>
              <BasicSelect
                value={communityVoiceCategory}
                onChange={(e) => setCommunityVoiceCategory(e.target.value)}
                options={[
                  { value: 'all', label: (
                    <div className="category-menu-item">
                      <FontAwesomeIcon icon={faFilter} style={{ color: '#57bad8' }} />
                      <span>All Categories</span>
                    </div>
                  )},
                  ...Object.entries(voiceCategories).map(([category, data]) => ({
                    value: category,
                    label: (
                      <div className="category-menu-item">
                        <FontAwesomeIcon icon={data.icon} style={{ color: data.color }} />
                        <span>{category}</span>
                      </div>
                    )
                  }))
                ]}
                className="pro-voice-category-select"
              />
              <BasicSelect
                value={communityActivationFilter}
                onChange={(e) => setCommunityActivationFilter(e.target.value)}
                options={[
                  { value: 'all', label: (
                    <div className="filter-menu-item">
                      <FontAwesomeIcon icon={faFilter} style={{ color: '#57bad8' }} />
                      <span>All Voices</span>
                    </div>
                  )},
                  { value: 'active', label: (
                    <div className="filter-menu-item">
                      <FontAwesomeIcon icon={faCheck} style={{ color: '#4caf50' }} />
                      <span>Active</span>
                    </div>
                  )},
                  { value: 'inactive', label: (
                    <div className="filter-menu-item">
                      <FontAwesomeIcon icon={faTimes} style={{ color: '#f44336' }} />
                      <span>Inactive</span>
                    </div>
                  )}
                ]}
                className="filter-select"
              />
              <BasicSelect
                value={communityVoiceSort}
                onChange={(e) => setCommunityVoiceSort(e.target.value)}
                options={[
                  { value: 'nameAsc', label: (
                    <div className="filter-menu-item">
                      <FontAwesomeIcon icon={faArrowDownAZ} />
                      <span>Name (A-Z)</span>
                    </div>
                  )},
                  { value: 'nameDesc', label: (
                    <div className="filter-menu-item">
                      <FontAwesomeIcon icon={faArrowUpAZ} />
                      <span>Name (Z-A)</span>
                    </div>
                  )},
                  { value: 'createdDesc', label: (
                    <div className="filter-menu-item">
                      <FontAwesomeIcon icon={faArrowUpShortWide} />
                      <span>Newest First</span>
                    </div>
                  )},
                  { value: 'createdAsc', label: (
                    <div className="filter-menu-item">
                      <FontAwesomeIcon icon={faArrowDownShortWide} />
                      <span>Oldest First</span>
                    </div>
                  )}
                ]}
                className="filter-select sort-select"
              />
              <div className="bulk-action-buttons">
                <Button
                  className="enable-all-btn"
                  variant="outlined"
                  size="small"
                  onClick={() => bulkToggleVoices(
                    filteredCommunityVoices.filter(v => !v.active && v.id !== ttsChatsVoice.id),
                    true,
                    'community'
                  )}
                  disabled={!ttsSettings.isProStreamer}
                  startIcon={<FontAwesomeIcon icon={faCheck} />}
                >
                  Activate All
                </Button>
                <Button
                  className="disable-all-btn"
                  variant="outlined"
                  size="small"
                  onClick={() => bulkToggleVoices(
                    filteredCommunityVoices.filter(v => v.active && v.id !== ttsChatsVoice.id),
                    false,
                    'community'
                  )}
                  disabled={areAllVoicesDisabled(filteredCommunityVoices)}
                  startIcon={<FontAwesomeIcon icon={faTimes} />}
                >
                  Deactivate All
                </Button>
              </div>
            </div>
            <div className={`voice-grid pro-voices-grid ${isMobile ? 'mobile' : ''}`}>
              {currentCommunityVoices.map((voice) => (
                <VoiceCard
                  key={`community-${voice.id}`}
                  voice={voice}
                  type="community"
                  listId="community-voices"
                  disabled={!ttsSettings.isProStreamer || voice.isOwner}
                  onActivate={() => activateVoice(voice.id, 'community')}
                  onDeactivate={() => deactivateVoice(voice.id, 'community')}
                  canDeactivate={voice.id != ttsChatsVoice.id}
                  eventEmitter={eventEmitter}
                  isOwnVoice={voice.isOwner}
                  ttsSettings={ttsSettings}
                />
              ))}
            </div>
            <div className={`pagination-container ${isMobile ? 'mobile' : ''}`}>
              <Pagination
                count={communityPageCount}
                page={currentCommunityPage}
                onChange={handleCommunityPageChange}
                color="primary"
                size={isMobile ? "medium" : "large"}
                showFirstButton
                showLastButton
                siblingCount={isMobile ? 0 : 1}
                boundaryCount={1}
                shape="rounded"
              />
            </div>
          </div>
        </section>
      </div>

      <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteClose}
        disableScrollLock={true}
        PaperProps={{
          style: {
            backgroundColor: '#2b2d31',
            color: '#ffffff'
          }
        }}
      >
        <DialogTitle>
          Delete Voice
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#ffffff' }}>
            Are you sure you want to delete {deleteDialog.voice && deleteDialog.voice.name}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleDeleteClose}
            sx={{ color: '#ffffff' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={removeVoice}
            color="error"
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={warningDialog.open}
        onClose={() => setWarningDialog({ ...warningDialog, open: false })}
        disableScrollLock={true}
        PaperProps={{
          style: {
            backgroundColor: '#2b2d31',
            color: '#ffffff'
          }
        }}
      >
        <DialogTitle>
          Permission Required
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#ffffff' }}>
            At least one permission (Subscribers or Moderators) must be enabled when TTS mode is set to Flag or All.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setWarningDialog({ ...warningDialog, open: false })}
            sx={{ color: '#57bad8' }}
            autoFocus
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default PowerchatPro;
