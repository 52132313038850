import React, { useState, useEffect, useRef } from 'react';
const axios = require('axios');
const crypto = require('crypto');
import NmiPayment from '../components/nmi';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowRight, faArrowLeft, faTimes } from '@fortawesome/free-solid-svg-icons';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ClipLoader from 'react-spinners/ClipLoader';
import '../css/pro-subscribe.css';

const PowerchatPro = ({ triggerAlert }) => {
  const [formData, setFormData] = useState({});
  const formDataRef = useRef({});
  const [tooltip, setTooltip] = useState('');
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [brand, setBrand] = useState('');
  const [last4, setLast4] = useState('****');
  const [subscribedPlanId, setSubscribedPlanId] = useState(null);
  const subscribedPlanIdRef = useRef(null);
  const [subDialogOpen, setSubDialogOpen] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fieldsLoaded, setFieldsLoaded] = useState(false);
  const [selectedInterval, setSelectedInterval] = useState('monthly');
  const [manageScreen, setManageScreen] = useState(false);
  const [payScreen, setPayScreen] = useState(true);
  const [downgrading, setDowngrading] = useState(false);
  const [updatingSub, setUpdatingSub] = useState(false);
  const updatingRef = useRef(false);
  const [plans, setPlans] = useState([]);
  const planRef = useRef(null);
  const [futurePlanId, setFuturePlanId] = useState(null);
  const [planAmount, setPlanAmount] = useState(0);
  const [nextChargeDate, setNextChargeDate] = useState(null);
  const [renewedDate, setRenewedDate] = useState(null);
  const subscriptionIdRef = useRef(null);
  const domLoaded = useRef(false);
  const [showCreditForm, setShowCreditForm] = useState(false);
  const sessionId = useRef(null);

  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  useEffect(() => {
    updatingRef.current = updatingSub;
  }, [updatingSub]);

  useEffect(() => {
    subscribedPlanIdRef.current = subscribedPlanId;
  }, [subscribedPlanId]);

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.composedPath ||
        !event.composedPath().find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const getSessionId = () => {
    return crypto.randomBytes(16).toString('hex').toUpperCase();
  };

  useEffect(() => {
    if (!subDialogOpen || !payScreen || downgrading) return;

    let price = getPrice(plans, selectedInterval);
    let existingScript = document.getElementById('nmipay');
    if (existingScript) {
      existingScript.remove();
      existingScript = null;
      domLoaded.current = false;
      setFieldsLoaded(false);
    }
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://secure.nmi.com/token/Collect.js';
      script.setAttribute('data-tokenization-key', process.env.NMI_TOKENIZATION_KEY);
      
      script.id = 'nmipay';
      document.body.appendChild(script);
      script.onload = () => {
        sessionId.current = getSessionId();
        if (!window.CollectJS) {
          console.error('Could not load Collect.js');
        }

        let ccnumber = document.getElementById('CollectJSInlineccnumber');
        if (ccnumber) ccnumber.remove();
        let ccexp = document.getElementById('CollectJSInlineccexp');
        if (ccexp) ccexp.remove();
        let cvv = document.getElementById('CollectJSInlinecvv');
        if (cvv) cvv.remove();

        let config = {
          variant: 'inline',
          callback: proSubscribe,
          country: 'US',
          currency: 'USD',
          price: price.toString(),
          paymentType: 'cc',
          fieldsAvailableCallback: () => setFieldsLoaded(true),
          styleSniffer: false,
          timeoutDuration: 10000,
          timeoutCallback: function () {
            alert("The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity");
            setLoadingPayment(false);
          },
          customCss: {
            'font-family': 'Arial',
            'font-size': '15px',
            'height': '31px',
          },
          invalidCss: {
            'border-color': 'red'
          },
          fields: {
            ccnumber: {
              enableCardBrandPreviews: true,
              selector: '#ccnumber',
              placeholder: 'Card Number',
            },
            ccexp: {
              selector: '#ccexp',
              placeholder: 'MM / YY',
            },
            cvv: {
              display: 'required',
              selector: '#cvv',
              placeholder: 'CVV',
            },
            googlePay: {
              selector: '#google-pay-button',
              billingAddressRequired: true,
              billingAddressParameters: {
                format: "FULL"
              },
            }
          }
        };
        window.CollectJS.configure(config);
        if (!domLoaded.current) {
          document.dispatchEvent(new Event('DOMContentLoaded'));
          domLoaded.current = true;
        }
      };
    }
  }, [subDialogOpen, payScreen, selectedInterval, plans]);

  const getSettings = async () => {
    let response = await axios.get('/api/powerchat-pro');
    setRecurring(response.data.recurring);
    setPlanAmount(response.data.amount);
    if (response.data.nextChargeDate) {
      setNextChargeDate(new Date(response.data.nextChargeDate).toLocaleDateString());
    }
    if (response.data.renewedDate) {
      setRenewedDate(new Date(response.data.renewedDate).toLocaleDateString());
    }
    subscriptionIdRef.current = response.data.externalId;
    setSubscribedPlanId(response.data.planId);
    setFuturePlanId(response.data.futurePlanId);
    setBrand(response.data.brand);
    setLast4(response.data.last4);
  };

  useEffect(() => {
    setLoadingSettings(true);
    getSettings().finally(() => setLoadingSettings(false));
  }, []);

  //Cannot be async - fails to capture response parameter
  const proSubscribe = (response) => {
    try {
      console.info(response);
      setLoadingPayment(false);
      setSubDialogOpen(false);
      response['payment_token'] = response.token;
      let usedGoogle = !!response.initiatedBy && response.initiatedBy.srcElement.parentElement.id === 'google-pay-button';
      let body = {
        planId: planRef.current,
        payment_token: response.token,
        //payment_token: '00000000-000000-000000-000000000000', //todo remove
        transaction_session_id: sessionId.current,
      }
      if (usedGoogle) {
        let addressDetails = { ...response.wallet.billingInfo };
        Object.assign(body, addressDetails);
      }
      else {
        Object.assign(body, formDataRef.current);
      }

      setSubmitting(true);
      if (!!subscribedPlanIdRef.current && planRef.current != subscribedPlanIdRef.current) {
        body.subscription_id = subscriptionIdRef.current;
        axios.post('/api/powerchat-pro/upgrade', body).then(response => {
          triggerAlert('Subscription upgrade successful');
          setRecurring(true);

          subscriptionIdRef.current = response.data.externalId;
          setSubscribedPlanId(response.data.planId);
          setFuturePlanId(null);
          setNextChargeDate(response.data.nextChargeDate);
          setTimeout(getSettings, 10000);
          setTimeout(getSettings, 15000);
        }).catch(err => {
          console.error(err);
          let message = 'Error occurred while upgrading subscription';
          if (err.data) {
            message = err.data;
          }
          triggerAlert(message, false);
        }).finally(() => setSubmitting(false));
        return;
      }
      if (updatingRef.current == true) {
        body.subscription_id = subscriptionIdRef.current;
        axios.post('/api/powerchat-pro/update', body).then(() => {
          triggerAlert('Payment information updated');
        }).catch(err => {
          console.error(err);
          let message = 'Error occurred while updating subscription';
          if (err.data) {
            message = err.data;
          }
          triggerAlert(message, false);
        }).finally(() => setSubmitting(false));
        return;
      }
      axios.post('/api/powerchat-pro', body).then(response => {
        triggerAlert('Subscription payment successful');

        setRecurring(true);
        subscriptionIdRef.current = response.data.externalId;
        setSubscribedPlanId(response.data.planId);
        setNextChargeDate(response.data.nextChargeDate);
        setTimeout(getSettings, 10000);
        setTimeout(getSettings, 15000);
      }).catch(err => {
        console.error(err);
        let message = 'Error occurred while subscribing to Powerchat Pro';
        if (err.data) {
          message = err.data;
        }
        triggerAlert(message, false);
      }).finally(() => setSubmitting(false));
    } catch (err) {
      console.error(err);
      triggerAlert(err, false);
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const openUpdate = (planIds) => {
    setUpdatingSub(true);
    openSubscribe(planIds, true);
  };

  const restartSubscription = async () => {
    try {
      setSubmitting(true);
      await axios.post('/api/powerchat-pro/pause', {
        subscriptionId: subscriptionIdRef.current,
        pause: false,
      });
      triggerAlert('Subscription restarted');
      await getSettings();
    } catch (err) {
      console.error(err);
      triggerAlert('Could not restart subscription', false);
    } finally {
      setSubmitting(false);
    }
  };

  const cancelSubscription = async () => {
    if (!confirm('Are you sure you want to cancel your Powerchat Pro subscription?')) {
      return;
    }
    setSubDialogOpen(false);
    try {
      await axios.post('/api/powerchat-pro/pause', {
        subscriptionId: subscriptionIdRef.current,
        pause: true,
      });
      triggerAlert('Subscription canceled');
      await getSettings();
    } catch (err) {
      console.error(err);
      triggerAlert('Could not cancel subscription', false);
    }
  };

  const changePlanInterval = async () => {
    let interval = monthsMap[subscribedPlanIdRef.current] == 1 ? 'yearly' : 'monthly';
    if (!confirm(`Change to a ${interval} subscription?`)) {
      return;
    }
    let newPlan = subscribedPlanIdRef.current % 2 == 1
      ? subscribedPlanIdRef.current + 1
      : subscribedPlanIdRef.current - 1;
    try {
      setSubDialogOpen(false);
      await axios.post('/api/powerchat-pro/change-interval', {
        planId: newPlan,
      });
      triggerAlert('Subscription interval changed');
      await getSettings();
    } catch (err) {
      console.error(err);
      triggerAlert('Could not change subscription interval', false);
    }
  };

  const downgradeSub = async () => {
    try {
      setSubDialogOpen(false);
      setSubmitting(true);
      await axios.post('/api/powerchat-pro/downgrade', {
        planId: planRef.current,
      });
      setFuturePlanId(planRef.current);
      triggerAlert('Subscription set to downgrade');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not downgrade subscription', false);
    } finally {
      setSubmitting(false);
    }
  };

  const cancelDowngrade = async () => {
    if (!confirm('Cancel downgrade and resume your current subscription?')) {
      return;
    }
    try {
      await axios.post('/api/powerchat-pro/downgrade-cancel');
      setFuturePlanId(null);
      triggerAlert('Cancelled subscription downgrade');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not cancel subscription downgrade', false);
    }
  }

  const openDowngrade = (planIds) => {
    setDowngrading(true);
    openSubscribe(planIds);
  };

  const openSubscribe = (planIds, manage = false) => {
    setSelectedInterval('monthly');
    setShowCreditForm(false);
    setFormData({});
    setSubDialogOpen(true);
    setLoadingPayment(false);
    setManageScreen(manage);
    setPayScreen(!manage);
    setPlans(planIds);
  };

  const handleSubDialogClose = (_, reason) => {
    if (reason === 'backdropClick') return;
    if (reason === 'escapeKeyDown' && tooltip != '') return;
    setSubDialogOpen(false);
    setTimeout(() => {
      setUpdatingSub(false);
      setFieldsLoaded(false);
      setManageScreen(false);
      setPayScreen(true);
      setDowngrading(false);
    }, 500);
  };

  const changeInterval = (event) => {
    setSelectedInterval(event.target.name);
  };

  const viewerPlans = [1, 2];
  const streamerPlans = [3, 4];
  const tiers = [
    {
      title: 'Free',
      pricing: (<br/>),
      features: [
        'Basic Live Chat TTS Voices',
        'Basic Powerchat TTS Voices',
      ],
      planIds: [],
    },
    {
      title: 'Pro Viewer',
      pricing: (<span><b>$4.99</b>/mo or <b>$49.99</b>/yr</span>),
      features: [
        'All Basic Features',
        'Premium Powerchat AI TTS',
        ['Powerchat Pro Badge (Blue)',
        (
          <div key="pro-badge-icon" className="tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('viewerBadges')}
            />
            {tooltip === 'viewerBadges' && (
              <p className="tooltip shadow-lg">
                Streaming platforms must be linked in&nbsp;
                <a href="/creator-dashboard?tab=external-integrations">External Integrations</a>
                &nbsp;for chats to have badges.
              </p>
            )}
          </div>
        )],
        'Custom Name Colors',
      ],
      planIds: viewerPlans,
    },
    {
      title: 'Pro Streamer',
      pricing: (<span><b>$9.99</b>/mo or <b>$99.99</b>/yr</span>),
      features: [
        'All Pro Viewer Features',
        'Premium Live Chat AI TTS (Subs)',
        ['Powerchat Pro Badge (Gold)',
        (
          <div key="pro-badge-icon" className="tooltip-wrapper">
            <FontAwesomeIcon
              icon={faInfoCircle}
              color="#57bad8"
              onClick={() => toggleTooltip('streamerBadges')}
            />
            {tooltip === 'streamerBadges' && (
              <p className="tooltip shadow-lg">
                Streaming platforms must be linked in&nbsp;
                <a href="/creator-dashboard?tab=external-integrations">External Integrations</a>
                &nbsp;for your chats to have badges.
              </p>
            )}
          </div>
        )],
        '5 Custom AI TTS Voices',
      ],
      planIds: streamerPlans,
    },
  ];

  const priceMap = {
    1: 4.99,
    2: 49.99,
    3: 9.99,
    4: 99.99,
  };

  const monthsMap = {
    1: 1,
    2: 12,
    3: 1,
    4: 12,
  };

  const googleLoaderCss = {
    position: 'absolute',
    bottom: '25%',
    left: '40%',
  };

  const formLoaderCss = {
    position: 'absolute',
    top: '25%',
    left: '40%',
  };

  const submittingCss = {
    alignSelf: 'center',
    marginTop: 50,
  };

  const payLoaderCss = {
    gridRowStart: 8,
    gridColumnEnd: 'span 4',
    justifySelf: 'center',
    marginTop: 5,
  };

  const getPrice = (plans, interval) => interval == 'yearly' ? priceMap[plans[1]] : priceMap[plans[0]];

  const prorateDiscount = (subscribedPlanId, nextChargeDate, renewedDate) => {
    let amount = priceMap[subscribedPlanId];
    let daysTillEnd = Math.round((new Date(nextChargeDate) - new Date()) / 86400000);
    let daysFromStart = Math.round((new Date() - new Date(renewedDate)) / 86400000);
    return (amount * daysFromStart / (daysFromStart + daysTillEnd)).toFixed(2);
  }

  useEffect(() => {
    planRef.current = selectedInterval == 'yearly' ? plans[1] : plans[0];
  }, [plans, selectedInterval]);

  const fieldVisible = () => !showCreditForm ? 'collapse' :
    fieldsLoaded ? 'visible' : 'hidden';

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoadingPayment(true);
    window.CollectJS.startPaymentRequest(event);
  };

  const notNullOrEmpty = (field) => !!field && field.trim() != '';
  const formComplete = () => notNullOrEmpty(formData.first_name)
    && notNullOrEmpty(formData.first_name)
    && notNullOrEmpty(formData.last_name)
    && notNullOrEmpty(formData.address1)
    && notNullOrEmpty(formData.city)
    && notNullOrEmpty(formData.state)
    && notNullOrEmpty(formData.zip);

  return (
    <div className="pro-subscribe form-container">
      <div className="tiers">
      {tiers.map(tier => (
        <div key={tier.title}>
        {tier.planIds.includes(subscribedPlanId) ? (
          <div className="flex-column">
            <span className="current">Current Plan</span>
            <span className="renew">
              {nextChargeDate ? (!futurePlanId && recurring ? 'Renews on ' : 'Expires on ') + nextChargeDate
                : 'loading renew date..'
              }
            </span>
          </div>
        ) : tier.planIds.includes(futurePlanId) ? (
          <div className="flex-column">
            <br/>
            <span className="renew">Begins on {nextChargeDate}</span>
          </div>
        ) : (
          <div><br/><br/></div>
        )}
          <div className={"pro-section " + (tier.planIds.includes(subscribedPlanId) ? 'active' : '')}>
            <div>
              <div className="title-card">
                <div className="title">
                  <h3>{tier.title}</h3>
                  <div className="pricing">{tier.pricing}</div>
                </div>
                <div className="skew" />
              </div>
              <ul>
              {tier.features.map((feat, i) => (
                <li key={tier.title + i} className="feature">
                  {feat}
                </li>
              ))}
              </ul>
            {tier.title != 'Free' && (
              <div className="learn">
                <a href="https://learn.powerchat.live/account-management/powerchat-pro"
                  target="_blank" rel="noreferrer">
                  Learn More
                </a>
              </div>
            )}
            </div>
          {loadingSettings || tier.planIds.length == 0 ? (<div/>) : !tier.planIds.includes(subscribedPlanId) ? (
            <div className="sub-btn">
            {(!futurePlanId && subscribedPlanId && subscribedPlanId > tier.planIds[1]) ? (
              <button type="button" className="primary-btn" onClick={() => openDowngrade(tier.planIds)}>
                Downgrade
              </button>
            ) : (!subscribedPlanId || subscribedPlanId < tier.planIds[0]) ? (
              <button type="button" className="primary-btn" onClick={() => openSubscribe(tier.planIds)}>
                {!subscribedPlanId ? 'Subscribe' : 'Upgrade'}
              </button>
            ) : (
              <div/>
            )}
            </div>
          ) : !!futurePlanId && tier.planIds.includes(subscribedPlanId) ? (
            <div className="sub-btn">
              <button type="button" className="primary-btn" onClick={cancelDowngrade}>
                Resume
              </button>
            </div>
          ) : recurring ? (
            <div className="sub-btn">
              <button type="button" className="primary-btn" onClick={() => openUpdate(tier.planIds)}>
                Manage
              </button>
            </div>
          ) : (
            <div className="sub-btn">
              <button type="button" className="primary-btn" onClick={restartSubscription}>
                Resume
              </button>
            </div>
          )}
          </div>
        </div>
      ))}
      </div>
      <Dialog open={subDialogOpen}
        onClose={handleSubDialogClose}
        classes={{ paper: 'pro-sub-dialog ' + (downgrading ? 'downgrade' : '') }}
      >
        <DialogTitle>
          <div className="dialog-title">
            {updatingSub ? 'Manage Subscription' :
              (plans && plans[0] === 1 ? 'Powerchat Pro Viewer' : 'Powerchat Pro Streamer')
            }
            <IconButton
              aria-label="close"
              onClick={handleSubDialogClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="dialog-container">
            <div>
              <div>
              {manageScreen && (
                <div className="manage-sub">
                  <span>
                    Payment Method:&emsp;
                    <b>{brand} **{last4}</b>
                  </span>
                  <span>
                    Amount:&emsp;
                    <b>${planAmount.toFixed(2)}</b>
                  </span>
                  <span>
                    Next Payment:&emsp;
                    <b>{nextChargeDate}</b>
                  </span>
                  <span>
                    Billing cycle:&emsp;
                    <b>{monthsMap[subscribedPlanId] == 1 ? 'Monthly' : 'Yearly'}</b>
                  </span>
                  {!payScreen && (
                    <div className="flex-column">
                      <button className="primary-btn" onClick={() => setPayScreen(true)}>
                        Change Payment Card
                      </button>
                      <div className="tooltip-wrapper">
                        <button className="primary-btn" onClick={changePlanInterval}>
                          Switch to {monthsMap[subscribedPlanId] == 1 ? 'Yearly' : 'Monthly'}
                        </button>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          color="#57bad8"
                          onClick={() => toggleTooltip('switchInterval')}
                        />
                      {tooltip === 'switchInterval' && (
                        <p className="tooltip shadow-lg">
                          You will be billed for the annual amount on the next charge date
                        </p>
                      )}

                      </div>
                      <button className="secondary-btn" onClick={cancelSubscription}>
                        Cancel Subscription
                      </button>
                    </div>
                  )}
                </div>
              )}
              {payScreen && (
                <div className="google-form">
                {!updatingSub && (
                  <div className="frequency-tabs">
                    <button name="monthly" onClick={changeInterval}
                      className={"secondary-btn " + (selectedInterval == 'monthly' ? 'active' : '')}>
                      Monthly
                    </button>
                    <button name="yearly" onClick={changeInterval}
                      className={"secondary-btn " + (selectedInterval == 'yearly' ? 'active' : '')}>
                      Yearly
                    </button>
                  </div>
                )}
                {!updatingSub && (
                  <div className="price">Amount: <b>&#36;{getPrice(plans, selectedInterval)}</b></div>
                )}
                {!downgrading && !updatingSub && !!subscribedPlanId &&
                    prorateDiscount(subscribedPlanId, nextChargeDate, renewedDate) > 0 && (
                  <div className="note">
                    Your initial payment will be prorated
                    &#36;{getPrice(plans, selectedInterval) -
                      prorateDiscount(subscribedPlanId, nextChargeDate, renewedDate)}
                  </div>
                )}
                {downgrading && (
                  <div className="note downgrade">
                    The downgraded plan will begin on {nextChargeDate}<br/>
                    Your current plan will remain active until then
                  </div>
                )}
                {downgrading && (
                  <button className="downgrade primary-btn" onClick={downgradeSub}>
                    Submit
                  </button>
                )}
                  <div className="payment" style={{ minHeight: 70, width: 250 }}>
                    <NmiPayment useGoogle={true} />
                  </div>
                {!downgrading && payScreen && !showCreditForm && (
                  <ClipLoader color="#fff" loading={!fieldsLoaded} cssOverride={googleLoaderCss} size={40} />
                )}
                  <div className="payment" style={{ visibility: fieldsLoaded ? "visible" : "hidden"}}>
                    Or input manually:
                  </div>
                  <button className="payment credit-toggle" style={{ visibility: fieldsLoaded ? "visible" : "hidden" }}
                    onClick={() => setShowCreditForm(!showCreditForm)}>
                  {showCreditForm ? (
                    <span>
                      Credit Card&emsp;
                      <FontAwesomeIcon icon={faArrowLeft} size="lg" />
                    </span>
                  ) : (
                    <span>
                      Credit Card&emsp;
                      <FontAwesomeIcon icon={faArrowRight} size="lg" />
                    </span>
                  )}
                  </button>
                </div>
              )}
              </div>
              <div style={{ display: showCreditForm ? 'block' : 'none' }}>
                <form onSubmit={handleSubmit}>
                  <ClipLoader color="#fff" loading={!fieldsLoaded} cssOverride={formLoaderCss} size={40} />
                  <div style={{ gridColumnStart: 1, gridColumnEnd: "span 4" }} id="ccnumber" className="nmi-input" />
                  <div style={{ gridRow: 2, gridColumnStart: 1, gridColumnEnd: "span 2" }} id="ccexp" className="nmi-input" />
                  <div style={{ gridRow: 2, gridColumnStart: 3, gridColumnEnd: "span 2" }} id="cvv" className="nmi-input" />
                  <br/>
                  <input style={{ gridRow: 3, gridColumnStart: 1, gridColumnEnd: "span 2", visibility: fieldVisible() }}
                    name="first_name" placeholder="First Name" value={formData.first_name || ''} onChange={handleChange}
                  />
                  <input style={{ gridRow: 3, gridColumn: 3, gridColumnEnd: "span 2", visibility: fieldVisible() }}
                    name="last_name" placeholder="Last Name" value={formData.last_name || ''} onChange={handleChange} />
                  <input style={{ gridRow: 4, gridColumnStart: 1, gridColumnEnd: "span 4", visibility: fieldVisible() }}
                    name="address1" placeholder="Address" value={formData.address1 || ''} onChange={handleChange} />
                  <input style={{ gridRow: 5, gridColumnStart: 1, gridColumnEnd: "span 4", visibility: fieldVisible() }}
                    name="address2" placeholder="Address 2 (optional)" value={formData.address2 || ''} onChange={handleChange} />
                  <input style={{ gridRow: 6, gridColumnStart: 1, gridColumnEnd: "span 2", visibility: fieldVisible() }}
                    name="city" placeholder="City" value={formData.city || ''} onChange={handleChange} />
                  <input style={{ gridRow: 6, gridColumn: 3, visibility: fieldVisible() }}
                    name="state" placeholder="State"
                    value={formData.state || ''} onChange={handleChange} />
                  <input style={{ gridRow: 6, gridColumn: 4, visibility: fieldVisible() }} name="zip" placeholder="Zip"
                    value={formData.zip || ''} onChange={handleChange} />
                  <div style={{ justifySelf: 'center', gridRow: 7, gridColumnStart: 2, gridColumnEnd: "span 2", visibility: fieldVisible() }}>
                  {showCreditForm && (
                    <NmiPayment useGoogle={false} disabled={!formComplete()} />
                  )}
                  </div>
                  <ClipLoader color="#fff" loading={loadingPayment} cssOverride={payLoaderCss} size={30} />
                </form>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <ClipLoader color="#fff" loading={submitting} cssOverride={submittingCss} size={40} />
    </div>
  );
};

export default PowerchatPro;
